import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { api } from '../../api';
import { ReactComponent as AddButton } from '../../assets/img/svg/main/add_btn.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/svg/main/delete.svg';
import { ReactComponent as EditIcon } from '../../assets/img/svg/main/edit.svg';
import { ReactComponent as RemoveButton } from '../../assets/img/svg/main/remove_btn.svg';
import CreatePolicyModal from '../../components/general/CreatePolicyModal';
import { ToggleSwitch } from '../../components/mui/LBToggle';
import { openConfirmPopup, closeConfirmPopup } from '../../redux/dialog/dialogActions';
import { isNotEmpty } from '../../shared/util';
import TemplateDefault from '../template/TemplateDefault';

class Policy extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      data: [],
      isLoading: false,
      errorMsg: '',
      isOpenDeleteDialog: false
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { isLoading } = this.state;
    // 정책 리스트 조회
    if (!isLoading) this.fetch();
  }

  componentDidUpdate(prevProps, prevState) {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  fetch = () => {
    api.get(`/v1/policy`).then(({ data }) => {
      this.setState({ data, isLoading: false });
    });
  };

  modalData = (
    <CreatePolicyModal
      // TODO: isValid는 나중에 동의/비동의 같은 항목에서 return 값 받아오는 용도로 쓸 예정
      title=""
      content=""
      handleCloseModal={() => {
        this.setState({ showModal: false });
      }}
      handleClickConfirm={() => {
        alert('기본');
        this.setState({ showModal: false });
      }}
    />
  );

  addPolicy = (type, content) => {
    api
      .post(`/v1/policy`, {
        type,
        content
      })
      .then((res) => {
        this.setState({ showModal: false });
        this.fetch();
      });
  };

  updatePolicy = (type, content, id) => {
    api
      .put(`/v1/policy/${id}`, {
        type,
        content
      })
      .then((res) => {
        this.setState({ showModal: false });
        this.fetch();
      });
  };

  openCreateModal = () => {
    return () => {
      this.modalData = (
        <CreatePolicyModal
          type=""
          content=""
          handleCloseModal={() => {
            this.setState({ showModal: false });
          }}
          handleClickConfirm={this.addPolicy}
        />
      );
      this.setState({ showModal: true });
    };
  };

  openEditModal = (type, content, id) => {
    return () => {
      this.modalData = (
        <CreatePolicyModal
          id={id}
          type={type}
          content={content}
          handleCloseModal={() => {
            this.setState({ showModal: false });
          }}
          handleClickConfirm={this.updatePolicy}
        />
      );
      this.setState({ showModal: true });
    };
  };

  handleToggle = (id) => {
    api.put(`/v1/policy/${id}/apply`).then(this.fetch);
  };

  /* 약관삭제 */
  removePolicy = (id) => {
    const { dispatch } = this.props;
    api
      .delete(`/v1/policy/${id}`)
      .then((res) => {
        this.fetch();
      })
      .finally(() => {
        dispatch(closeConfirmPopup());
      });
  };

  /* 삭제 버튼 클릭 */
  handleClickDeleteButton = (id) => {
    const { dispatch } = this.props;
    dispatch(
      /* 삭제 버튼 클릭 재확인 팝업창*/
      openConfirmPopup({
        title: 'NOTICE',
        comment: '삭제 진행 재확인',
        type: 'delete',
        onOk: () => this.removePolicy(id)
      })
    );
  };

  // -- render --------------------------------------------------------------------------------
  topButton = (className, text, onClick) => {
    const button = className === 'hyper-mint' ? <AddButton /> : <RemoveButton />;
    return (
      <p className={className} onClick={onClick}>
        {button}
        <span>{text}</span>
      </p>
    );
  };

  renderHeader = () => (
    <div className="row th">
      <p style={{ width: '10%' }}>No</p>
      <p style={{ width: '20%' }}>타입</p>
      <p style={{ width: '20%' }}>내용</p>
      <p style={{ width: '20%' }}>적용여부</p>
      <p style={{ width: '20%' }}>생성날짜</p>
      <p style={{ width: '10%' }}></p>
    </div>
  );

  renderRow = ({ id, appliedAt, content, createdAt, type }, index) => {
    const typeName = type === 'TERMS_OF_SERVICE' ? '이용약관' : '??약관';
    return (
      <div className="row" key={index}>
        <p style={{ width: '10%' }}>{id}</p>
        <p style={{ width: '20%' }}>{typeName}</p>
        <p
          style={{
            width: '20%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            lineHeight: '50px',
            display: 'block'
          }}
        >
          {content}
        </p>
        <p style={{ width: '20%' }}>
          <ToggleSwitch disabled={this.state.isLoading} checked={!!appliedAt} onClick={() => this.handleToggle(id)} />
        </p>
        <p style={{ width: '20%' }}>{moment(createdAt).format('YYYY-MM-DD')}</p>
        <p style={{ width: '10%' }}>
          <EditIcon style={{ marginRight: '28px' }} onClick={this.openEditModal(type, content, id)} />
          <DeleteIcon onClick={() => this.handleClickDeleteButton(id)} />
        </p>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <TemplateDefault sidebar="admin">
          <span className="title">약관 관리</span>
          <div style={{ width: 'fit-content' }}>
            <div className="top-button-container">
              {this.topButton('hyper-mint', '약관 등록', this.openCreateModal())}
            </div>
            <div className="container">
              <div className="table">
                {this.renderHeader()}
                {this.props.isLoading ? (
                  <CustomLoading size="100px" />
                ) : isNotEmpty(this.props.errorMsg) ? (
                  <CustomErrorMessage message={this.props.errorMsg} />
                ) : (
                  <div className="rows">{this.state.data?.map((data, index) => this.renderRow(data, index))}</div>
                )}
              </div>
            </div>
          </div>
        </TemplateDefault>
        {this.state.showModal ? this.modalData : null}
        {this.state.isOpenDeleteDialog ? this.deleteConfirmModal : null}
      </React.Fragment>
    );
  }
}
function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(Policy));
