import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';

// import components
import TemplateDefault from '../template/TemplateDefault';

import CustomPagination from '../../components/custom/CustomPagination';
import CalenderRange from '../../components/general/CalenderRange';

// import utils & icons
import moment from 'moment';
import { api } from '../../api';
import { isNotEmpty } from '../../shared/util';
import { numberWithCommas } from '../../shared/formatter';
import { addDays } from '../../shared/dateTimeUtil';
import { openConfirmPopup, closeConfirmPopup } from '../../redux/dialog/dialogActions';

class Calculate extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      storeId: props.match?.params?.storeid || -1,
      limit: 10,
      page: 1,
      count: 0,
      calculateList: sampleData,
      isLoading: false,
      isDownloading: false,
      errorMsg: '',
      startDate: moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      toggleTest: true,
      showModal: false,
      totalPrice: 0
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { isLoading } = this.state;
    if (!isLoading) this.loadCalculate();
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, isLoading, page } = this.state;

    // 날짜 변경
    if (isNotEmpty(startDate) && isNotEmpty(endDate)) {
      if (prevState.startDate !== startDate || prevState.endDate !== endDate) {
        if (!isLoading) this.loadCalculate();
      }
    } else if (prevState.page !== page) {
      // 페이지 변경
      this.loadCalculate();
    }
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  // 정산내역 로드
  loadCalculate = () => {
    const { startDate, endDate, limit, page, storeId } = this.state;

    this.setState({
      isLoading: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .get(`/v1/places/${storeId}/orders/settlement`, {
        params: {
          startDate,
          endDate,
          limit,
          page
        }
      })
      .then((res) => {
        const { orders, count, total } = res.data;

        this.setState({
          calculateList: orders,
          count: count,
          totalPrice: total,
          isLoading: false
        });
      })
      .catch((err) => {
        console.error(err);

        this.setState({
          isLoading: false
        });
      });
  };

  // 정산내역 다운로드
  downloadCalculate = () => {
    if (this.state.calculateList.length < 1) {
      this.props.dispatch(
        openConfirmPopup({
          title: 'NOTICE',
          comment: '파일로 출력할 내용이 존재하지 않습니다. 기간을 조정해주세요.',
          type: 'warning',
          onOk: () => {
            this.props.dispatch(closeConfirmPopup());
          }
        })
      );
    } else {
      const { startDate, endDate, storeId } = this.state;

      this.setState({
        isDownloading: true
      });

      api.defaults.headers = {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
      };
      api
        .get(`/v1/places/${storeId}/orders/settlement/file`, {
          responseType: 'blob',
          params: {
            startDate,
            endDate
          }
        })
        .then((res) => {
          const result = res.data;

          fileDownload(result, `${storeId}_${startDate}_${endDate}.csv`);

          this.setState({
            isDownloading: false
          });

          api.delete(`/v1/places/${storeId}/orders/settlement/temp`).catch((err) => {
            console.error(err);
          });
        })
        .catch((err) => {
          console.error(err);

          this.setState({
            isDownloading: false
          });
        });
    }
  };

  renderHeader = (titles) => {
    return (
      <div className="row th">
        {titles.map((title) => (
          <p key={title} style={{ width: `calc(100%/${titles.length})` }}>
            {title}
          </p>
        ))}
      </div>
    );
  };

  handleChange = () => {
    this.setState({ toggleTest: !this.state.toggleTest });
  };

  renderRow = (data, tdCount) => {
    const { id, actualPrice, commissionFee, couponPrice, orderDate, orderNo, paymentMethod, price } = data;

    const commonStyle = { width: `calc(100%/${tdCount})` };

    return (
      <div className="row" key={orderNo || id}>
        <p style={commonStyle}>{orderDate}</p>
        <p style={commonStyle}>{orderNo}</p>
        <p style={commonStyle}>{`₩ ${numberWithCommas(price)}`}</p>
        <p style={commonStyle}>{`₩ ${numberWithCommas(couponPrice)}`}</p>
        <p style={commonStyle}>{paymentMethod}</p>
        <p style={commonStyle}>{`₩ ${numberWithCommas(commissionFee)}`}</p>
        <p style={commonStyle}>{`₩ ${numberWithCommas(actualPrice)}`}</p>
      </div>
    );
  };

  setUrl = (query) => {
    const loc = this.props.location.pathname;
    // let searchParams = new URLSearchParams(this.props.location.search);
    // console.log(query);
    this.props.history.push({
      pathname: loc,
      search: '?' + new URLSearchParams({ startDate: query.startDate, endDate: query.endDate }).toString()
    });
  };

  merchantContents = () => {
    const { page, limit, count, isLoading, calculateList, startDate, endDate, totalPrice } = this.state;
    const tableHeader = ['일자', '주문 번호', '결제 금액', '쿠폰 금액', '결제사', '수수료', '정산 금액'];

    return (
      <div style={{ width: 'fit-content' }}>
        <div className="top-button-container">
          {this.state.isDownloading && <span>다운로드 중...</span>}
          <span
            className="button"
            style={{
              width: '160px',
              fontFamily: 'Noto Sans KR',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '20px',
              color: 'black',
              borderRadius: '4px',
              margin: '0 16px'
            }}
            onClick={this.downloadCalculate}
            disabled={isLoading}
          >
            정산 내역 출력
          </span>
          <CalenderRange
            disabled={isLoading}
            startDate={startDate}
            endDate={endDate}
            queryParam={(res) => {
              const { startDate, endDate } = res;

              if (isNotEmpty(endDate)) {
                this.setState({
                  startDate,
                  endDate: addDays(endDate, 1),
                  page: 1
                });
              } else {
                this.setState({
                  startDate,
                  endDate: ''
                });
              }
            }}
          />
        </div>
        <div className="container" style={{ paddingTop: '1px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              fontSize: '1.5rem',
              height: '50px',
              textAlign: 'center'
            }}
          >
            <div style={{ fontWeight: 600, width: `calc(100%/${tableHeader.length || 1})` }}>정산 금액 총합</div>
            <div style={{ fontWeight: 400, width: `calc(100%/${tableHeader.length || 1})` }}>
              {`₩ ${numberWithCommas(totalPrice)}`}
            </div>
          </div>
          <div className="table">
            {this.renderHeader(tableHeader)}
            <div className="rows">{calculateList?.map((data) => this.renderRow(data, tableHeader.length))}</div>
          </div>
          {/* 하단 */}
          <CustomPagination
            totalCount={count}
            page={page}
            limit={limit}
            onClickBack={this.handleClickBack}
            onClickForward={this.handleClickForward}
            isLoading={isLoading}
          />
        </div>
      </div>
    );
  };

  // 이전 페이지
  handleClickBack = () => {
    this.setState((prev) => ({
      page: prev.page - 1
    }));
  };
  // 다음 페이지
  handleClickForward = () => {
    this.setState((prev) => ({
      page: prev.page + 1
    }));
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const { version } = this.props;
    // const isMerchant = this.state.tab === 'merchant' ? true : false;

    return (
      <React.Fragment>
        <TemplateDefault sidebar="store">
          <span className="title">정산 관리</span>
          {this.merchantContents()}
        </TemplateDefault>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(Calculate));

const sampleData = [
  {
    id: 1,
    dateComplete: '2021.09.01-09.30',
    dateEstimate: '2021.10.12',
    paymentCount: 120,
    status: 'expected',
    paymentAmount: '3,130,000원'
  },
  {
    id: 2,
    dateComplete: '2021.08.01-08.30',
    dateEstimate: '2021.09.12',
    paymentCount: 210,
    status: 'hold',
    paymentAmount: '3,130,000원'
  },
  {
    id: 3,
    dateComplete: '2021.07.01-07.30',
    dateEstimate: '2021.08.12',
    paymentCount: 120,
    status: 'completion',
    paymentAmount: '3,130,000원'
  },
  {
    id: 4,
    dateComplete: '2021.06.01-06.30',
    dateEstimate: '2021.07.12',
    paymentCount: 320,
    status: 'completion',
    paymentAmount: '3,130,000원'
  },
  {
    id: 5,
    dateComplete: '2021.05.01-05.30',
    dateEstimate: '2021.06.12',
    paymentCount: 145,
    status: 'completion',
    paymentAmount: '3,130,000원'
  }
];
