import produce from 'immer';
// import { deepCopy } from "../../shared/util";
import { GET_ALL_POSTS } from '../actionTypes';

const initialState = {
  posts: [
    {
      id: 1,
      title: 'foo',
      body: 'content',
      userId: 1
    },
    {
      id: 2,
      title: 'bar',
      body: '...',
      userId: 1
    }
  ]
};

export const postListReducer = (state = initialState, action) => {
  if (action.type === GET_ALL_POSTS) {
    return produce(state, (draft) => {
      draft.posts = action.posts;
    });
    // return action.posts;
  }

  // switch (action.type) {
  //   case GET_ALL_POSTS:
  //     return action.posts;
  //   // default:
  //   //   return initialState;
  // }

  return state;
};
