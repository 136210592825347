import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

// import component
import { ToggleSwitch } from './mui/LBToggle';

// import utils & icon
import { api } from '../api';
import moment from 'moment';
import { ReactComponent as CloseButton } from '../assets/img/svg/main/modal_close.svg';
import { getPromotionCouponStatus } from '../shared/dataUtil';
import { isEmpty } from '../shared/util';

class AdminCouponDetailModal extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object,
    couponList: PropTypes.array,
    loadCouponList: PropTypes.func,
    onClose: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      couponList: props.couponList,
      isLoading: false
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  handleChange = () => {
    this.setState({ toggleTest: !this.state.toggleTest });
  };

  /* 전체 쿠폰 발행 토글 */
  handleAllToggle = (placeId) => {
    const { couponList } = this.state;
    const allChecked = !couponList?.some((v) => v.isPublish === false);

    if (isEmpty(couponList)) return;

    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };

    this.setState({ isLoading: true });
    api
      .patch(`/v1/places/${placeId}/coupons/publish-all`, { publish: !allChecked })
      .then((res) => {
        const newList = couponList.map((v) => {
          let newStatus = '';
          for (let i = 0; i < res.data?.length; i++) {
            if (res.data[i].id === v.id) newStatus = res.data[i].status;
          }

          return { ...v, isPublish: !allChecked, status: newStatus };
        });

        this.setState({
          isLoading: false,
          couponList: newList
        });

        // 리스트 새로 고침 (카운트 정보)
        this.props.loadCouponList();
      })
      .catch((err) => {
        this.setState({ isLoading: false, errorMsg: '예기치 못한 에러가 발생되었습니다.' });
      })
      .finally(() => {});
  };

  /* 쿠폰 발행 토글 */
  handleToggle = (couponId, placeId) => {
    const { couponList } = this.state;
    const findIndex = couponList.findIndex((v) => v.id === couponId);
    if (findIndex === -1) return alert('알수없는 오류 발생');
    const toggle = couponList?.[findIndex]?.isPublish;

    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    this.setState({ isLoading: true });

    api
      .patch(`/v1/places/${placeId}/coupons/${couponId}/toggle-publish`, { isPublish: !toggle })
      .then((res) => {
        const newList = couponList.map((v) => {
          if (v.id === couponId) return { ...v, isPublish: !toggle, status: res.data.status };
          else return v;
        });
        this.setState({
          isLoading: false,
          couponList: newList
        });
        // 리스트 새로 고침 (카운트 정보)
        this.props.loadCouponList();
      })
      .catch((err) => {
        this.setState({ isLoading: false, errorMsg: '예기치 못한 에러가 발생되었습니다.' });
      })
      .finally(() => {});
  };

  // -- render --------------------------------------------------------------------------------

  /* 리스트 헤더 */
  renderHeader = (widthSize) => {
    const tableHeader = ['쿠폰 번호', '발행 기간', '쿠폰 종류', '쿠폰명', '사용 / 발행(EA)', '발행 상태', '발행 ON'];
    return (
      <div className="row th">
        <p style={{ width: widthSize[0] }}>{tableHeader[0]}</p>
        <p style={{ width: widthSize[1] }}>{tableHeader[1]}</p>
        <p style={{ width: widthSize[2] }}>{tableHeader[2]}</p>
        <p style={{ width: widthSize[3] }}>{tableHeader[3]}</p>
        <p style={{ width: widthSize[4] }}>{tableHeader[4]}</p>
        <p style={{ width: widthSize[5] }}>{tableHeader[5]}</p>
        <p style={{ width: widthSize[6] }}>{tableHeader[6]}</p>
      </div>
    );
  };

  /* 리스트 Row */
  renderRow = (data, index, widthSize) => {
    const { isLoading } = this.state;
    const { id, placeId, startDate, endDate, createAmount, remainAmount, type, name, isPublish, status } = data;
    const startAt = moment(startDate).format('YYYY-MM-DD');
    const endDateAt = moment(endDate).format('YYYY-MM-DD');
    const couponDate = `${startAt} ~ ${endDateAt}`;
    const usedCount = `${createAmount - remainAmount}/${createAmount}`;

    return (
      <div className="row" key={index}>
        <p style={{ width: widthSize[0] }}>{id}</p>
        <p style={{ width: widthSize[1] }}>{couponDate}</p>
        <p style={{ width: widthSize[2] }}>{type}</p>
        <p style={{ width: widthSize[3] }}>{name}</p>
        <p style={{ width: widthSize[4] }}>{usedCount}</p>
        <p style={{ width: widthSize[5] }}>
          <span className={'issue'} style={{ backgroundColor: getPromotionCouponStatus(status).color }}>
            {getPromotionCouponStatus(status).title}
          </span>
        </p>
        <p style={{ width: widthSize[6] }}>
          <ToggleSwitch
            disabled={isLoading}
            checked={isPublish}
            onClick={() => this.handleToggle(id, placeId)}
            name="checkedB"
          />
        </p>
      </div>
    );
  };

  render() {
    const { couponList, isLoading } = this.state;
    const widthSize = ['12%', '20%', '10%', '26%', '10%', '12%', '10%'];

    // 전체 체크 여부
    const allChecked = isEmpty(couponList) ? false : !couponList?.some((v) => v.isPublish === false);
    const placeId = couponList?.[0]?.placeId; //매장 Id 가져오기

    const allCount = couponList?.length;

    let pCount = 0; // 발행중 (publish)
    let sCount = 0; // 중단 (stop)
    let fCount = 0; // 종료 (finish)
    let rCount = 0; // 예약 (reservation)

    for (let i = 0; i < couponList.length; i++) {
      if (couponList[i].status === 'P') pCount += 1;
      else if (couponList[i].status === 'S') sCount += 1;
      else if (couponList[i].status === 'F') fCount += 1;
      else if (couponList[i].status === 'R') rCount += 1;
    }

    return (
      <React.Fragment>
        <div className="modal-container">
          <div className="coupon-detail-modal">
            <div className="title-container">
              <span>{this.props.title}</span>
              <CloseButton onClick={() => this.props.onClose()} />
            </div>
            <div className="middle-container">
              <p>
                <span>{`총 쿠폰 개수 : ${allCount} 개`}</span>&nbsp;&nbsp;/&nbsp;&nbsp;
                <span className="green-font">{`발행중 : ${pCount} 개`}</span>&nbsp;&nbsp;/&nbsp;&nbsp;
                <span className="red-font">{`중단 : ${sCount} 개`}</span>&nbsp;&nbsp;/&nbsp;&nbsp;
                <span className="gray-font">{`종료 : ${fCount} 개`}</span>&nbsp;&nbsp;/&nbsp;&nbsp;
                <span className="yellow-font">{`예약 : ${rCount} 개`}</span>&nbsp;&nbsp;
              </p>
              <p style={{ marginLeft: '48px' }}>
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>전 쿠폰 발행</span>
                <ToggleSwitch
                  disabled={isLoading}
                  checked={allChecked}
                  onClick={() => this.handleAllToggle(placeId)}
                  name="checkedB"
                />
              </p>
            </div>
            <div className="content-container">
              <div className="modal-table">
                <div style={{ display: 'flex' }}>{this.renderHeader(widthSize)}</div>
                <div className="rows">{couponList?.map((data, index) => this.renderRow(data, index, widthSize))}</div>
              </div>
              <span
                className="button button-active"
                style={{ width: '330px', color: 'black', margin: '12px auto 0' }}
                onClick={() => this.props.onClose()}
              >
                확인
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}
export default connect(select)(AdminCouponDetailModal);
