import produce from 'immer';
import {
  LOAD_STORE_CATEGORY_REQUEST,
  LOAD_STORE_CATEGORY_SUCCESS,
  LOAD_STORE_CATEGORY_FAILURE,
  LOAD_STORE_SERVICE_REQUEST,
  LOAD_STORE_SERVICE_SUCCESS,
  LOAD_STORE_SERVICE_FAILURE
} from '../actionTypes';

const initialState = {
  storeCategory: [],
  isLoadingCategory: false, // 데이타 로딩중
  storeService: [],
  isLoadingService: false // 데이타 로딩중
};

// TODO: deepCopy 이용해서 default_state 만들어두어야 함
export const categoryReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case LOAD_STORE_CATEGORY_REQUEST: {
        draft.isLoadingCategory = true;
        draft.storeCategory = [];
        break;
      }
      case LOAD_STORE_CATEGORY_SUCCESS: {
        draft.storeCategory = action.storeCategory;
        draft.isLoadingCategory = false;
        break;
      }
      case LOAD_STORE_CATEGORY_FAILURE: {
        draft.isLoadingCategory = false;
        break;
      }
      case LOAD_STORE_SERVICE_REQUEST: {
        draft.storeService = [];
        draft.isLoadingService = true;
        break;
      }
      case LOAD_STORE_SERVICE_SUCCESS: {
        draft.storeService = action.storeService;
        draft.isLoadingService = false;
        break;
      }
      case LOAD_STORE_SERVICE_FAILURE: {
        draft.isLoadingService = false;
        break;
      }
      default: {
        break;
      }
    }
  });
};
