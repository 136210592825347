import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { closeConfirmPopup } from '../redux/dialog/dialogActions';

// import icons
import { ReactComponent as CloseButton } from '../assets/img/svg/main/modal_close.svg';
import { ReactComponent as SuccessIcon } from '../assets/img/svg/popup/success.svg';
import { ReactComponent as WarningIcon } from '../assets/img/svg/popup/warning.svg';
import { ReactComponent as DeleteIcon } from '../assets/img/svg/popup/delete.svg';

class ConfirmPopup extends React.Component {
  // -- static --------------------------------------------------------------------------------

  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    commonDialog: PropTypes.object
  };
  // -- constructor ---------------------------------------------------------------------------

  constructor(props) {
    super(props);
  }
  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------

  optionImgRender = (option) => {
    switch (option) {
      case 'success':
        return <SuccessIcon />;
      case 'warning':
        return <WarningIcon />;
      case 'delete':
        return <DeleteIcon />;
      default:
        return <SuccessIcon />;
    }
  };

  render() {
    const {
      commonDialog: { isOpenConfirmPopup, confirmPopupTitle, confirmPopupComment, confirmPopupType, confirmPopupOnOk },
      dispatch
    } = this.props;
    return isOpenConfirmPopup ? (
      <div className="status-modal-container">
        <div className="notice-modal">
          <div className="title-container">
            <span>{confirmPopupTitle}</span>
            <CloseButton onClick={() => dispatch(closeConfirmPopup())} />
          </div>
          <div className="content-container">
            <div className="success">
              {this.optionImgRender(confirmPopupType)}
              <div style={{ color: '#545454' }}>{confirmPopupComment}</div>
            </div>
            <span
              className="button"
              style={{ width: '330px', color: 'black', margin: '22px auto 0' }}
              onClick={() => (confirmPopupOnOk ? confirmPopupOnOk() : dispatch(closeConfirmPopup()))}
            >
              확인
            </span>
          </div>
        </div>
      </div>
    ) : null;
  }
}
function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version,
    commonDialog: state.commonDialog
  };
}
export default connect(select)(ConfirmPopup);
