import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import actions
import { openConfirmPopup, closeConfirmPopup } from '../../redux/dialog/dialogActions';

// import components
import CustomPagination from '../../components/custom/CustomPagination';
import TemplateDefault from '../template/TemplateDefault';
import CalenderRange from '../../components/general/CalenderRange';
import { ToggleSwitch } from '../../components/mui/LBToggle';
import TextAreaModal from '../../components/general/TextareaModal';
import StoreCouponCreateModal from '../../components/StoreCouponCreateModal';
import CustomLoading from '../../components/custom/CustomLoading';

// import utils & icons
import update from 'react-addons-update';
import moment from 'moment';
import { api } from '../../api';
import { isNotEmpty, isEmpty } from '../../shared/util';
import { numberWithCommas } from '../../shared/formatter';
import { addDays } from '../../shared/dateTimeUtil';
import { getPromotionCouponStatus } from '../../shared/dataUtil';
import { ReactComponent as AddButton } from '../../assets/img/svg/main/add_btn.svg';
import { ReactComponent as RemoveButton } from '../../assets/img/svg/main/remove_btn.svg';
import { ReactComponent as DetailImage } from '../../assets/img/svg/store/menu/detail.svg';
import { ReactComponent as MemoIcon } from '../../assets/img/svg/main/memo.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/svg/main/delete.svg';

class Coupon extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.settingModalEl = React.createRef();

    this.state = {
      storeId: props.match?.params?.storeid || -1, // 가맹점 ID
      limit: 10, // 페이징
      page: 1, // 페이징
      count: 0, // 전체 개수
      couponList: [], // 쿠폰 리스트
      couponCheckedList: [], // 체크박스
      isLoading: false, // 조회중
      errorMsg: '', // 에러 메세지
      startDate: '', // 시작 날짜
      endDate: '', // 종료 날찌
      isLoadingDelete: false, // 삭제 진행중
      toggle: -1, // 쿠폰 드롭다운 메뉴
      isChangingToggle: false, // 쿠폰 상태 변경중
      isOpenCommentModal: false, // 쿠폰 메모 팝업창
      couponComment: {}, // 쿠폰 메모(메모 팝업창 저장)
      couponModal: false // 쿠폰 발행 팝업창
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { isLoading } = this.state;
    if (!isLoading) this.loadCoupons();

    // 옵션팝업 이외 영역 조작
    window.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, isLoading, page } = this.state;

    // 날짜 변경
    if (isNotEmpty(startDate) && isNotEmpty(endDate)) {
      if (prevState.startDate !== startDate || prevState.endDate !== endDate) {
        if (!isLoading) this.loadCoupons();
      }
    }

    // 페이지 변경
    if (prevState.page !== page) {
      this.loadCoupons();
    }
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // 이외 영역 클릭시 닫기
  handleClickOutside = ({ target }) => {
    // 설정 메뉴
    if (this.state.toggle !== -1 && !this.settingModalEl.current?.contains(target)) {
      this.setState({
        toggle: -1
      });
    }
  };

  // 쿠폰 리스트 로드
  loadCoupons = () => {
    const { startDate, endDate, limit, page, storeId } = this.state;
    const isDate = isNotEmpty(startDate) && isNotEmpty(endDate);

    this.setState({
      isLoading: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .get(
        `/v1/places/${storeId}/coupons?limit=${limit}&page=${page}${
          isDate ? `&startDate=${startDate}&endDate=${endDate}` : ''
        }`
      )
      .then((res) => {
        const { coupons, count, pCount, sCount, fCount, rCount } = res.data;
        this.setState({
          couponList: coupons,
          count: count,
          pCount: pCount,
          sCount: sCount,
          fCount: fCount,
          rCount: rCount,
          isLoading: false
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
      });
  };

  // 쿠폰 발행
  createCoupon = (coupons) => {
    const { dispatch } = this.props;
    const { couponList, isLoading } = this.state;
    if (isLoading) return;
    const { storeId, ...body } = coupons;
    // body = { name, startDate, endDate, discountType, discountAmount, productId, useMinPrice, createAmount }

    this.setState({
      isLoading: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .post(`/v1/places/${storeId}/coupons`, body)
      .then((res) => {
        const newList = [...couponList];
        console.log('res.data===', res.data);
        newList.unshift(res.data);
        if (newList.length > 10) newList.pop();

        this.setState({
          couponList: newList,
          couponModal: false,
          isLoading: false
        });

        dispatch(
          openConfirmPopup({
            title: 'NOTICE',
            comment: '등록 완료',
            type: 'success'
          })
        );
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
      });
  };

  // 모든 체크 선택
  // 전체 선택 여부에 따라 on/off
  handleClickAllCheckBox = () => {
    const { couponCheckedList, couponList } = this.state;
    const isAllChecked = isNotEmpty(couponList) && couponList?.length === couponCheckedList?.length ? true : false;

    // 전체 선택 상태
    if (isAllChecked) {
      this.setState({ couponCheckedList: [] });
    }
    // 전체 선택이 아닌 경우
    else {
      const allId = couponList.map((v) => v.id);
      this.setState({ couponCheckedList: allId });
    }
  };

  /* 쿠폰 체크 박스 선택 */
  handleClickCheckBox = (id) => {
    const { couponCheckedList } = this.state;
    const isChecked = couponCheckedList.includes(id);

    // 체크
    if (!isChecked) {
      const newList = [...couponCheckedList, id];
      this.setState({
        couponCheckedList: newList
      });
    }
    // 체크 해제
    else {
      const newList = couponCheckedList?.filter((v) => v !== id);
      this.setState({
        couponCheckedList: newList
      });
    }
  };

  // 선택된 쿠폰 삭제
  deleteCoupon = (couponIdList) => {
    if (isEmpty(couponIdList)) {
      return alert('삭제 할 쿠폰을 선택해주세요.');
    }

    const { dispatch } = this.props;
    const { storeId, couponList, isLoadingDelete, couponCheckedList } = this.state;
    if (isLoadingDelete) return;

    // 전체 삭제 여부
    const isAllChecked = isNotEmpty(couponList) && couponList?.length === couponCheckedList?.length ? true : false;

    this.setState({
      isLoadingDelete: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .post(`/v1/places/${storeId}/coupons/remove`, { couponIdList: couponIdList })
      .then((res) => {
        const newList = couponList.filter((v) => !couponIdList.includes(v.id));

        this.setState({
          couponList: newList,
          couponCheckedList: [],
          isLoadingDelete: false
        });

        // 전체 삭제 일 경우, 새로고침
        if (isAllChecked) this.loadCoupons();
      })
      .catch((err) => {
        this.setState({ isLoadingDelete: false });
      })
      .finally(() => {
        dispatch(closeConfirmPopup());
      });
  };

  /* 쿠폰 발행 토글 */
  handleToggle = (couponId) => {
    const { couponList, storeId } = this.state;
    const findIndex = couponList.findIndex((v) => v.id === couponId);
    if (findIndex === -1) return alert('알수없는 오류 발생');
    const beforeToggle = couponList?.[findIndex]?.isPublish;
    const beforeStatus = couponList?.[findIndex]?.status;

    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    this.setState({ isChangingToggle: true });

    api
      .patch(`/v1/places/${storeId}/coupons/${couponId}/toggle-publish`, { isPublish: !beforeToggle })
      .then((res) => {
        this.setState((prev) => {
          const count = {
            pCount: prev.pCount,
            sCount: prev.sCount,
            fCount: prev.fCount,
            rCount: prev.rCount
          };
          if (res.data.status === 'P') count.pCount = count.pCount + 1;
          else if (res.data.status === 'S') count.sCount = count.sCount + 1;
          else if (res.data.status === 'F') count.fCount = count.fCount + 1;
          else if (res.data.status === 'R') count.rCount = count.rCount + 1;

          if (beforeStatus === 'P') count.pCount = count.pCount - 1;
          else if (beforeStatus === 'S') count.sCount = count.sCount - 1;
          else if (beforeStatus === 'F') count.fCount = count.fCount - 1;
          else if (beforeStatus === 'R') count.rCount = count.rCount - 1;

          return {
            isChangingToggle: false,
            couponList: update(couponList, {
              [findIndex]: { isPublish: { $set: !beforeToggle }, status: { $set: res.data.status } }
            }),
            ...count
          };
        });
      })
      .catch((err) => {
        this.setState({ isChangingToggle: false, errorMsg: '예기치 못한 에러가 발생되었습니다.' });
      })
      .finally(() => {});
  };

  /* 쿠폰 메모 수정 */
  updateComment = (comment) => {
    const { dispatch } = this.props;
    const { storeId } = this.state;
    const {
      couponComment: { id },
      couponList
    } = this.state;
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };

    api
      .patch(`/v1/places/${storeId}/coupons/${id}/comment`, { comment })
      .then((res) => {
        const { comment } = res.data;
        const findIndex = couponList.findIndex((v) => v.id === id);
        this.setState({
          isOpenCommentModal: false,
          couponList: update(couponList, { [findIndex]: { comment: { $set: comment } } })
        });

        dispatch(
          openConfirmPopup({
            title: 'NOTICE',
            comment: '수정 완료',
            type: 'success'
          })
        );
      })
      .catch((err) => {
        this.setState({ isOpenCommentModal: false });
      });
  };

  // 이전 페이지
  handleClickBack = () => {
    this.setState((prev) => ({
      page: prev.page - 1
    }));
  };
  // 다음 페이지
  handleClickForward = () => {
    this.setState((prev) => ({
      page: prev.page + 1
    }));
  };

  // -- render --------------------------------------------------------------------------------

  renderHeader = (widthSize) => {
    const { couponList, couponCheckedList } = this.state;
    const titles = ['쿠폰 번호', '발행 기간', '적용 대상', '쿠폰명', '사용 / 발행 (EA)', '발행 상태', '발행 ON'];
    const isAllChecked = isNotEmpty(couponList) && couponList?.length === couponCheckedList?.length ? true : false;
    return (
      <div className="row th" style={{ maxWidth: '1346px' }}>
        <p style={{ width: '50px', minWidth: '50px' }}>
          <input type="checkbox" checked={isAllChecked} onChange={this.handleClickAllCheckBox} className="checkbox" />
        </p>
        <p style={{ width: widthSize[0] }}>{titles[0]}</p>
        <p style={{ width: widthSize[1] }}>{titles[1]}</p>
        <p style={{ width: widthSize[2] }}>{titles[2]}</p>
        <p style={{ width: widthSize[3] }}>{titles[3]}</p>
        <p style={{ width: widthSize[4] }}>{titles[4]}</p>
        <p style={{ width: widthSize[5] }}>{titles[5]}</p>
        <p style={{ width: widthSize[6] }}>{titles[6]}</p>
        <p style={{ width: widthSize[7] }}></p>
      </div>
    );
  };

  renderRow = (data, index, widthSize) => {
    const {
      id,
      isPublish,
      name,
      orderProductCouponCount,
      products,
      productCoupons,
      startDate,
      endDate,
      status,
      comment,
      createAmount,
      remainAmount
    } = data;
    const { couponCheckedList, isChangingToggle } = this.state;

    const startAt = moment(startDate).format('YYYY-MM-DD');
    const endAt = moment(endDate).format('YYYY-MM-DD');
    const couponDate = `${startAt} ~ ${endAt}`;
    const target = products?.length ? '특정메뉴' : '전메뉴';
    const isChecked = couponCheckedList.includes(id); //체크 여부
    const couponCount = `${numberWithCommas(
      (isEmpty(createAmount) ? 0 : createAmount) - (isEmpty(remainAmount) ? 0 : remainAmount)
    )} / ${numberWithCommas(isEmpty(createAmount) ? 0 : createAmount)}`;

    return (
      <div className="row" key={index} style={{ position: 'relative', maxWidth: '1346px' }}>
        <p style={{ width: '50px', minWidth: '50px' }}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => this.handleClickCheckBox(id)}
            className="checkbox"
          />
        </p>
        <p style={{ width: widthSize[0] }}>{id}</p>
        <p style={{ width: widthSize[1] }}>{couponDate}</p>
        <p style={{ width: widthSize[2] }}>{target}</p>
        <p style={{ width: widthSize[3] }}>{name}</p>
        <p style={{ width: widthSize[4] }}>{couponCount}</p>
        <p style={{ width: widthSize[5] }}>
          <span className={'issue'} style={{ backgroundColor: getPromotionCouponStatus(status).color }}>
            {getPromotionCouponStatus(status).title}
          </span>
        </p>
        <p style={{ width: widthSize[6] }}>
          <ToggleSwitch
            disabled={isChangingToggle}
            checked={isPublish}
            onClick={() => this.handleToggle(id)}
            name="checkedB"
          />
        </p>
        <div
          className="center"
          style={{ width: widthSize[7], overflow: 'hidden' }}
          ref={this.state.toggle == id && this.settingModalEl}
        >
          <DetailImage
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (this.state.toggle === id) this.setState({ toggle: -1 });
              else this.setState({ toggle: id });
            }}
          />
          {this.state.toggle == id ? (
            <div className="editMenu" style={{ height: '84px', top: '35px' }}>
              <p
                onClick={() => {
                  this.setState({ isOpenCommentModal: true, toggle: -1, couponComment: { id, comment } });
                }}
              >
                <MemoIcon /> <span>메모</span>
              </p>
              <p
                onClick={() => {
                  this.props.dispatch(
                    openConfirmPopup({
                      title: 'NOTICE',
                      comment: '삭제 진행 재확인',
                      type: 'delete',
                      onOk: () => this.deleteCoupon([id])
                    })
                  );
                }}
              >
                <DeleteIcon /> <span>삭제</span>
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  merchantContents = () => {
    const { dispatch } = this.props;
    const {
      page,
      limit,
      count,
      isLoading,
      couponList,
      errorMsg,
      startDate,
      endDate,
      pCount,
      sCount,
      fCount,
      rCount,
      couponCheckedList
    } = this.state;
    const widthSize = ['14%', '16%', '12%', '22%', '16%', '10%', '10%', '7%'];

    // pCount - 발행중 (publish)
    // sCount - 중단 (stop)
    // fCount - 종료 (finish)
    // rCount - 예약 (reservation)

    return (
      <div style={{ width: 'fit-content' }}>
        <div className="top-button-container">
          <p className={'hyper-mint'} onClick={() => this.setState({ couponModal: true })}>
            <AddButton />
            <span>{'쿠폰 발행'}</span>
          </p>
          <p
            className={'red white-font'}
            onClick={() => {
              if (isEmpty(couponCheckedList)) {
                return alert('삭제 할 쿠폰을 선택해주세요.');
              } else {
                dispatch(
                  openConfirmPopup({
                    title: 'NOTICE',
                    comment: '삭제 진행 재확인',
                    type: 'delete',
                    onOk: () => this.deleteCoupon(couponCheckedList)
                  })
                );
              }
            }}
          >
            <RemoveButton />
            <span>{'쿠폰 삭제'}</span>
          </p>
          <CalenderRange
            disabled={isLoading}
            startDate={isNotEmpty(startDate) ? moment(startDate).format('YYYY-MM-DD') : ''}
            endDate={isNotEmpty(endDate) ? moment(endDate).format('YYYY-MM-DD') : ''}
            queryParam={(res) => {
              const { startDate, endDate } = res;
              this.setState({
                startDate,
                endDate: isNotEmpty(endDate) ? addDays(endDate, 1) : ''
              });
            }}
          />
        </div>
        <div className="container">
          <div className="table" style={{ width: '1600px' }}>
            {this.renderHeader(widthSize)}
            <div className="rows">
              {isLoading ? <CustomLoading /> : couponList?.map((data, index) => this.renderRow(data, index, widthSize))}
            </div>
          </div>
          <div className="bottom-container">
            <p>
              <span>{`총 쿠폰 개수 : ${numberWithCommas(count)} 개`}</span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <span className="green-font">{`발행중 : ${numberWithCommas(pCount)} 개`}</span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <span className="red-font">{`중단 : ${numberWithCommas(sCount)} 개`}</span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <span className="gray-font">{`종료 : ${numberWithCommas(fCount)} 개`}</span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <span className="yellow-font">{`예약 : ${numberWithCommas(rCount)} 개`}</span>&nbsp;&nbsp;
            </p>
            {/* 하단 */}
            <CustomPagination
              totalCount={count}
              page={page}
              limit={limit}
              onClickBack={this.handleClickBack}
              onClickForward={this.handleClickForward}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    );
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const { couponComment, storeId } = this.state;

    return (
      <React.Fragment>
        <TemplateDefault sidebar="store">
          <span className="title">쿠폰 관리</span>
          {this.merchantContents()}
        </TemplateDefault>
        {this.state.isOpenCommentModal ? (
          <TextAreaModal
            handleClickButton={this.updateComment}
            title="주요사항 메모"
            content={couponComment.comment}
            onClose={() => this.setState({ isOpenCommentModal: false, couponComment: {} })}
          />
        ) : null}
        {this.state.couponModal ? (
          <StoreCouponCreateModal
            title="쿠폰 발행"
            content=""
            storeId={storeId}
            onClose={() => {
              this.setState({ couponModal: false });
            }}
            onClickButton={this.createCoupon}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(Coupon));
