import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { changeIsFirst } from '../../redux/auth/authActions';

// imports icons & utils
import { ReactComponent as CloseButton } from '../../assets/img/svg/main/modal_close.svg';
import { isNotEmpty } from '../../shared/util';
import validate from '../../shared/validation';
import { api } from '../../api';

class PasswordPopup extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    isOpen: PropTypes.bool,
    closePopup: PropTypes.func,
    onOk: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    closePopup: () => {}
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      rePassword: '',
      errorMessage: ''
    };
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleClickButton = this.handleClickButton.bind(this);
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({
        password: '',
        errorMessage: '',
        rePassword: ''
      });
    }
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  handleChangePassword(e) {
    this.setState({
      [e.target.name]: e.target.value,
      errorMessage: ''
    });
  }

  handleClickButton() {
    const { dispatch, closePopup } = this.props;
    const { password, rePassword } = this.state;

    const passwordValidate = validate('password', password);

    // 비밀번호 확인
    if (isNotEmpty(passwordValidate)) {
      this.setState({
        errorMessage: passwordValidate
      });
      return;
    } else if (password !== rePassword) {
      this.setState({
        errorMessage: '비밀번호가 일치하지 않습니다.'
      });
      return;
    } else {
      api.defaults.headers = {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
      };
      const body = {
        password,
        passwordCheck: rePassword
      };

      api
        .patch(`/v1/manager/password`, body)
        .then((res) => {
          closePopup();
          this.props.onOk();
        })
        .catch((err) => {
          // 에러 발생시 자동 팝업
        })
        .finally(() => {});
    }
  }

  // -- render --------------------------------------------------------------------------------
  render() {
    const { errorMessage, password, rePassword } = this.state;
    return (
      <React.Fragment>
        <div className={`popup-wrap ${this.props.isOpen ? 'open' : 'close'} center`} onClick={this.props.closePopup}>
          <div
            className={`popup-container ${this.props.isOpen ? 'open' : 'close'} flex-column`}
            style={{
              width: '370px',
              height: '270px'
            }}
            onClick={(e) => {
              e.stopPropagation(); // 부모 이벤트 막기
            }}
          >
            <div className="title-container">
              <span>패스워드 재설정</span>
              <CloseButton onClick={this.props.closePopup} />
            </div>
            <div className="password-container">
              <p>
                <span>&#183;</span> 영문+숫자+특수문자 8~20자로 조합
              </p>
              <input
                name="password"
                className="input-box"
                type="password"
                placeholder="패스워드 입력"
                value={password}
                onChange={this.handleChangePassword}
              />
              <input
                name="rePassword"
                className="input-box"
                type="password"
                placeholder="패스워드 재입력"
                value={rePassword}
                onChange={this.handleChangePassword}
              />
              {isNotEmpty(errorMessage) && <p className="error">{errorMessage}</p>}
            </div>
            <div className="footer">
              <button
                className="close button-active"
                // onClick={this.props.closePopup}
                onClick={this.handleClickButton}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(PasswordPopup));
