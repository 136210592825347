import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseButton } from '../assets/img/svg/main/modal_close.svg';
import CalenderRange from './general/CalenderRange';
import CustomLoading from './custom/CustomLoading';

// import actions
import { openConfirmPopup } from '../redux/dialog/dialogActions';

// import utils
import { api } from '../api';
import moment from 'moment';
import { isNotEmpty, isEmpty } from '../shared/util';
import { addDays } from '../shared/dateTimeUtil';

class StorePromotionCreateModal extends React.Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object,
    onClose: PropTypes.func,
    onClickButton: PropTypes.func
  };

  // -- constructor ---------------------------------------------------------------------------

  constructor(props) {
    super(props);

    this.state = {
      productList: [],
      name: '',
      startDate: '',
      endDate: '',
      discountType: 'price',
      discountAmount: 0,
      productId: -1,
      isLoadingProducts: false
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { isLoadingProducts } = this.props;
    if (!isLoadingProducts) this.loadProducts();
  }

  componentDidUpdate(prevProps, prevState) {}

  // -- handler -------------------------------------------------------------------------------
  // 메뉴 리스트 로드
  loadProducts = () => {
    const { storeId } = this.props;

    this.setState({
      isLoadingProducts: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .get(`/v1/places/${storeId}/products`)
      .then((res) => {
        const { products } = res.data;
        this.setState({
          productList: products,
          isLoadingProducts: false
        });
      })
      .catch((err) => {
        this.setState({
          isLoadingProducts: false
        });
      });
  };

  /* 확인 버튼 */
  handleClickButton = () => {
    const { onClickButton, storeId, dispatch } = this.props;
    const { name, startDate, endDate, discountType, discountAmount, productId } = this.state;
    const promotion = { storeId, name, startDate, endDate, discountType, discountAmount, productId };

    if (
      isEmpty(storeId) ||
      isEmpty(name) ||
      isEmpty(startDate) ||
      isEmpty(endDate) ||
      isEmpty(discountType) ||
      isEmpty(discountAmount) ||
      isEmpty(productId)
    ) {
      dispatch(
        openConfirmPopup({
          title: 'NOTICE',
          comment: '필수정보 입력 재확인',
          type: 'warning'
        })
      );
    } else onClickButton(promotion);
  };

  /* 내용 변경 */
  hnadleChangeInput = (e) => {
    if (e.target.name === 'discountAmount') {
      const numRegex = /[^0-9]/g;
      if (numRegex.test(e.target.value)) return;
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  // -- render --------------------------------------------------------------------------------

  render() {
    const { onClose } = this.props;
    const { name, startDate, endDate, discountType, discountAmount, productList, isLoadingProducts, productId } =
      this.state;
    return (
      <React.Fragment>
        <div className="modal-container">
          <div className="coupon-create-modal">
            <div className="title-container">
              <span>{this.props.title}</span>
              <CloseButton onClick={() => onClose()} />
            </div>
            <div className="content-container">
              <div className="row">
                <p>˙ 프로모션명에 적용 메뉴와 조건을 기재해주세요.</p>
                <input
                  name="name"
                  className="input-box"
                  type="text"
                  value={name || ''}
                  placeholder="프로모션명 입력 (ex. 아이스아메리카노 1,000원 할인 프로모션)"
                  onChange={this.hnadleChangeInput}
                />
              </div>
              <div className="row">
                <p>˙ 프로모션 기간</p>
                <CalenderRange
                  disabled={false}
                  startDate={isNotEmpty(startDate) ? moment(startDate).format('YYYY-MM-DD') : ''}
                  endDate={isNotEmpty(endDate) ? moment(endDate).format('YYYY-MM-DD') : ''}
                  queryParam={(res) => {
                    const { startDate, endDate } = res;
                    this.setState({
                      startDate,
                      endDate: isNotEmpty(endDate) ? addDays(endDate, 1) : ''
                    });
                  }}
                />
              </div>
              <div className="column">
                <div className="row relative">
                  <p>˙ 프로모션 적용 대상</p>
                  <select
                    name="productId"
                    className="input-box select"
                    style={{ width: '100%' }}
                    onChange={this.hnadleChangeInput}
                    value={productId}
                  >
                    {!isLoadingProducts && (
                      <>
                        <option value={-1}>전메뉴 (가맹점)</option>
                        {productList.map((product, index) => (
                          <option key={index} value={product.id}>
                            {product.name}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  {isLoadingProducts && (
                    <div style={{ position: 'absolute', width: '100%', top: '32px' }}>
                      <CustomLoading size="30px" comment="메뉴 로딩중..." />
                    </div>
                  )}
                </div>
                <div className="row">
                  <p>˙ 프로모션 조건 입력</p>
                  <div>
                    <input
                      name="discountAmount"
                      className="input-box"
                      type="text"
                      placeholder="1000"
                      style={{ width: '65%' }}
                      value={discountAmount || ''}
                      onChange={this.hnadleChangeInput}
                    />
                    <select
                      name="discountType"
                      className="input-box select"
                      value={discountType || ''}
                      style={{ width: '35%', height: 'auto' }}
                      onChange={this.hnadleChangeInput}
                    >
                      <option value={'price'}>원</option>
                      {/* <option value={'percent'}>%</option> */}
                    </select>
                  </div>
                </div>
              </div>
              <span
                className="button"
                style={{ width: '100%', color: 'black', margin: '125px auto 0' }}
                onClick={this.handleClickButton}
              >
                확인
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}
export default connect(select)(StorePromotionCreateModal);
