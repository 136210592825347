import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../assets/scss/_sidebar.scss';
// import { ReactComponent as Logo } from '../assets/img/svg/logo_01.svg';
import { ReactComponent as Logo } from '../assets/img/svg/logo_default.svg';
import { ReactComponent as MerchantIcon } from '../assets/img/svg/sidebar/merchant.svg';
import { ReactComponent as NoticeIcon } from '../assets/img/svg/sidebar/notice.svg';
import { ReactComponent as MenuIcon } from '../assets/img/svg/sidebar/menu.svg';
import { ReactComponent as CouponIcon } from '../assets/img/svg/sidebar/coupon.svg';
import { ReactComponent as ReviewIcon } from '../assets/img/svg/sidebar/review.svg';
import { ReactComponent as CalculateIcon } from '../assets/img/svg/sidebar/calculate.svg';
import { ReactComponent as StoreIcon } from '../assets/img/svg/sidebar/store.svg';
import { ReactComponent as PromotionIcon } from '../assets/img/svg/sidebar/promotion.svg';
import { Link } from 'react-router-dom';
class SideBar extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      storeId: props.match?.params?.storeid || -1
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  renderMenu = (icon, text, link) => {
    const checkUrl = this.props.match.url === link ? true : false;
    return (
      <Link to={link} className="text-link">
        <div className={checkUrl ? `menu active` : `menu`}>
          {icon}
          {text}
        </div>
      </Link>
    );
  };

  adminMenu = () => {
    return (
      <div>
        {this.renderMenu(<MerchantIcon />, '가맹점 관리', '/main')}
        {this.renderMenu(<PromotionIcon />, '프로모션 관리', '/promotion')}
        {this.renderMenu(<CouponIcon />, '쿠폰 관리', '/coupon')}
        {this.renderMenu(<CalculateIcon />, '정산 관리', '/calculate')}
        {this.renderMenu(<NoticeIcon />, '공지 관리', '/notice')}
        {this.renderMenu(<NoticeIcon />, '약관 관리', '/policy')}
      </div>
    );
  };

  storeMenu = () => {
    const { storeId } = this.state;

    return (
      <div>
        {this.renderMenu(<MerchantIcon />, '메인페이지', `/store/${storeId}`)}
        {/* TODO: 동적라우팅, 스토어 아이디를 가지고 있어야할 것 같다. */}
        {this.renderMenu(<NoticeIcon />, '주문 관리', `/store/order/${storeId}`)}
        {this.renderMenu(<MenuIcon />, '메뉴 관리', `/store/menu/${storeId}`)}
        {this.renderMenu(<PromotionIcon />, '프로모션 관리', `/store/promotion/${storeId}`)}
        {this.renderMenu(<CouponIcon />, '쿠폰 관리', `/store/coupon/${storeId}`)}
        {this.renderMenu(<ReviewIcon />, '리뷰 관리', `/store/review/${storeId}`)}
        {this.renderMenu(<CalculateIcon />, '정산 관리', `/store/calculate/${storeId}`)}
        {this.renderMenu(<StoreIcon />, '매장 관리', `/store/branch/${storeId}`)}
      </div>
    );
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    // admin / merchant

    const menuList = this.props.sidebar === 'admin' ? this.adminMenu() : this.storeMenu();

    return (
      <React.Fragment>
        <div className="side-bar">
          <div className="horizontal">
            <Link to="/main" className="text-link">
              <Logo />
            </Link>
          </div>
          <div className="menu-list">{menuList}</div>
          <div className="terms">
            <Link to="/policy_viewer?type=TERMS_OF_SERVICE" className="text-link">
              <p>˙ 이용약관</p>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth
  };
}

export default withRouter(connect(select)(SideBar));
