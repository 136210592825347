import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import ko from 'date-fns/locale/ko';

import TemplateDefault from '../template/TemplateDefault';
import CalenderRange from '../../components/general/CalenderRange';
import CustomLoading from '../../components/custom/CustomLoading';

// import utils & icons
import { ReactComponent as DetailIcon } from '../../assets/img/svg/store/main/go_to_detail.svg';
import { api } from '../../api';
import { numberWithCommas } from '../../shared/formatter';
import { isNotEmpty } from '../../shared/util';
import { addDays } from '../../shared/dateTimeUtil';
import moment from 'moment';
import ResetPassword from '../../components/store/ResetPassword';

class Main extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      storeId: props.match?.params?.storeid || -1,
      toggleTest: true,
      data: {
        createCouponCount: 0,
        orderTotalCount: 0,
        orderTotalPrice: 0,
        remainCouponCount: 0
      },
      isLoading: false,
      startDate: '',
      endDate: ''
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { isLoading } = this.state;
    if (!isLoading) this.loadDashboard();
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, isLoading } = this.state;
    if (isNotEmpty(startDate) && isNotEmpty(endDate)) {
      if (prevState.startDate !== startDate || prevState.endDate !== endDate) {
        if (!isLoading) this.loadDashboard();
      }
    }
  }
  // -- handler -------------------------------------------------------------------------------

  // 데이타 로드
  loadDashboard = () => {
    const { storeId, startDate, endDate } = this.state;

    this.setState({
      isLoading: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .get(
        `/v1/places/${storeId}/dashboard${
          isNotEmpty(startDate) && isNotEmpty(endDate) ? `?startDate=${startDate}&endDate=${endDate}` : ''
        }`
      )
      .then((res) => {
        this.setState({
          data: res.data,
          isLoading: false
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
      });
  };

  // -- render --------------------------------------------------------------------------------

  merchantContents = () => {
    const { data, startDate, endDate, isLoading, storeId } = this.state;

    return (
      <div style={{ width: 'fit-content' }}>
        <div className="top-button-container" style={{ margin: '28px 0 19px auto' }}>
          <CalenderRange
            disabled={isLoading}
            startDate={isNotEmpty(startDate) ? moment(startDate).format('YYYY-MM-DD') : ''}
            endDate={isNotEmpty(endDate) ? moment(endDate).format('YYYY-MM-DD') : ''}
            queryParam={(res) => {
              const { startDate, endDate } = res;
              this.setState({
                startDate,
                endDate: isNotEmpty(endDate) ? addDays(endDate, 1) : ''
              });
            }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div className="store-main-container" style={{ marginRight: '30px' }}>
            <div className="title-container">
              <span>‘수리수리마수리’ 이번달 주문 현황</span>
            </div>
            <div className="content-container">
              <div className="content">
                <div className="left">
                  <p className="total">총 주문 건수 : {numberWithCommas(data.orderTotalCount)} 건</p>
                  <p className="error">오류 처리 건수 : {numberWithCommas(0)} 건</p>
                </div>
                <div className="right">
                  <p className="total">{numberWithCommas(data.orderTotalPrice)} 원</p>
                  <p className="error">정산 금액 : {numberWithCommas(0)} 원</p>
                </div>
              </div>
              <div className="link">
                <Link to="/store/order/1">
                  <DetailIcon />
                </Link>
              </div>
            </div>
          </div>
          <div className="store-main-container">
            <div className="title-container">
              <span>‘수리수리마수리’ 이번달 쿠폰 발행 현황</span>
            </div>
            <div className="content-container">
              <div className="content">
                {isLoading ? (
                  <CustomLoading size="100px" />
                ) : (
                  <>
                    <div className="left">
                      <p className="total">총 발행 쿠폰 : {numberWithCommas(data.createCouponCount)} 건</p>
                      <p className="error">오류 처리 건수 : {numberWithCommas(0)} 건</p>
                    </div>
                    <div className="right">
                      <p className="total">
                        완료 : {numberWithCommas(data.createCouponCount - data.remainCouponCount)}건 / 잔여 :{' '}
                        {numberWithCommas(data.createCouponCount)}건
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="link">
                <Link to="/store/coupon/1">
                  <DetailIcon />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <ResetPassword storeId={storeId} />
        </div>
      </div>
    );
  };

  render() {
    const { version } = this.props;
    const now = format(new Date(Date.now()), 'yyyy.MM.dd (EEEE)', { locale: ko });
    return (
      <React.Fragment>
        <TemplateDefault sidebar="store">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="title">메인페이지</span>
            <span className="title-date">Today : {now}</span>
          </div>
          <this.merchantContents />
        </TemplateDefault>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(Main));
