import { DateTime } from 'luxon';
import { isEmpty } from './util';
import moment from 'moment';

export const getDateTime = (dateTime) => {
  let dt;
  if (typeof dateTime === 'string') {
    dt = DateTime.fromISO(dateTime);
  } else {
    dt = dateTime;
  }
  return dt;
};

export const formatFullShortly = (dateTime) => {
  let dt = getDateTime(dateTime);
  return dt.toFormat('yyyy.MM.dd HH:mm');
};

/**
 * 날짜/시간 전체를 '2021년 03월 14일 (수) 오전 10:30' 형태로 포맷한다.
 * @param dateTime
 * @returns {*}
 */
export const formatFullFriendly = (dateTime) => {
  let dt = getDateTime(dateTime);
  return dt.toFormat('yyyy년 MM월 dd일 (EEEE) a HH:mm');
};

/**
 * 날짜/시간 전체를 '2021년 03월 14일 (수) 오전 10:30' 형태로 포맷한다.
 * @param dateTime
 * @returns {*}
 */
export const formatFullFriendlyShort = (dateTime) => {
  let dt = getDateTime(dateTime);
  return dt.toFormat('yyyy.MM.dd (EEE) HH:mm');
};

/**
 * Get Friendly Date
 * @param dateTime
 */
export const formatFriendlyDate = (dateTime) => {
  let dt = getDateTime(dateTime);
  return dt.toFormat('yyyy년 MM월 dd일 (EEEE)');
};

/**
 * Get Reservation Date
 * @param dateTime
 */
export const formatReservationDate = (dateTime) => {
  let dt = getDateTime(dateTime);
  return dt.toFormat('yyyy년 MM월 dd일');
};

/**
 * Get Reservation Time
 * @param dateTime
 */
export const formatReservationTime = (dateTime) => {
  let dt = getDateTime(dateTime);
  return dt.toFormat('a HH:mm');
};

/**
 * 주어진 분을 시간/분으로 얻는다.
 * @param minutes
 * @returns {{hours: number, minutes: number}}
 */
export const getHourMinute = (minutes) => {
  const ret = {
    hours: 0,
    minutes: 0
  };
  if (!isNaN(minutes)) {
    ret.hours = Math.trunc(minutes / 60);
    ret.minutes = minutes % 60;
  }
  return ret;
};

/**
 * 시간/분을 입력 받아서 친숙한 형식으로 표시한다.
 * {hours:1, minutes:5} => '1시간 5분'
 * {hours:0, minutes:10} => '10분'
 * {hours:5, minutes:0} = > '5시간
 * @param hours
 * @param minutes
 */
export const getDisplayHourMinute = (hours, minutes) => {
  if (hours === 0 && minutes === 0) {
    return '0분';
  }
  if (hours === 0) {
    return `${minutes}분`;
  }
  if (minutes === 0) {
    return `${hours}시간`;
  }
  return `${hours}시간 ${minutes}분`;
};

/**
 * ISO 날짜/시간 형식 문자열을 주어진 Luxon DateTime format 형식으로 반환한다.
 * @param isoString
 * @param format
 * @returns {string}
 */
export const getDisplayFromISO = (isoString, format) => {
  if (isEmpty(isoString)) {
    return '';
  }

  const dateTime = DateTime.fromISO(isoString);
  return dateTime.toFormat(format);
};

/**
 * 주어진 날짜에 더하고 빼기
 * @param date  주어진 날짜
 * @param days  일수 더하기
 */
export const addDays = (date, days) => {
  let result = '';
  if (isEmpty(date) || isEmpty(days)) return result;
  let newDate = moment(date, 'YYYY-MM-DD');
  // 날짜 더하기
  if (days > 0) {
    result = newDate.add(days, 'days').format('YYYY-MM-DD');
  } else {
    result = newDate.subtract(Math.abs(days), 'days').format('YYYY-MM-DD');
  }

  return result;
};
