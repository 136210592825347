import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import CustomErrorMessage from '../custom/CustomErrorMessage';

import { isEmpty, isNotEmpty, returnFileSize } from '../../shared/util';
import { ReactComponent as CloseButton } from '../../assets/img/svg/main/modal_close.svg';
import { ReactComponent as PendingIcon } from '../../assets/img/svg/main/pending.svg';
import { ReactComponent as UploadingIcon } from '../../assets/img/svg/main/uploading.svg';
import { ReactComponent as FailIcon } from '../../assets/img/svg/main/fail.svg';
import { ReactComponent as SuccessIcon } from '../../assets/img/svg/main/success.svg';
import { ReactComponent as FileIcon } from '../../assets/img/svg/main/file.svg';
import { ReactComponent as SmallErrorIcon } from '../../assets/img/svg/main/small_error.svg';

class ImageUploadModal extends React.Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object,
    onClose: PropTypes.func,
    fileChange: PropTypes.func,
    name: PropTypes.string,
    imageUrl: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      // pending, uploading, fail, success
      loadingStatus: 'pending',
      file: {},
      preview: ''
    };
  }

  componentDidUpdate(prevProps, prevState) {}

  loadingStatusRender = (state, option = 'file') => {
    switch (state) {
      case 'pending':
        return (
          <>
            <PendingIcon />
            <span>{option == 'image' ? `jpeg, png (10MB)` : `Low DB Upload (xls, csv)`}</span>
          </>
        );
      case 'uploading':
        return (
          <>
            <UploadingIcon />
            <span>Uploading file</span>
          </>
        );
      case 'fail':
        return (
          <>
            <FailIcon />
            <span>Unable to upload file</span>
          </>
        );
      case 'success':
        return (
          <>
            <SuccessIcon />
            <span>File upload complete!</span>
          </>
        );
    }
  };
  textBoxRender = (state) => {
    switch (state) {
      case 'fail':
        return (
          <>
            <p className="text-box" style={{ marginBottom: '6px' }}>
              <span>
                <FileIcon />
                {this.state.file.name}
              </span>
              <span>{returnFileSize(this.state.file.size)}</span>
            </p>
            <p className="text-box" style={{ marginBottom: '13px' }}></p>
          </>
        );
      case 'success':
        return (
          <>
            <p className="text-box" style={{ marginBottom: '6px' }}>
              <span>
                <FileIcon />
                {this.state.file.name}
              </span>
              <span>{returnFileSize(this.state.file.size)}</span>
            </p>
            <p className="text-box" style={{ marginBottom: '13px', justifyContent: 'flex-start' }}>
              <span>Total : 157,&nbsp;</span>
              <span className="blue">Allow : 97&nbsp;</span>
              <span className="red">Error : 6</span>
            </p>
          </>
        );
      default:
        return (
          <>
            <p className="text-box" style={{ marginBottom: '6px' }}></p>
            <p className="text-box" style={{ marginBottom: '13px' }}></p>
          </>
        );
    }
  };

  /* 이미지 미리보기 
File 혹은 Blob 을 읽은 뒤 base64로 인코딩한 문자열을 FileReader 인스턴스의 result 속성에 담아준다.
*/
  encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        this.setState({ preview: reader.result });
        resolve();
      };
    });
  };

  handleChangeFile = (event) => {
    const { files, name } = event.target;
    const file = files[0];
    this.setState({ file: file });
    this.encodeFileToBase64(file);
  };

  handleClickButton = () => {
    const { name } = this.props;
    const { file } = this.state;
    this.props.fileChange(name, file);
    this.props.onClose();
  };

  render() {
    const isImage = this.props.option === 'image' ? true : false;
    const { name, imageUrl, onClose } = this.props;
    const { preview } = this.state;

    return (
      <React.Fragment>
        <div className="modal-container" style={{ width: '100%' }}>
          <div className={isImage ? 'modal image' : 'modal'}>
            <div className="title-container" style={{ width: 'auto', background: 'white' }}>
              <span style={{ margin: '0' }}>{this.props.title}</span>
              <CloseButton onClick={() => onClose()} />
            </div>
            <div className="content-container">
              {isEmpty(name) ? (
                <CustomErrorMessage />
              ) : (
                <>
                  <p className="texts" style={{ margin: '14px 15px 12px' }}>
                    {isImage ? '이미지 등록' : '대량 업로드'}
                    {/* {isImage ? '메뉴 이미지 등록' : '대량 업로드'} */}
                  </p>
                  <input
                    type="file"
                    id="input-file"
                    style={{ display: 'none' }}
                    name={this.props.name || ''}
                    onChange={this.handleChangeFile}
                  />
                  <label className="input-file-button" htmlFor="input-file">
                    {isNotEmpty(imageUrl) || isNotEmpty(preview) ? (
                      <img
                        className={`loading-status menu`}
                        src={preview ? preview : imageUrl}
                        width="143px"
                        height="114px"
                        style={{ objectFit: 'contain' }}
                      />
                    ) : (
                      <div className={`loading-status ${this.state.loadingStatus}`}>
                        {this.loadingStatusRender(this.state.loadingStatus, this.props.option)}
                      </div>
                    )}
                  </label>
                  {isImage ? (
                    <></>
                  ) : (
                    <>
                      <p className="texts" style={{ margin: '14px 15px 7px' }}>
                        등록 처리
                      </p>
                      {this.textBoxRender(this.state.loadingStatus)}
                    </>
                  )}
                  <span
                    className={isImage ? 'button image' : 'button'}
                    style={{ width: '100%', color: 'black', marginLeft: '0' }}
                    onClick={this.handleClickButton}
                  >
                    확인
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function select(state) {
  return {
    app: state.app,
    auth: state.auth
  };
}
export default connect(select)(ImageUploadModal);
