import reduceReducers from 'reduce-reducers';
import { combineReducers } from 'redux';
import { rootReducer } from './root/rootReducer';
import { authReducer } from './auth/authReducer';
import { dialogReducer } from './dialog/dialogReducer';
import { versionReducer } from './version/versionReducer';
import { categoryReducer } from './category/categoryReducer';
import { globalReducer } from './global/globalReducer';
import { postListReducer } from './test/postListReducer';
import { postReducer } from './test/postReducer';

const combinedReducers = combineReducers({
  commonDialog: dialogReducer,
  auth: authReducer,
  version: versionReducer,
  postList: postListReducer,
  post: postReducer,
  category: categoryReducer,
  global: globalReducer
});

export const reducers = reduceReducers(combinedReducers, rootReducer);
