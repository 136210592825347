import React from 'react';
import CustomPopup from '../custom/CustomPopup';
import defaultIcon from './../../assets/img/png/default_img.png';

const QrCodePopup = ({ isOpen, closePopup, qrCodeUrl }) => {
  return (
    <CustomPopup isOpen={isOpen} closePopup={closePopup} width={'400px'} height={'400px'}>
      <div className="popup-header flex-start">QR Code</div>
      <div className="center" style={{ height: '100%' }}>
        <img
          alt="logo"
          src={qrCodeUrl || defaultIcon}
          style={{
            width: '180px',
            height: '180px'
          }}
        />
      </div>
    </CustomPopup>
  );
};

export default QrCodePopup;
