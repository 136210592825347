import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadProjectVersion } from '../../redux/version/versionActions';
import { changeStoreValue } from '../../redux/root/actions';
import { showDialog } from '../../redux/dialog/dialogActions';
import { getAllPosts } from '../../redux/test/postListActions';
import { getOnePost } from '../../redux/test/postActions';

import '../../assets/scss/template/template-main.scss';

class TestTemplate extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object,
    postList: PropTypes.object,
    post: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {};
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(changeStoreValue('version', 'currentVersion', 'changeStoreValue (3.0.0)'));
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  handleServerVersionInfo = () => {
    const { dispatch } = this.props;
    const onOk = () => {
      dispatch(loadProjectVersion());
    };

    dispatch(getOnePost(3));
    dispatch(getAllPosts());

    dispatch(
      showDialog({
        title: '창제목',
        content: '서버에서 버전 정보를 가져 올까요?',
        onOk: onOk,
        buttonCount: 2
      })
    );
  };

  postListMap = (post, index) => {
    return (
      <div key={index} style={{ textAlign: 'left', width: '80%', display: 'inline-block' }}>
        {post.id} {post.title}
      </div>
    );
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const { version, postList, post } = this.props;
    // const post =  () => postList?.map((p) => {
    //   return <div>{`${p.id} ${p.title} ${p.body}`}</div>;
    // });

    return (
      <React.Fragment>
        <div style={{ textAlign: 'center' }}>
          <h3 className="test">
            <b>test </b>
          </h3>
          <div>
            <button onClick={this.handleServerVersionInfo}>클릭</button>
          </div>
          <br />
          <div># 버전 정보 : {version.currentVersion}</div>
          {/* <div>{JSON.stringify(postList.posts)}</div> */}
          <div>{JSON.stringify(post)}</div>
          <div>{postList.posts.map((post, index) => this.postListMap(post, index))}</div>
          <br />
        </div>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version,
    postList: state.postList,
    post: state.post
  };
}

export default withRouter(connect(select)(TestTemplate));
