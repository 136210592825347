import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import TemplateDefault from '../template/TemplateDefault';
import DatePicker from 'react-datepicker';
import { openConfirmPopup } from '../../redux/dialog/dialogActions';
import { baseURL } from '../../api';
import dayjs from 'dayjs';
const CalculatePage = () => {
  const [selectedDate, setDate] = useState(new Date());
  const [isLoading, setLoading] = useState(false);

  const handleDownload = () => {
    if (isLoading) return;
    setLoading(true);
    const month = dayjs(selectedDate).format('YYYY-MM');
    window.open(`${baseURL}v1/calculate/${month}`);
  };

  return (
    <React.Fragment>
      <TemplateDefault sidebar="store">
        <span className="title">정산 관리</span>
        <div style={{ width: 'fit-content', 'margin-top': '20px' }}>
          <DatePicker
            name="endDate"
            disabled={isLoading}
            dateFormat="yyyy.MM"
            selected={selectedDate}
            showMonthYearPicker
            locale={'ko'}
            onChange={async (d) => {
              setDate(d);
            }}
          />
          <button style={{ 'margin-top': '8px' }} className={'button'} onClick={handleDownload}>
            정산내역 다운받기
          </button>
        </div>
      </TemplateDefault>
    </React.Fragment>
  );
};

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(CalculatePage));
