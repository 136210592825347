import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TemplateDefault from '../template/TemplateDefault';

// import components
import CustomPagination from '../../components/custom/CustomPagination';
import CustomLoading from '../../components/custom/CustomLoading';
import CustomErrorMessage from '../../components/custom/CustomErrorMessage';
import AdminPromotionDetailModal from '../../components/AdminPromotionDetailModal';

// import icon & utils
import { api } from '../../api';
import { isNotEmpty } from '../../shared/util';

class Promotion extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      limit: 50,
      page: 1,
      count: 0,
      placeList: [],
      modalPromotionList: [],
      isLoading: false,
      errorMsg: ''
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { isLoading } = this.state;

    // 프로모션 리스트 조회
    if (!isLoading) this.loadPromotionList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { page } = this.state;

    // 페이지 변경
    if (prevState.page !== page) {
      this.loadPromotionList();
    }
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  // 프로모션 조회
  loadPromotionList = () => {
    const { page, limit } = this.state;
    this.setState({ isLoading: true, errorMsg: '' });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };

    api
      .get(`/v1/promotions?limit=${limit}&page=${page}`)
      .then((res) => {
        this.setState({ isLoading: false, placeList: res.data?.places, count: res.data?.count });
      })
      .catch((err) => {
        this.setState({ isLoading: false, errorMsg: '예기치 못한 에러가 발생되었습니다.' });
      })
      .finally(() => {});
  };

  /* 상세정보 팝업창 열기 */
  onClickPromotionModal = (id) => {
    const { placeList } = this.state;
    const findIndex = placeList?.findIndex((v) => v.id === id);
    const place = placeList[findIndex];
    const placePromotionList = place.placePromotions.map((v) => {
      return { ...v.promotion, type: '전메뉴' };
    });
    const productPromotionList = place.productPromotions.map((v) => {
      return { ...v.promotion, type: '특정메뉴' };
    });
    const totlePromotionList = placePromotionList?.concat(productPromotionList);

    this.setState({
      showModal: true,
      modalPromotionList: totlePromotionList
    });
  };

  // 이전 페이지
  handleClickBack = () => {
    this.setState((prev) => ({
      page: prev.page - 1
    }));
  };
  // 다음 페이지
  handleClickForward = () => {
    this.setState((prev) => ({
      page: prev.page + 1
    }));
  };

  // -- render --------------------------------------------------------------------------------

  /* 리스트 헤더 */
  renderHeader = (widthSize) => {
    const tableHeader = ['가맹점명', '프로모션 종류', '총 프로모션 참여수', '진행중 프로모션', '종료 프로모션'];
    return (
      <div className="row th">
        <p style={{ width: widthSize[0] }}>{tableHeader[0]}</p>
        <p style={{ width: widthSize[1] }}>{tableHeader[1]}</p>
        <p style={{ width: widthSize[2] }}>{tableHeader[2]}</p>
        <p style={{ width: widthSize[3] }}>{tableHeader[3]}</p>
        <p style={{ width: widthSize[4] }}>{tableHeader[4]}</p>
      </div>
    );
  };

  /* 리스트 Row */
  renderRow = (data, index, widthSize) => {
    const { id, name, totalPromotions, usedCount, publishingCount, finishCount } = data;
    return (
      <div className="row" key={index}>
        <p className="ellipsis" style={{ width: widthSize[0] }}>
          {' '}
          {name}
        </p>
        <p
          style={{ width: widthSize[1], cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => this.onClickPromotionModal(id)}
        >
          {totalPromotions}
        </p>
        <p style={{ width: widthSize[2] }}>{usedCount}</p>
        <p style={{ width: widthSize[3] }}>{publishingCount}</p>
        <p style={{ width: widthSize[4] }}>{finishCount}</p>
      </div>
    );
  };

  render() {
    const { page, limit, count, isLoading, placeList, errorMsg } = this.state;
    const widthSize = ['25%', '25%', '25%', '25%', '25%'];

    return (
      <React.Fragment>
        <TemplateDefault sidebar="admin">
          <span className="title">프로모션 관리</span>
          <div style={{ width: 'fit-content' }}>
            <div className="top-button-container">
              <div style={{ height: '40px' }}></div>
            </div>
            <div className="container">
              <div className="table">
                {this.renderHeader(widthSize)}
                {isLoading ? (
                  <CustomLoading size="100px" />
                ) : isNotEmpty(errorMsg) ? (
                  <CustomErrorMessage message={errorMsg} />
                ) : (
                  <div className="rows">{placeList?.map((data, index) => this.renderRow(data, index, widthSize))}</div>
                )}
              </div>
              {/* 하단 */}
              <CustomPagination
                totalCount={count}
                page={page}
                limit={limit}
                onClickBack={this.handleClickBack}
                onClickForward={this.handleClickForward}
                isLoading={isLoading}
              />
            </div>
          </div>
        </TemplateDefault>
        {this.state.showModal ? (
          <AdminPromotionDetailModal
            title="프로모션 종류 세부 리스트"
            onClose={() => {
              this.setState({ showModal: false, modalPromotionList: [] });
            }}
            promotionList={this.state.modalPromotionList}
            loadPromotionList={this.loadPromotionList}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(Promotion));
