import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isNotEmpty } from '../shared/util';
import { logout } from './../redux/auth/authActions';

// import styles && icons
import { ReactComponent as PasswordIcon } from '../assets/img/svg/popup/password_icon.svg';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

class SettingDropDown extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object,
    toggleCsInfoPopup: PropTypes.func,
    toggleChangePassword: PropTypes.func,
    closeSettingDropdown: PropTypes.func
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {};
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  handleClickLogout = () => {
    const { history, closeSettingDropdown, dispatch } = this.props;
    closeSettingDropdown();
    dispatch(logout());
    history.push('/');
  };

  onClickPolicyService = (e) => {
    e.stopPropagation(); // 부모 이벤트 막기
    const { history, closeSettingDropdown } = this.props;
    closeSettingDropdown();
    history.push('/policy/service');
  };

  onClickPolicyUsage = (e) => {
    e.stopPropagation(); // 부모 이벤트 막기
    const { history, closeSettingDropdown } = this.props;
    closeSettingDropdown();
    history.push('/policy/usage');
  };

  onClickNotice = (e) => {
    e.stopPropagation(); // 부모 이벤트 막기
    const { history, closeSettingDropdown } = this.props;
    closeSettingDropdown();
    history.push('/notice');
  };
  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <div className="dropdown-container flex-column">
        <div className="vertical" onClick={this.props.toggleChangePassword}>
          <PasswordIcon />
          <span>패스워드 수정</span>
        </div>
        <div className="vertical" onClick={this.handleClickLogout}>
          <ExitToAppIcon style={{ width: '30px', height: '30px' }} />
          <span>로그아웃</span>
        </div>
      </div>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(SettingDropDown));
