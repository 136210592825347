import { COMMON_DIALOG_STATE_CHANGED, COMMON_CONFIRM_POPUP_OPEN, COMMON_CONFIRM_POPUP_CLOSE } from '../actionTypes';

export const showDialog = (dialogOptions) => {
  const opt = {
    title: dialogOptions.title ? dialogOptions.title : null,
    content: dialogOptions.content ? dialogOptions.content : null,
    buttonCount: dialogOptions.buttonCount ? dialogOptions.buttonCount : 1,

    onOk: dialogOptions.onOk ? dialogOptions.onOk : null,
    onCancel: dialogOptions.onCancel ? dialogOptions.onCancel : null,
    okButtonText: dialogOptions.okButtonText ? dialogOptions.okButtonText : '확인',
    cancelButtonText: dialogOptions.cancelButtonText ? dialogOptions.cancelButtonText : '취소',

    useClickAway: typeof dialogOptions.useClickAway === 'boolean' ? dialogOptions.useClickAway : true,
    modalStyle: dialogOptions.modalStyle ? dialogOptions.modalStyle : null
  };

  return {
    type: COMMON_DIALOG_STATE_CHANGED,
    show: true,
    ...opt
  };
};

export const closeDialog = () => {
  return {
    type: COMMON_DIALOG_STATE_CHANGED,
    show: false,
    title: null,
    content: null,
    buttonCount: 1,
    onOk: null,
    onCancel: null,
    cancelButtonText: '취소',
    okButtonText: '확인',
    useClickAway: true,
    modalStyle: null
  };
};

/* 확인 팝업창
 type : success - 성공, warning - 경고, delete - 삭제
 title - 제목
 content - 내용
 onOk - action
*/
export const openConfirmPopup = (option) => {
  const opt = {
    title: option.title ? option.title : 'NOTICE',
    comment: option.comment ? option.comment : '완료',
    popupType: option.type ? option.type : 'success',
    onOk: option.onOk ? option.onOk : null
  };
  return {
    type: COMMON_CONFIRM_POPUP_OPEN,
    ...opt
  };
};

/* 상태 팝업창 닫기 */
export const closeConfirmPopup = () => {
  return {
    type: COMMON_CONFIRM_POPUP_CLOSE
  };
};
