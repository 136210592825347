// --------------------------------------
// service Data Utils
// --------------------------------------

/**
 * 쿠폰, 프로모션 상태 데이터 가공
 * @param statusCode
 * @returns {title, color}
 */
export const getPromotionCouponStatus = (statusCode) => {
  let title = '종료';
  let color = '#BCBCBC';

  if (statusCode === 'P') {
    title = '발행';
    color = '#3cc13b';
  } else if (statusCode === 'S') {
    title = '중단';
    color = '#FE4B4C';
  } else if (statusCode === 'F') {
    title = '종료';
    color = '#BCBCBC';
  } else if (statusCode === 'R') {
    title = '예약';
    color = '#FFE600';
  }

  return { title, color };
};
