import { api } from '../../api';
import { GET_POST_CONTENT, UPDATE_POSTS } from '../actionTypes';
import { url } from './postListActions';

export const getOnePost = (postId = 1) => {
  return (dispatch) => {
    api
      .get(`${url}/posts/${postId}`)
      .then((res) => {
        const { data } = res;
        // console.log(data);
        // const result = {
        //   id: data.id,
        //   title: data.title,
        //   body: data.body,
        //   userId: data.userId,
        // };
        dispatch({
          type: GET_POST_CONTENT,
          id: data.id,
          title: data.title,
          body: data.body,
          userId: data.userId
        });
      })
      .finally(() => {});
  };
};
export const updatePost = (
  postId = 1,
  changeParams = {
    id: 1,
    title: 'foo',
    body: 'bar',
    userId: 1
  }
) => {
  return (dispatch) => {
    api
      .put(`${url}/posts/${postId}`, changeParams)
      .then((res) => {
        const { data } = res;
        // const result = {
        //   id: data.id,
        //   title: data.title,
        //   body: data.body,
        //   userId: data.userId,
        // };
        // dispatch({
        //   type: GET_POST_CONTENT,
        //   id: data.id,
        //   title: data.title,
        //   body: data.body,
        //   userId: data.userId,
        // });
      })
      .finally(() => {});
  };
};
