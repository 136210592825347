import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';

const useStyles = makeStyles((theme) => ({
  margin: {
    padding: '10px'
  },
  extendedIcon: {
    padding: '10px'
  }
}));

const CustomPagination = ({ totalCount = 0, page = 0, limit = 0, onClickBack, onClickForward, isLoading }) => {
  const classes = useStyles();
  return (
    <div className="pagination-footer flex-end">
      <span>{totalCount}개 중</span>
      <span>
        {`${(page - 1) * limit + 1} - 
                  ${totalCount <= page * limit ? totalCount : page * limit}`}
      </span>
      <IconButton
        aria-label="back"
        className={classes.margin}
        size="small"
        onClick={onClickBack}
        disabled={page <= 1 || isLoading}
      >
        <ArrowBackIosOutlinedIcon fontSize="medium" />
      </IconButton>
      <IconButton
        aria-label="forward"
        className={classes.margin}
        size="small"
        onClick={onClickForward}
        disabled={totalCount <= page * limit || isLoading}
      >
        <ArrowForwardIosOutlinedIcon fontSize="medium" />
      </IconButton>
    </div>
  );
};

export default CustomPagination;
