import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import components
import TemplateDefault from '../template/TemplateDefault';

// import utils & icons
import update from 'react-addons-update';
import moment from 'moment';
import { api } from '../../api';
import { format } from 'date-fns';
import ko from 'date-fns/locale/ko';
import BranchStore from '../../components/BranchStore';
import BranchBuisness from '../../components/BranchBuisness';

class Branch extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      storeId: props.match?.params?.storeid || -1,
      tab: 'store', // store, buisness
      isLoading: false, // 조회중
      toggleTest: false,
      editMode: false // 수정 모드
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    // const { tab } = this.state;
    // if (tab === 'store') this.loadStoreInfo();
    // else if (tab === 'buisness') this.loadBuisnessInfo();
  }

  componentDidUpdate(prevProps, prevState) {
    // const { tab } = this.state;
    // // 페이지 변경
    // if (prevState.tab !== tab) {
    //   if (tab === 'store') this.loadStoreInfo();
    //   else if (tab === 'buisness') this.loadBuisnessInfo();
    // }
  }
  // -- feature -------------------------------------------------------------------------------
  // -- handler -------------------------------------------------------------------------------

  handleChangeLoading = (value) => {
    this.setState({ isLoading: value });
  };

  merchantContents = () => {
    const { editMode, isLoading } = this.state;
    return (
      <div style={{ width: 'fit-content' }}>
        <div className="top-button-container" style={{ margin: '28px 0 19px auto', position: 'relative' }}>
          <div
            className={this.state.tab === 'store' ? 'content-header active' : 'content-header'}
            onClick={() => {
              if (!isLoading) this.setState({ tab: 'store' });
            }}
          >
            매장 운영 정보
          </div>
          <div
            className={this.state.tab === 'buisness' ? 'content-header active' : 'content-header'}
            style={{ left: '292px' }}
            onClick={() => {
              if (!isLoading) this.setState({ tab: 'buisness' });
            }}
          >
            사업자 등록 정보
          </div>
        </div>
        <div>
          <div className="store-main-container branch">
            {this.state.tab === 'store' ? (
              <BranchStore handleChangeLoading={this.handleChangeLoading} isLoading={isLoading} />
            ) : (
              <BranchBuisness handleChangeLoading={this.handleChangeLoading} isLoading={isLoading} />
            )}
          </div>
        </div>
      </div>
    );
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <TemplateDefault sidebar="store">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="title">매장 관리</span>
          </div>
          {this.merchantContents()}
        </TemplateDefault>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(Branch));
