import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { api } from '../../api';

class PolicyViewer extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      data: {}
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { isLoading } = this.state;
    // 약관 리스트 조회
    if (!isLoading) this.fetch();
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  fetch = () => {
    const [_, matched] = /type=(.+)/.exec(this.props.location.search);
    api.get(`/v1/policy/applied/${matched}`).then(({ data }) => {
      if (!data || !data.content) {
        return this.setState({
          data: {
            content: '(no data)'
          }
        });
      }

      data.content = data.content.replace(/\n/g, '<br/>');
      this.setState({ data });
    });
  };

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.state.data.content }} style={{ padding: 20 }}></div>;
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(PolicyViewer));
