import { api } from '../../api';
import {
  LOAD_STORE_CATEGORY_REQUEST,
  LOAD_STORE_CATEGORY_SUCCESS,
  LOAD_STORE_CATEGORY_FAILURE,
  LOAD_STORE_SERVICE_REQUEST,
  LOAD_STORE_SERVICE_SUCCESS,
  LOAD_STORE_SERVICE_FAILURE
} from '../actionTypes';

/**
 * 매장 카테고리 정보 불러오기
 */
export const loadStoreCategory = () => {
  return (dispatch) => {
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    dispatch({ type: LOAD_STORE_CATEGORY_REQUEST });
    return api
      .get(`/v1/places/category`)
      .then((res) => {
        dispatch({
          type: LOAD_STORE_CATEGORY_SUCCESS,
          storeCategory: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: LOAD_STORE_CATEGORY_FAILURE
          // error: err.response?.data?.error?.message
        });
      })
      .finally(() => {});
  };
};

/**
 * 매장 카테고리 정보 불러오기
 */
export const loadStoreService = () => {
  return (dispatch) => {
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    dispatch({ type: LOAD_STORE_SERVICE_REQUEST });

    return api
      .get(`/v1/places/service`)
      .then((res) => {
        dispatch({
          type: LOAD_STORE_SERVICE_SUCCESS,
          storeService: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: LOAD_STORE_SERVICE_FAILURE
          // error: err.response?.data?.error?.message
        });
      })
      .finally(() => {});
  };
};
