import React, { Component, forwardRef, useImperativeHandle } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import components
import ImageUploadModal from './general/ImageUploadModal';
import CustomPopup from './custom/CustomPopup';
import DaumPostCode from 'react-daum-postcode';
import QrCodePopup from './general/QrCodePopup';
import { openConfirmPopup, closeConfirmPopup } from '../redux/dialog/dialogActions';
import CustomLoading from './custom/CustomLoading';
import CustomErrorMessage from './custom/CustomErrorMessage';

// import material
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// import utils & icons
import { loadStoreCategory, loadStoreService } from '../redux/category/categoryActions';
import { ReactComponent as SheetIcon } from '../assets/img/svg/main/sheet.svg';
import { ReactComponent as QrCodeIcon } from '../assets/img/svg/store/qrcode_icon.svg';
import update from 'react-addons-update';
import { api } from '../api';
import { isEmpty, isNotEmpty } from '../shared/util';
import { backdropLoadingStart, backdropLoadingStop } from '../redux/global/globalActions';

class BranchStore extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    storeCategory: PropTypes.array,
    storeService: PropTypes.array,
    handleChangeLoading: PropTypes.func
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      loadingError: '',
      editMode: false,
      storeId: props.match?.params?.storeid || -1,
      storeInfo: {},
      placeCategory: '',
      isOpen: '',
      /* 파일 */
      logoFile: {},
      logoFileUrl: '',
      logoFileName: '',
      storeFile1: {},
      storeFile1Name: '',
      storeFile1Url: '',
      storeFile2: {},
      storeFile2Name: '',
      storeFile2Url: '',
      storeFile3: {},
      storeFile3Name: '',
      storeFile3Url: '',
      menuFile: {},
      menuFileUrl: '',
      menuFileName: '',
      /* 매장서비스 */
      placeServiceList: [],
      /* 팝업창 */
      isOpenImageModal: false,
      isOpenDaumPost: false,
      isOpenStoreService: false,
      isOpenQrcodePopup: false
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { dispatch, storeCategory, storeService } = this.props;
    // 매장 정보 로드
    this.loadStoreInfo();

    // 매장 카테고리 로드
    if (isEmpty(storeCategory)) dispatch(loadStoreCategory());

    // 매장 서비스 로드
    if (isEmpty(storeService)) dispatch(loadStoreService());
  }

  componentDidUpdate(prevProps, prevState) {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // 매장 운영 정보 로드
  loadStoreInfo = () => {
    const { handleChangeLoading } = this.props;
    const { storeId } = this.state;

    this.setState({ isLoading: true, loadingError: '' });
    handleChangeLoading(true); // 부모 탭 클릭 막기

    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };

    api
      .get(`/v1/places/${storeId}/info`)
      .then((res) => {
        const {
          logoFileName,
          logoUrl,
          menuFileName,
          menuImageUrl,
          placeCategory,
          deletedAt,
          placeImages,
          placeService,
          ...storeInfo
        } = res.data;

        this.setState({
          isLoading: false,
          storeInfo: storeInfo,
          placeCategory: placeCategory?.id || '',
          isOpen: isEmpty(deletedAt) ? 'on' : 'off', // 매장 영업중 유무 (폐업유무)
          //file
          logoFile: {},
          logoFileUrl: logoUrl || '',
          logoFileName: logoFileName || '',
          menuFile: {},
          menuFileUrl: menuImageUrl || '',
          menuFileName: menuFileName || '',
          storeFile1: {},
          storeFile1Name: placeImages?.[0]?.fileName || '',
          storeFile1Url: placeImages?.[0]?.url || '',
          storeFile2: {},
          storeFile2Name: placeImages?.[1]?.fileName || '',
          storeFile2Url: placeImages?.[1]?.url || '',
          storeFile3: {},
          storeFile3Name: placeImages?.[2]?.fileName || '',
          storeFile3Url: placeImages?.[2]?.url || '',
          placeServiceList: placeService.map((v) => v.serviceId)
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false, loadingError: 'error' });
      })
      .finally(() => {
        handleChangeLoading(false);
      });
  };

  // 정보 변경
  handleChangeInput = (e) => {
    if (e.target.name === 'placeCategory' || e.target.name === 'isOpen') {
      this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.name.indexOf('service') !== -1) {
      this.setState({ [e.target.name]: e.target.checked });
    } else this.setState({ storeInfo: update(this.state.storeInfo, { [e.target.name]: { $set: e.target.value } }) });
  };

  /* 이미지 미리보기
   * File 혹은 Blob 을 읽은 뒤 base64로 인코딩한 문자열을 FileReader 인스턴스의 result 속성에 담아준다.
   */
  encodeFileToBase64 = (name, fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        if (name === 'logoFile') this.setState({ logoFileUrl: reader.result });
        else if (name === 'storeFile1') this.setState({ storeFile1Url: reader.result });
        else if (name === 'storeFile2') this.setState({ storeFile2Url: reader.result });
        else if (name === 'storeFile3') this.setState({ storeFile3Url: reader.result });
        resolve();
      };
    });
  };

  /* 파일 업로드 */
  fileChange = (name, file) => {
    this.setState({ [name]: file });
    this.encodeFileToBase64(name, file);
  };

  /* 파일 업로드 팝업창 닫기 */
  onCloseUploadModal = () => {
    this.setState({ isOpenImageModal: false });
  };

  /* 주소 검색 */
  handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    //fullAddress -> 전체 주소반환
    this.setState({ storeInfo: update(this.state.storeInfo, { address: { $set: fullAddress } }) });
  };

  /* QrCode 팝업창 닫기 */
  closeQrcodePopup = () => {
    this.setState({
      isOpenQrcodePopup: false
    });
  };

  // 사진 삭제 기능
  handleRemoveImage = (target, fileInfo) => {
    const { storeId } = this.state;
    const { dispatch, handleChangeLoading } = this.props;
    // 파일 새로 등록 상태
    if (typeof fileInfo === 'object') {
      // 단순 제거
      this.setState({ [target]: {}, [`${target}Url`]: '' });
    }
    // 이미 등록된 파일상태
    else if (typeof fileInfo === 'string') {
      // 실제 파일 삭제 요청 필요

      const onOk = () => {
        dispatch(closeConfirmPopup());
        handleChangeLoading(true);
        dispatch(backdropLoadingStart());
        const body = {
          target,
          fileInfo
        };
        api.defaults.headers = {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken')
        };

        api
          .post(`/v1/places/file/${storeId}/remove`, body)
          .then((res) => {
            handleChangeLoading(false);
            dispatch(backdropLoadingStop());

            const onOk = () => {
              dispatch(closeConfirmPopup());
              this.loadStoreInfo(); // 매장 정보 로드
            };

            dispatch(
              openConfirmPopup({
                title: 'NOTICE',
                comment: '삭제 완료',
                type: 'success',
                onOk: onOk
              })
            );
          })
          .catch((err) => {
            handleChangeLoading(false);
            dispatch(backdropLoadingStop());
          });
      };

      dispatch(
        openConfirmPopup({
          title: 'NOTICE',
          comment: (
            <>
              <div>파일 삭제 후 되돌릴 수 없습니다.</div>
              <div>정말 삭제하시겠습니까?</div>
            </>
          ),
          type: 'delete',
          onOk: () => {
            onOk();
          }
        })
      );
    }
  };

  /* 저장 */
  handleClickSaveButton = () => {
    const { dispatch, handleChangeLoading } = this.props;
    const {
      storeId,
      storeInfo: { name, address, addressDetail, phone, placeType, placeOpenTime, comment, content, qrCodeUrl },
      placeServiceList,
      placeCategory,
      isOpen,
      logoFile,
      storeFile1,
      storeFile2,
      storeFile3,
      menuFile
    } = this.state;

    /* 필수값 체크 */
    let isEmptyValue;
    if (
      isEmpty(name)
      // isEmpty(addressDetail) ||
      // isEmpty(phone) ||
      // isEmpty(placeOpenTime) ||
      // isEmpty(comment) ||
      // isEmpty(content) ||
    ) {
      isEmptyValue = '매장명';
    } else if (isEmpty(address)) {
      isEmptyValue = '주소';
    } else if (isEmpty(placeCategory)) {
      isEmptyValue = '카테고리';
    } else if (isEmpty(isOpen)) {
      isEmptyValue = '영업상태';
    } else if (isEmpty(placeType)) {
      isEmptyValue = '오더서비스';
    }

    if (isNotEmpty(isEmptyValue)) {
      dispatch(
        openConfirmPopup({
          title: 'NOTICE',
          comment: `필수정보 입력 재확인(${isEmptyValue})`,
          type: 'warning'
        })
      );
    } else {
      handleChangeLoading(true);
      dispatch(backdropLoadingStart());
      const formData = new FormData();
      formData.append('name', name);
      formData.append('isOpen', isOpen === 'on' ? true : false);
      formData.append('address', address);
      formData.append('addressDetail', addressDetail);
      formData.append('phone', phone);
      formData.append('placeType', placeType);
      formData.append('placeOpenTime', placeOpenTime);
      formData.append('comment', comment);
      formData.append('content', content);
      formData.append('placeCategory', placeCategory);
      formData.append('placeServiceList', JSON.stringify(placeServiceList));

      if (isNotEmpty(logoFile)) formData.append('logoImage', logoFile);
      if (isNotEmpty(storeFile1)) formData.append('placeImage1', storeFile1);
      if (isNotEmpty(storeFile2)) formData.append('placeImage2', storeFile2);
      if (isNotEmpty(storeFile3)) formData.append('placeImage3', storeFile3);
      if (isNotEmpty(menuFile)) formData.append('menuImage', menuFile);

      api.defaults.headers = {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
      };
      api
        .put(`/v1/places/${storeId}/info`, formData)
        .then((res) => {
          handleChangeLoading(false);
          dispatch(backdropLoadingStop());

          const onOk = () => {
            dispatch(closeConfirmPopup());
            this.loadStoreInfo(); // 매장 정보 로드
          };

          dispatch(
            openConfirmPopup({
              title: 'NOTICE',
              comment: '저장 완료',
              type: 'success',
              onOk: onOk
            })
          );
        })
        .catch((err) => {
          handleChangeLoading(false);
          dispatch(backdropLoadingStop());
        });
    }
  };

  // 서비스 선택
  handleCheckService = (id) => {
    const { placeServiceList } = this.state;
    const isChecked = placeServiceList.includes(id);

    // 체크
    if (!isChecked) {
      this.setState({
        placeServiceList: update(placeServiceList, { $push: [id] })
      });
    }
    // 체크 해제
    else {
      // const newList = placeServiceList?.filter((v) => v !== id);
      const index = placeServiceList.findIndex((v) => v === id);
      if (index !== -1)
        this.setState({
          placeServiceList: update(placeServiceList, { $splice: [[index, 1]] })
        });
    }
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const { storeCategory, storeService } = this.props;
    const {
      isLoading,
      loadingError,
      editMode,
      logoFile,
      storeFile1,
      storeFile2,
      storeFile3,
      storeFile1Url,
      storeFile1Name,
      storeFile2Url,
      storeFile2Name,
      storeFile3Url,
      storeFile3Name,
      menuFile,
      menuFileUrl,
      menuFileName,
      isOpenDaumPost,
      isOpenImageModal,
      isOpenStoreService,
      isOpenQrcodePopup,
      logoFileUrl,
      logoFileName,
      storeInfo: { name, address, addressDetail, phone, placeType, placeOpenTime, comment, content, qrCodeUrl },
      placeCategory,
      placeServiceList,
      isOpen // 영업중여부(폐업여부)
    } = this.state;
    let placeServiceName = '';
    if (isNotEmpty(storeService)) {
      for (let i = 0; i < placeServiceList.length; i++) {
        const findIndex = storeService.findIndex((v) => v.id === placeServiceList[i]);
        const categoryName = storeService?.[findIndex]?.name;

        if (i === 0) placeServiceName = categoryName;
        else placeServiceName += ',' + categoryName;
      }
    }

    return isLoading ? (
      <CustomLoading size={'100px'} />
    ) : isNotEmpty(loadingError) ? (
      <CustomErrorMessage />
    ) : (
      <React.Fragment>
        <div
          className="qrcode-div"
          onClick={() => {
            this.setState({ isOpenQrcodePopup: true });
          }}
        >
          <QrCodeIcon />
        </div>
        <div className="button-div">
          <button
            className="button button-active"
            onClick={() => {
              this.setState((prev) => ({
                editMode: !prev.editMode
              }));
            }}
          >
            수정
          </button>
          <button
            className={`button ${editMode ? ' button-active' : ' middle-gray'}`}
            onClick={this.handleClickSaveButton}
            disabled={!editMode}
          >
            저장
          </button>
        </div>
        <div className="content-div" style={{ background: 'none' }}>
          <div>
            <p className="title">
              <SheetIcon />
              매장 기본 정보
            </p>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>매장 로고 등록
              </p>
              <div className="item-input">
                <label className="input-file-button">
                  <a download target="_blank" href={logoFileUrl || null}>
                    <div className={`input-box ${editMode ? ' mint' : ''}`}>
                      {isNotEmpty(logoFile) ? logoFile?.name : logoFileName}
                    </div>
                  </a>
                </label>
                {isNotEmpty(logoFile) || isNotEmpty(logoFileName) ? (
                  <button
                    className={`button ${editMode ? ' hyper-mint' : ''}`}
                    onClick={() => this.handleRemoveImage('logoFile', isNotEmpty(logoFileUrl) ? logoFileUrl : logoFile)}
                    disabled={!editMode}
                  >
                    삭제
                  </button>
                ) : (
                  <button
                    className={`button ${editMode ? ' hyper-mint' : ''}`}
                    onClick={() => {
                      this.setState({ isOpenImageModal: 'logoFile' });
                    }}
                    disabled={!editMode}
                  >
                    등록
                  </button>
                )}
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>매장명
              </p>
              <div className="item-input">
                <input
                  name="name"
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  value={name || ''}
                  type="text"
                  placeholder="수리수리마수리"
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>매장 주소
              </p>
              <div className="item-input">
                <input
                  name="address"
                  value={address || ''}
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  type="text"
                  placeholder="주소"
                  onChange={this.handleChangeInput}
                  disabled
                />
                <button
                  className={`button ${editMode ? ' hyper-mint' : ''}`}
                  onClick={() => {
                    this.setState({ isOpenDaumPost: true });
                  }}
                  disabled={!editMode}
                >
                  주소 검색
                </button>
              </div>
              <div className="item-input" style={{ marginTop: '5px' }}>
                <input
                  name="addressDetail"
                  value={addressDetail || ''}
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  type="text"
                  placeholder="상세주소"
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>매장 대표번호
              </p>
              <div className="item-input">
                <input
                  name="phone"
                  value={phone || ''}
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  type="text"
                  placeholder="02-1234-5678"
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="content-div"
          style={{ background: 'none', borderLeft: '1px solid #545454', borderRight: '1px solid #545454' }}
        >
          <div>
            <p className="title">
              <SheetIcon />
              매장 영업 정보
            </p>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>매장 카테고리
              </p>
              <div className="item-input">
                <select
                  name="placeCategory"
                  value={placeCategory || ''}
                  className={`input-box select ${editMode ? ' mint' : ''}`}
                  required
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                >
                  <option value="" disabled>
                    한식
                  </option>
                  {storeCategory?.map((v) => (
                    <option key={`${v.id}-${v.name}`} value={v.id}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>스마트 오더 서비스 종류
              </p>
              <div className="item-input">
                <select
                  name="placeType"
                  value={placeType || ''}
                  className={`input-box select ${editMode ? ' mint' : ''}`}
                  required
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                >
                  <option value="" disabled>
                    테이블/픽업, 테이블오더, 픽업오더
                  </option>
                  <option value="TP">테이블/픽업</option>
                  <option value="T">테이블오더</option>
                  <option value="P">픽업오더</option>
                </select>
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>영업 상태
              </p>
              <div className="item-input">
                <select
                  name="isOpen"
                  value={isOpen || ''}
                  className={`input-box select ${editMode ? ' mint' : ''}`}
                  required
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                >
                  <option value="" disabled>
                    영업중
                  </option>
                  <option value={'on'}>영업중</option>
                  <option value={'off'}>영업종료</option>
                </select>
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>영업 시간
              </p>
              <div className="item-input">
                <input
                  name="placeOpenTime"
                  value={placeOpenTime || ''}
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  type="text"
                  placeholder="월,수,금 09:00 ~ 20:00"
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>매장 서비스
              </p>
              <div className="item-input">
                <div className="item-input">
                  <input
                    name="storeService"
                    className={`input-box ${editMode ? ' mint' : ''}`}
                    type="text"
                    value={placeServiceName || ''}
                    placeholder="주차, 와이파이, 장애인 편의시설, 포장, 배달, 비건"
                    onChange={() => {}}
                    disabled
                  />
                  <button
                    className={`button ${editMode ? ' hyper-mint' : ''}`}
                    onClick={() => {
                      this.setState({ isOpenStoreService: true });
                    }}
                    disabled={!editMode}
                  >
                    설정
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-div" style={{ background: 'none' }}>
          <div>
            <p className="title">
              <SheetIcon />
              매장 소개 관련
            </p>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>매장 사진
              </p>
              <div className="item-input">
                <label className="input-file-button" /*htmlFor="input-file"*/>
                  <a download target="_blank" href={storeFile1Url || null}>
                    <div className={`input-box ${editMode ? ' mint' : ''}`} style={{ marginBottom: '5px' }}>
                      <span className="ellipsis">{isNotEmpty(storeFile1) ? storeFile1?.name : storeFile1Name}</span>
                    </div>
                  </a>
                </label>
                <button
                  className={`button ${editMode ? ' hyper-mint' : ''}`}
                  onClick={() => {
                    this.setState({ isOpenImageModal: 'storeFile1' });
                  }}
                  disabled={!editMode}
                >
                  등록
                </button>
              </div>
              <div className="item-input">
                <label className="input-file-button" htmlFor="input-file2">
                  <a download target="_blank" href={storeFile2Url || null}>
                    <div className={`input-box ${editMode ? ' mint' : ''}`} style={{ marginBottom: '5px' }}>
                      <span className="ellipsis">{isNotEmpty(storeFile2) ? storeFile2?.name : storeFile2Name}</span>
                    </div>
                  </a>
                </label>
                <button
                  className={`button ${editMode ? ' hyper-mint' : ''}`}
                  onClick={() => {
                    this.setState({ isOpenImageModal: 'storeFile2' });
                  }}
                  disabled={!editMode}
                >
                  등록
                </button>
              </div>
              <div className="item-input">
                <label className="input-file-button" /*htmlFor="input-file"*/>
                  <a download target="_blank" href={storeFile3Url || null}>
                    <div className={`input-box ${editMode ? ' mint' : ''}`} style={{ marginBottom: '5px' }}>
                      <span className="ellipsis">{isNotEmpty(storeFile3) ? storeFile3?.name : storeFile3Name}</span>
                    </div>
                  </a>
                </label>
                <button
                  className={`button ${editMode ? ' hyper-mint' : ''}`}
                  onClick={() => {
                    this.setState({ isOpenImageModal: 'storeFile3' });
                  }}
                  disabled={!editMode}
                >
                  등록
                </button>
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>매장 한줄소개
              </p>
              <div className="item-input">
                <input
                  name="comment"
                  value={comment || ''}
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  style={{ width: '350px', fontSize: '13px' }}
                  type="text"
                  maxLength={100}
                  placeholder="최대 100자 작성가능"
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>매장 소개글
              </p>
              <div className="item-input">
                <textarea
                  maxLength={300}
                  name="content"
                  value={content || ''}
                  className={`input-box textarea ${editMode ? ' mint' : ''}`}
                  placeholder="최대 300자 작성 가능"
                  style={{ width: '350px', height: '60px', padding: 5, fontSize: '13px' }}
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                ></textarea>
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>메뉴판 이미지 등록
              </p>
              <div className="item-input">
                {/* <input type="file" id="input-file" style={{ display: 'none' }} onChange={this.fileChange} /> */}
                <label className="input-file-button" /*htmlFor="input-file"*/>
                  <a download target="_blank" href={menuFileUrl || null}>
                    <div className={`input-box ${editMode ? ' mint' : ''}`}>
                      {isNotEmpty(menuFile) ? menuFile?.name : menuFileName}
                    </div>
                  </a>
                </label>

                {isNotEmpty(menuFile) || isNotEmpty(menuFileName) ? (
                  <button
                    className={`button ${editMode ? ' hyper-mint' : ''}`}
                    onClick={() => this.handleRemoveImage('menuFile', isNotEmpty(menuFileUrl) ? menuFileUrl : menuFile)}
                    disabled={!editMode}
                  >
                    삭제
                  </button>
                ) : (
                  <button
                    className={`button ${editMode ? ' hyper-mint' : ''}`}
                    onClick={() => {
                      this.setState({ isOpenImageModal: 'menuFile' });
                    }}
                    disabled={!editMode}
                  >
                    등록
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* 이미지 등록 팝업창*/}
        {isOpenImageModal ? (
          <ImageUploadModal
            title="이미지 등록"
            option="image"
            style={{ width: '100%' }}
            name={isOpenImageModal}
            imageUrl={this.state[isOpenImageModal + 'Url']}
            onClose={this.onCloseUploadModal}
            fileChange={this.fileChange}
          />
        ) : null}
        {/* 주소검색 팝업창 */}
        <CustomPopup
          isOpen={isOpenDaumPost}
          closePopup={() => {
            this.setState({ isOpenDaumPost: false });
          }}
          width={'500px'}
          height={'610px'}
        >
          <div className="popup-header flex-start">주소 검색</div>
          <div className="center" style={{ height: '100%', width: '100%', flexDirection: 'column' }}>
            {isOpenDaumPost && (
              <DaumPostCode
                style={{ height: '100%', fontSize: '8px', overflow: 'hidden' }}
                autoClose={false}
                onComplete={this.handleComplete}
              />
            )}
            <input
              name="address"
              value={address || ''}
              className={`input-box ${editMode ? ' mint' : ''}`}
              style={{ width: '100%', marginTop: '20px' }}
              type="text"
              placeholder="주소"
              onChange={this.handleChangeInput}
              disabled
            />
            <input
              name="addressDetail"
              value={addressDetail || ''}
              className={`input-box ${editMode ? ' mint' : ''}`}
              style={{ width: '100%', marginTop: '5px' }}
              type="text"
              placeholder="상세주소"
              onChange={this.handleChangeInput}
            />
          </div>
        </CustomPopup>
        {/* 서비스 팝업창 */}
        <CustomPopup
          isOpen={isOpenStoreService}
          closePopup={() => {
            this.setState({ isOpenStoreService: false });
          }}
          width={'400px'}
          height={'500px'}
        >
          <div className="popup-header flex-start">매장 서비스 선택</div>
          <div className="center" style={{ height: '100%', width: '100%', flexDirection: 'column' }}>
            <FormGroup row>
              {storeService.map((v, index) => {
                const isChecked = placeServiceList.includes(v.id);
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        style={{ color: isChecked ? '#74f5f4' : '#bcbcbc' }}
                        checked={isChecked}
                        onChange={(e) => this.handleCheckService(v.id)}
                      />
                    }
                    label={v.name}
                  />
                );
              })}
            </FormGroup>
          </div>
        </CustomPopup>
        {/* QrCode 팝업창 */}
        <QrCodePopup isOpen={isOpenQrcodePopup} closePopup={this.closeQrcodePopup} qrCodeUrl={qrCodeUrl} />
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    storeCategory: state.category.storeCategory,
    storeService: state.category.storeService
  };
}

export default withRouter(connect(select)(BranchStore));
