import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import components
import ImageUploadModal from './general/ImageUploadModal';
import { openConfirmPopup, closeConfirmPopup } from '../redux/dialog/dialogActions';
import CustomLoading from './custom/CustomLoading';
import CustomErrorMessage from './custom/CustomErrorMessage';

// import utils & icons
import InputMask from 'react-input-mask';
import { ReactComponent as SheetIcon } from '../assets/img/svg/main/sheet.svg';
import update from 'react-addons-update';
import { api } from '../api';
import { isEmpty, isNotEmpty } from '../shared/util';
import { backdropLoadingStart, backdropLoadingStop } from '../redux/global/globalActions';

class BranchBuisness extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    isLoading: PropTypes.bool,
    handleChangeLoading: PropTypes.func,
    editMode: PropTypes.bool
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      loadingError: '',
      storeId: props.match?.params?.storeid || -1,
      businessInfo: {}, // 사업자 등록 정보
      /* 파일 */
      isOpenImageModal: false,
      idCardImage: {},
      idCardImageUrl: '',
      idCardImageName: '',
      accountNumberImage: {},
      accountNumberImageUrl: '',
      accountNumberImageName: '',
      businessNumberImage: {},
      businessNumberImageUrl: '',
      businessNumberImageName: '',
      businessReportNumberImage: {},
      businessReportNumberImageUrl: '',
      businessReportNumberImageName: ''
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    this.loadBuisnessInfo();
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // 사업자 등록 정보 로드
  loadBuisnessInfo = () => {
    const { handleChangeLoading } = this.props;
    const { storeId } = this.state;

    handleChangeLoading(true);
    this.setState({
      isLoading: true,
      loadingError: ''
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };

    api
      .get(`/v1/places/${storeId}/partner-info`)
      .then((res) => {
        this.setState({
          businessInfo: res.data,
          isLoading: false,
          idCardImage: {},
          idCardImageUrl: res.data?.idCardImageSignedUrl,
          idCardImageName: res.data?.idCardImageName,
          accountNumberImage: {},
          accountNumberImageUrl: res.data?.accountNumberImageSignedUrl,
          accountNumberImageName: res.data?.accountNumberImageName,
          businessNumberImage: {},
          businessNumberImageUrl: res.data?.businessNumberImageSignedUrl,
          businessNumberImageName: res.data?.businessNumberImageName,
          businessReportNumberImage: {},
          businessReportNumberImageUrl: res.data?.businessReportNumberImageSignedUrl,
          businessReportNumberImageName: res.data?.businessReportNumberImageName
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          loadingError: 'error'
        });
      })
      .finally(() => {
        handleChangeLoading(false);
      });
  };

  /* 이미지 미리보기
   * File 혹은 Blob 을 읽은 뒤 base64로 인코딩한 문자열을 FileReader 인스턴스의 result 속성에 담아준다.
   */
  encodeFileToBase64 = (name, fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        if (name === 'isOpenImageModal') this.setState({ idCardImageUrl: reader.result });
        else if (name === 'accountNumberImageUrl') this.setState({ storeFile1Url: reader.result });
        else if (name === 'businessNumberImageUrl') this.setState({ storeFile2Url: reader.result });
        else if (name === 'businessReportNumberImageUrl') this.setState({ storeFile3Url: reader.result });
        resolve();
      };
    });
  };

  /* 파일 업로드 */
  fileChange = (name, file) => {
    this.setState({ [name]: file });
    this.encodeFileToBase64(name, file);
  };

  /* 파일 업로드 팝업창 닫기 */
  onCloseUploadModal = () => {
    this.setState({ isOpenImageModal: false });
  };

  // 정보 변경
  handleChangeInput = (e) => {
    this.setState({ businessInfo: update(this.state.businessInfo, { [e.target.name]: { $set: e.target.value } }) });
  };

  // 과세 구분 변경
  handleChangeTaxType = (taxType) => {
    this.setState({ taxType });
  };

  // 사진 삭제 기능
  handleRemoveImage = (target, fileInfo) => {
    const { storeId } = this.state;
    const { dispatch, handleChangeLoading } = this.props;
    // 파일 새로 등록 상태
    if (typeof fileInfo === 'object') {
      // 단순 제거
      this.setState({ [target]: {}, [`${target}Url`]: '' });
    }
    // 이미 등록된 파일상태
    else if (typeof fileInfo === 'string') {
      // 실제 파일 삭제 요청 필요

      const onOk = () => {
        dispatch(closeConfirmPopup());
        handleChangeLoading(true);
        dispatch(backdropLoadingStart());
        const body = {
          target,
          fileInfo
        };
        api.defaults.headers = {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken')
        };

        api
          .post(`/v1/places/file/${storeId}/remove`, body)
          .then((res) => {
            handleChangeLoading(false);
            dispatch(backdropLoadingStop());

            const onOk = () => {
              dispatch(closeConfirmPopup());
              this.loadBuisnessInfo();
            };

            dispatch(
              openConfirmPopup({
                title: 'NOTICE',
                comment: '삭제 완료',
                type: 'success',
                onOk: onOk
              })
            );
          })
          .catch((err) => {
            handleChangeLoading(false);
            dispatch(backdropLoadingStop());
          });
      };

      dispatch(
        openConfirmPopup({
          title: 'NOTICE',
          comment: (
            <>
              <div>파일 삭제 후 되돌릴 수 없습니다.</div>
              <div>정말 삭제하시겠습니까?</div>
            </>
          ),
          type: 'delete',
          onOk: () => {
            onOk();
          }
        })
      );
    }
  };

  /* 저장 */
  handleClickSaveButton = () => {
    const { dispatch, handleChangeLoading } = this.props;
    const {
      storeId,
      businessInfo: {
        accountNumber,
        bankName,
        businessNumber,
        businessReportNumber,
        email,
        id,
        ownerName,
        phone,
        taxType
      },
      idCardImage,
      accountNumberImage,
      businessNumberImage,
      businessReportNumberImage
    } = this.state;

    /* 필수값 체크 */
    let isEmptyValue;
    if (isEmpty(ownerName)) {
      isEmptyValue = '대표자명';
    } else if (isEmpty(phone)) {
      isEmptyValue = '휴대폰';
    } else if (isEmpty(email)) {
      isEmptyValue = '이메일';
    }
    // else if (isEmpty(bankName)) {
    //   isEmptyValue = '계좌 은행';
    // } else if (isEmpty(accountNumber)) {
    //   isEmptyValue = '계좌 번호';
    // } else if (isEmpty(taxType)) {
    //   isEmptyValue = '과세 구분';
    // } else if (isEmpty(businessNumber)) {
    //   isEmptyValue = '사업자등록번호';
    // } else if (isEmpty(businessReportNumber)) {
    //   isEmptyValue = '영업신고증번호';
    // }

    if (isNotEmpty(isEmptyValue)) {
      dispatch(
        openConfirmPopup({
          title: 'NOTICE',
          comment: `필수정보 입력 재확인(${isEmptyValue})`,
          type: 'warning'
        })
      );
    } else {
      handleChangeLoading(true);
      dispatch(backdropLoadingStart());
      const formData = new FormData();
      formData.append('ownerName', ownerName);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('bankName', bankName);
      formData.append('taxType', taxType);
      formData.append('accountNumber', accountNumber);
      formData.append('businessNumber', businessNumber);
      formData.append('businessReportNumber', businessReportNumber);

      if (isNotEmpty(idCardImage)) formData.append('idCardImage', idCardImage);
      if (isNotEmpty(accountNumberImage)) formData.append('accountNumberImage', accountNumberImage);
      if (isNotEmpty(businessNumberImage)) formData.append('businessNumberImage', businessNumberImage);
      if (isNotEmpty(businessReportNumberImage))
        formData.append('businessReportNumberImage', businessReportNumberImage);

      api.defaults.headers = {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
      };
      api
        .put(`/v1/places/${storeId}/partner-info`, formData)
        .then((res) => {
          handleChangeLoading(false);
          dispatch(backdropLoadingStop());

          const onOk = () => {
            dispatch(closeConfirmPopup());
            this.loadBuisnessInfo(); // 정보 로드
          };

          dispatch(
            openConfirmPopup({
              title: 'NOTICE',
              comment: '저장 완료',
              type: 'success',
              onOk: onOk
            })
          );
        })
        .catch((err) => {
          dispatch(backdropLoadingStop());
          handleChangeLoading(false);
        });
    }
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const {
      isLoading,
      loadingError,
      editMode,
      isOpenImageModal,
      idCardImage,
      idCardImageUrl,
      idCardImageName,
      accountNumberImage,
      accountNumberImageUrl,
      accountNumberImageName,
      businessNumberImage,
      businessNumberImageUrl,
      businessNumberImageName,
      businessReportNumberImage,
      businessReportNumberImageUrl,
      businessReportNumberImageName
    } = this.state;
    const { accountNumber, bankName, businessNumber, businessReportNumber, email, id, ownerName, phone, taxType } =
      this.state.businessInfo;

    return isLoading ? (
      <CustomLoading size={'100px'} />
    ) : isNotEmpty(loadingError) ? (
      <CustomErrorMessage />
    ) : (
      <React.Fragment>
        <div className="button-div">
          <button
            className="button button-active"
            onClick={() => {
              this.setState((prev) => ({
                editMode: !prev.editMode
              }));
            }}
          >
            수정
          </button>
          <button
            className={`button ${editMode ? ' button-active' : ' middle-gray'}`}
            onClick={this.handleClickSaveButton}
            disabled={!editMode}
          >
            저장
          </button>
        </div>
        <div className="content-div" style={{ background: 'none' }}>
          <div>
            <p className="title">
              <SheetIcon />
              대표자 개인 정보 등록
            </p>{' '}
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>대표자 성명
              </p>
              <div className="item-input">
                <input
                  name="ownerName"
                  value={ownerName || ''}
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  type="text"
                  placeholder="박수리"
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                />
              </div>
            </div>{' '}
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>휴대폰
              </p>
              <div className="item-input">
                <input
                  name="phone"
                  value={phone || ''}
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  type="text"
                  placeholder="010-1234-5678"
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                />
              </div>
            </div>{' '}
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>E-mail
              </p>
              <div className="item-input">
                <input
                  name="email"
                  value={email || ''}
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  type="text"
                  placeholder="suri@hyper-cloud.kr"
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>신분증 사본 등록
              </p>
              <div className="item-input">
                <label className="input-file-button" /*htmlFor="input-file"*/>
                  <a download target="_blank" href={idCardImageUrl || null}>
                    <div className={`input-box ${editMode ? ' mint' : ''}`}>
                      {isNotEmpty(idCardImage) ? idCardImage?.name : idCardImageName}
                    </div>
                  </a>
                </label>
                {isNotEmpty(idCardImage) || isNotEmpty(idCardImageName) ? (
                  <button
                    className={`button ${editMode ? ' hyper-mint' : ''}`}
                    onClick={() =>
                      this.handleRemoveImage('idCardImage', isNotEmpty(idCardImageUrl) ? idCardImageUrl : idCardImage)
                    }
                    disabled={!editMode}
                  >
                    삭제
                  </button>
                ) : (
                  <button
                    className={`button ${editMode ? ' hyper-mint' : ''}`}
                    onClick={() => {
                      this.setState({ isOpenImageModal: 'idCardImage' });
                    }}
                    disabled={!editMode}
                  >
                    등록
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="content-div"
          style={{ background: 'none', borderLeft: '1px solid #545454', borderRight: '1px solid #545454' }}
        >
          <div>
            <p className="title">
              <SheetIcon />
              정산 관련 제반 정보 등록
            </p>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>계좌 은행
              </p>
              <div className="item-input">
                <input
                  name="bankName"
                  value={bankName || ''}
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  type="text"
                  placeholder="국민은행"
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>계좌 번호
              </p>
              <div className="item-input">
                <input
                  name="accountNumber"
                  value={accountNumber || ''}
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  type="text"
                  placeholder="12-345-123125"
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>통장 사본 등록
              </p>
              <div className="item-input">
                <label className="input-file-button" /*htmlFor="input-file"*/>
                  <a download target="_blank" href={accountNumberImageUrl || null}>
                    <div className={`input-box ${editMode ? ' mint' : ''}`}>
                      {isNotEmpty(accountNumberImage) ? accountNumberImage?.name : accountNumberImageName}
                    </div>
                  </a>
                </label>

                {isNotEmpty(accountNumberImage) || isNotEmpty(accountNumberImageName) ? (
                  <button
                    className={`button ${editMode ? ' hyper-mint' : ''}`}
                    onClick={() =>
                      this.handleRemoveImage(
                        'accountNumberImage',
                        isNotEmpty(accountNumberImageUrl) ? accountNumberImageUrl : accountNumberImage
                      )
                    }
                    disabled={!editMode}
                  >
                    삭제
                  </button>
                ) : (
                  <button
                    className={`button ${editMode ? ' hyper-mint' : ''}`}
                    onClick={() => {
                      this.setState({ isOpenImageModal: 'accountNumberImage' });
                    }}
                    disabled={!editMode}
                  >
                    등록
                  </button>
                )}
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>과세 구분
              </p>
              <div className="item-input">
                <label className={`${editMode ? ' radio-mint' : ''}`}>
                  <input
                    type="radio"
                    checked={taxType === '일반'}
                    name="taxType"
                    onChange={this.handleChangeInput}
                    disabled={!editMode}
                    value={'일반'}
                    style={{ backgroundColor: 'red' }}
                  />
                  <span>일반</span>
                </label>
                <label className={`${editMode ? ' radio-mint' : ''}`}>
                  <input
                    style={{ background: '#74f5f4' }}
                    type="radio"
                    checked={taxType === '간이'}
                    name="taxType"
                    onChange={this.handleChangeInput}
                    disabled={!editMode}
                    value={'간이'}
                  />
                  <span>간이</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="content-div" style={{ background: 'none' }}>
          <div>
            <p className="title">
              <SheetIcon />
              사업자 제반 정보 등록
            </p>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>사업자 등록 번호
              </p>
              <div className="item-input">
                <InputMask
                  mask="999-99-99999"
                  name="businessNumber"
                  value={businessNumber || ''}
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  type="text"
                  placeholder="123-456-78912"
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                />
                {/* <input
                  name="businessNumber"
                  value={businessNumber || ''}
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  type="text"
                  placeholder="123-142-43534"
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                /> */}
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>사업자 등록 사본 등록
              </p>
              <div className="item-input">
                <label className="input-file-button" /*htmlFor="input-file"*/>
                  <a download target="_blank" href={businessNumberImageUrl || null}>
                    <div className={`input-box ${editMode ? ' mint' : ''}`}>
                      {isNotEmpty(businessNumberImage) ? businessNumberImage?.name : businessNumberImageName}
                    </div>
                  </a>
                </label>

                {isNotEmpty(businessNumberImage) || isNotEmpty(businessNumberImageName) ? (
                  <button
                    className={`button ${editMode ? ' hyper-mint' : ''}`}
                    onClick={() =>
                      this.handleRemoveImage(
                        'businessNumberImage',
                        isNotEmpty(businessNumberImageUrl) ? businessNumberImageUrl : businessNumberImage
                      )
                    }
                    disabled={!editMode}
                  >
                    삭제
                  </button>
                ) : (
                  <button
                    className={`button ${editMode ? ' hyper-mint' : ''}`}
                    onClick={() => {
                      this.setState({ isOpenImageModal: 'businessNumberImage' });
                    }}
                    disabled={!editMode}
                  >
                    등록
                  </button>
                )}
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>영업 신고증 고유번호
              </p>
              <div className="item-input">
                <input
                  name="businessReportNumber"
                  value={businessReportNumber || ''}
                  className={`input-box ${editMode ? ' mint' : ''}`}
                  type="text"
                  placeholder="123-142-43534"
                  onChange={this.handleChangeInput}
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="item">
              <p>
                <span>&#183;&nbsp;</span>영업 신고증 사본 등록
              </p>
              <div className="item-input">
                <label className="input-file-button" /*htmlFor="input-file"*/>
                  <a download target="_blank" href={businessReportNumberImageUrl || null}>
                    <div className={`input-box ${editMode ? ' mint' : ''}`}>
                      {isNotEmpty(businessReportNumberImage)
                        ? businessReportNumberImage?.name
                        : businessReportNumberImageName}
                    </div>
                  </a>
                </label>

                {isNotEmpty(businessReportNumberImage) || isNotEmpty(businessReportNumberImageName) ? (
                  <button
                    className={`button ${editMode ? ' hyper-mint' : ''}`}
                    onClick={() =>
                      this.handleRemoveImage(
                        'businessReportNumberImage',
                        isNotEmpty(businessReportNumberImageUrl)
                          ? businessReportNumberImageUrl
                          : businessReportNumberImage
                      )
                    }
                    disabled={!editMode}
                  >
                    삭제
                  </button>
                ) : (
                  <button
                    className={`button ${editMode ? ' hyper-mint' : ''}`}
                    onClick={() => {
                      this.setState({ isOpenImageModal: 'businessReportNumberImage' });
                    }}
                    disabled={!editMode}
                  >
                    등록
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* 이미지 등록 팝업창*/}
        {isOpenImageModal ? (
          <ImageUploadModal
            title="이미지 등록"
            option="image"
            style={{ width: '100%' }}
            name={isOpenImageModal}
            imageUrl={this.state[isOpenImageModal + 'Url']}
            onClose={this.onCloseUploadModal}
            fileChange={this.fileChange}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth
  };
}

export default withRouter(connect(select)(BranchBuisness));
