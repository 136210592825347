import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadProjectVersion } from '../../redux/version/versionActions';
import { changeStoreValue } from '../../redux/root/actions';
import { showDialog } from '../../redux/dialog/dialogActions';
import SideMenuBar from '../../components/SideBar';
import Header from '../../components/Header';

import '../../assets/scss/template/temp-layout2.scss';

class TempLayout2 extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {};
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    const { version } = this.props;

    return (
      <React.Fragment>
        <div className="root-bg">
          <Header />
        </div>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth
  };
}

export default withRouter(connect(select)(TempLayout2));
