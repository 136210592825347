import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAdminInfo } from '../redux/auth/authActions';

// import actions
import { openConfirmPopup } from '../redux/dialog/dialogActions';
import { changeIsFirst } from '../redux/auth/authActions';

// import component
import SettingDropDown from './SettingDropDown';
import PasswordPopup from './general/PasswordPopup';

// import icon & utils
import { ReactComponent as NotificationIcon } from '../assets/img/svg/header/notificaton_icon.svg';
import { ReactComponent as SettingIcon } from '../assets/img/svg/header/setting_icon.svg';
import { ReactComponent as ProfileSample } from '../assets/img/svg/header/profile_sample.svg';

class Header extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.settingModalEl = React.createRef();

    this.state = {
      isOpenSettingMenu: false,
      isOpenChangePassword: props.auth?.manager?.isFirst
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { dispatch, auth } = this.props;
    if (!auth.isLoggedIn) {
      dispatch(getAdminInfo());
    }

    // 팝업 이외 영역 조작
    window.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // 드롭다운 이외 영역 클릭시 닫기
  handleClickOutside = ({ target }) => {
    // 설정 메뉴
    if (this.state.isOpenSettingMenu && !this.settingModalEl.current?.contains(target)) {
      this.setState({
        isOpenSettingMenu: false
      });
    }
    // On/Off 메뉴
    if (this.state.isOpenOnOffMenu && !this.onOffModalEl.current?.contains(target)) {
      this.setState({
        isOpenOnOffMenu: false
      });
    }
  };

  // 비밀번호 변경
  toggleChangePassword = () => {
    this.setState((prev) => ({
      isOpenChangePassword: !prev.isOpenChangePassword,
      isOpenSettingMenu: false
    }));
  };

  // 설정 메뉴 열기
  toggleSettingMenu = () => {
    this.setState((prev) => ({
      isOpenSettingMenu: !prev.isOpenSettingMenu
    }));
  };

  // 드롭다운 메뉴 닫기
  closeSettingDropdown = () => {
    this.setState({
      isOpenSettingMenu: false
    });
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const { match, auth, dispatch } = this.props;
    const url = match.url;
    const HeaderTitle = url.indexOf('store') !== -1 ? 'STORE PAGE' : 'ADMINPAGE';
    return (
      <React.Fragment>
        {/* 비밀번호 변경 팝업창 */}
        <PasswordPopup
          isOpen={this.state.isOpenChangePassword}
          closePopup={this.toggleChangePassword}
          onOk={() => {
            dispatch(
              openConfirmPopup({
                title: 'NOTICE',
                comment: '패스워드 재설정 완료',
                type: 'success'
              })
            );
            dispatch(changeIsFirst());
          }}
        />
        <div className="header vertical">
          <div className="header-title" style={{ paddingLeft: '25px' }}>
            {HeaderTitle}
          </div>
          <div className="header-right">
            {/* <NotificationIcon className="icon" /> */}
            <div style={{ position: 'relative' }} ref={this.settingModalEl}>
              <div onClick={() => this.toggleSettingMenu()} className="icon center">
                {/* <SettingIcon className="icon" /> */}
                <SettingIcon className="pointer" />
              </div>
              {/* 드롭다운 메뉴 */}
              {this.state.isOpenSettingMenu && (
                <SettingDropDown
                  toggleChangePassword={this.toggleChangePassword}
                  closeSettingDropdown={this.closeSettingDropdown}
                />
              )}
            </div>
            <ProfileSample className="icon" />
            <span className="user-name">{auth.manager?.name}</span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth
  };
}

export default withRouter(connect(select)(Header));
