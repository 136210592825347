import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import actions
import { openConfirmPopup, closeConfirmPopup } from '../../redux/dialog/dialogActions';

// import components
import CustomPagination from '../../components/custom/CustomPagination';
import TemplateDefault from '../template/TemplateDefault';
import CalenderRange from '../../components/general/CalenderRange';
import { ToggleSwitch } from '../../components/mui/LBToggle';
import TextAreaModal from '../../components/general/TextareaModal';
import StorePromotionCreateModal from '../../components/StorePromotionCreateModal';
import CustomLoading from '../../components/custom/CustomLoading';

// import utils & icons
import update from 'react-addons-update';
import moment from 'moment';
import { api } from '../../api';
import { isNotEmpty, isEmpty } from '../../shared/util';
import { numberWithCommas } from '../../shared/formatter';
import { addDays } from '../../shared/dateTimeUtil';
import { getPromotionCouponStatus } from '../../shared/dataUtil';
import { ReactComponent as AddButton } from '../../assets/img/svg/main/add_btn.svg';
import { ReactComponent as RemoveButton } from '../../assets/img/svg/main/remove_btn.svg';
import { ReactComponent as DetailImage } from '../../assets/img/svg/store/menu/detail.svg';
import { ReactComponent as MemoIcon } from '../../assets/img/svg/main/memo.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/svg/main/delete.svg';

class Promotion extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.settingModalEl = React.createRef();

    this.state = {
      storeId: props.match?.params?.storeid || -1, // 가맹점 ID
      limit: 10, // 페이징
      page: 1, // 페이징
      count: 0, // 전체 개수
      promotionList: [], // 프로모션 리스트
      promotionCheckedList: [], // 체크박스
      isLoading: false, // 조회중
      errorMsg: '', // 에러 메세지
      startDate: '', // 시작 날짜
      endDate: '', // 종료 날찌
      isLoadingDelete: false, // 삭제 진행중
      toggle: -1, // 프로모션 드롭다운 메뉴
      isChangingToggle: false, // 프로모션 상태 변경중
      isOpenCommentModal: false, // 프로모션 메모 팝업창
      promotionComment: {}, // 프로모션 메모(메모 팝업창 저장)
      promotionModal: false // 프로모션 발행 팝업창
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { isLoading } = this.state;
    if (!isLoading) this.loadPromotions();

    // 옵션팝업 이외 영역 조작
    window.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, isLoading, page } = this.state;

    // 날짜 변경
    if (isNotEmpty(startDate) && isNotEmpty(endDate)) {
      if (prevState.startDate !== startDate || prevState.endDate !== endDate) {
        if (!isLoading) this.loadPromotions();
      }
    }

    // 페이지 변경
    if (prevState.page !== page) {
      this.loadPromotions();
    }
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // 이외 영역 클릭시 닫기
  handleClickOutside = ({ target }) => {
    // 설정 메뉴
    if (this.state.toggle !== -1 && !this.settingModalEl.current?.contains(target)) {
      this.setState({
        toggle: -1
      });
    }
  };

  // 프로모션 리스트 로드
  loadPromotions = () => {
    const { startDate, endDate, limit, page, storeId } = this.state;
    const isDate = isNotEmpty(startDate) && isNotEmpty(endDate);

    this.setState({
      isLoading: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .get(
        `/v1/places/${storeId}/promotions?limit=${limit}&page=${page}${
          isDate ? `&startDate=${startDate}&endDate=${endDate}` : ''
        }`
      )
      .then((res) => {
        const { promotions, count, pCount, sCount, fCount, rCount } = res.data;
        this.setState({
          promotionList: promotions,
          count: count,
          pCount: pCount,
          sCount: sCount,
          fCount: fCount,
          rCount: rCount,
          isLoading: false
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
      });
  };

  // 프로모션 발행
  createPromotion = (promotion) => {
    const { dispatch } = this.props;
    const { promotionList, isLoading } = this.state;
    if (isLoading) return;
    const { storeId, ...body } = promotion;
    // body = {name, startDate, endDate, discountType, discountAmount, productId}

    this.setState({
      isLoading: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .post(`/v1/places/${storeId}/promotions`, body)
      .then((res) => {
        const newList = [...promotionList];
        newList.unshift(res.data);
        if (newList.length > 10) newList.pop();

        this.setState({
          promotionList: newList,
          promotionModal: false,
          isLoading: false
        });

        dispatch(
          openConfirmPopup({
            title: 'NOTICE',
            comment: '등록 완료',
            type: 'success'
          })
        );
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
      });
  };

  // 이전 페이지
  handleClickBack = () => {
    this.setState((prev) => ({
      page: prev.page - 1
    }));
  };
  // 다음 페이지
  handleClickForward = () => {
    this.setState((prev) => ({
      page: prev.page + 1
    }));
  };

  // 모든 체크 선택
  // 전체 선택 여부에 따라 on/off
  handleClickAllCheckBox = () => {
    const { promotionCheckedList, promotionList } = this.state;
    const isAllChecked =
      isNotEmpty(promotionList) && promotionList?.length === promotionCheckedList?.length ? true : false;

    // 전체 선택 상태
    if (isAllChecked) {
      this.setState({ promotionCheckedList: [] });
    }
    // 전체 선택이 아닌 경우
    else {
      const allId = promotionList.map((v) => v.id);
      this.setState({ promotionCheckedList: allId });
    }
  };

  /* 프로모션 체크 박스 선택 */
  handleClickCheckBox = (id) => {
    const { promotionCheckedList } = this.state;
    const isChecked = promotionCheckedList.includes(id);

    // 체크
    if (!isChecked) {
      const newList = [...promotionCheckedList, id];
      this.setState({
        promotionCheckedList: newList
      });
    }
    // 체크 해제
    else {
      const newList = promotionCheckedList?.filter((v) => v !== id);
      this.setState({
        promotionCheckedList: newList
      });
    }
  };

  // 선택된 프로모션 삭제
  deletePromotion = (promotionIdList) => {
    if (isEmpty(promotionIdList)) {
      return alert('삭제 할 프로모션을 선택해주세요.');
    }

    const { dispatch } = this.props;
    const { storeId, promotionList, isLoadingDelete, promotionCheckedList } = this.state;
    if (isLoadingDelete) return;

    // 전체 삭제 여부
    const isAllChecked =
      isNotEmpty(promotionList) && promotionList?.length === promotionCheckedList?.length ? true : false;

    this.setState({
      isLoadingDelete: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .post(`/v1/places/${storeId}/promotions/remove`, { promotionIdList: promotionIdList })
      .then((res) => {
        const newList = promotionList.filter((v) => !promotionIdList.includes(v.id));

        this.setState({
          promotionList: newList,
          promotionCheckedList: [],
          isLoadingDelete: false
        });

        // 전체 삭제 일 경우, 새로고침
        if (isAllChecked) this.loadPromotions();
      })
      .catch((err) => {
        this.setState({ isLoadingDelete: false });
      })
      .finally(() => {
        dispatch(closeConfirmPopup());
      });
  };

  /* 프로모션 발행 토글 */
  handleToggle = (promotionId) => {
    const { promotionList, storeId } = this.state;
    const findIndex = promotionList.findIndex((v) => v.id === promotionId);
    if (findIndex === -1) return alert('알수없는 오류 발생');
    const beforeToggle = promotionList?.[findIndex]?.isPublish;
    const beforeStatus = promotionList?.[findIndex]?.status;

    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    this.setState({ isChangingToggle: true });

    api
      .patch(`/v1/places/${storeId}/promotions/${promotionId}/toggle-publish`, { isPublish: !beforeToggle })
      .then((res) => {
        this.setState((prev) => {
          const count = {
            pCount: prev.pCount,
            sCount: prev.sCount,
            fCount: prev.fCount,
            rCount: prev.rCount
          };
          if (res.data.status === 'P') count.pCount = count.pCount + 1;
          else if (res.data.status === 'S') count.sCount = count.sCount + 1;
          else if (res.data.status === 'F') count.fCount = count.fCount + 1;
          else if (res.data.status === 'R') count.rCount = count.rCount + 1;

          if (beforeStatus === 'P') count.pCount = count.pCount - 1;
          else if (beforeStatus === 'S') count.sCount = count.sCount - 1;
          else if (beforeStatus === 'F') count.fCount = count.fCount - 1;
          else if (beforeStatus === 'R') count.rCount = count.rCount - 1;

          return {
            isChangingToggle: false,
            promotionList: update(promotionList, {
              [findIndex]: { isPublish: { $set: !beforeToggle }, status: { $set: res.data.status } }
            }),
            ...count
          };
        });
      })
      .catch((err) => {
        this.setState({ isChangingToggle: false, errorMsg: '예기치 못한 에러가 발생되었습니다.' });
      })
      .finally(() => {});
  };

  /* 프로모션 메모 수정 */
  updateComment = (comment) => {
    const { dispatch } = this.props;
    const { storeId } = this.state;
    const {
      promotionComment: { id },
      promotionList
    } = this.state;
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };

    api
      .patch(`/v1/places/${storeId}/promotions/${id}/comment`, { comment })
      .then((res) => {
        const { comment } = res.data;
        const findIndex = promotionList.findIndex((v) => v.id === id);
        this.setState({
          isOpenCommentModal: false,
          promotionList: update(promotionList, { [findIndex]: { comment: { $set: comment } } })
        });

        dispatch(
          openConfirmPopup({
            title: 'NOTICE',
            comment: '수정 완료',
            type: 'success'
          })
        );
      })
      .catch((err) => {
        this.setState({ isOpenCommentModal: false });
      });
  };

  // -- render --------------------------------------------------------------------------------

  renderHeader = (widthSize) => {
    const { promotionList, promotionCheckedList } = this.state;
    const titles = ['상품 번호', '프로모션 기간', '적용 대상', '프로모션명', '참여수', '발행 상태', '발행 ON'];
    const isAllChecked =
      isNotEmpty(promotionList) && promotionList?.length === promotionCheckedList?.length ? true : false;
    return (
      <div className="row th" style={{ maxWidth: '1346px' }}>
        <p style={{ width: '50px', minWidth: '50px' }}>
          <input type="checkbox" checked={isAllChecked} onChange={this.handleClickAllCheckBox} className="checkbox" />
        </p>
        <p style={{ width: widthSize[0] }}>{titles[0]}</p>
        <p style={{ width: widthSize[1] }}>{titles[1]}</p>
        <p style={{ width: widthSize[2] }}>{titles[2]}</p>
        <p style={{ width: widthSize[3] }}>{titles[3]}</p>
        <p style={{ width: widthSize[4] }}>{titles[4]}</p>
        <p style={{ width: widthSize[5] }}>{titles[5]}</p>
        <p style={{ width: widthSize[6] }}>{titles[6]}</p>
        <p style={{ width: widthSize[7] }}></p>
      </div>
    );
  };

  renderRow = (data, index, widthSize) => {
    const { id, isPublish, name, orderProductPromotionCount, products, startDate, endDate, status, comment } = data;
    const { promotionCheckedList, isChangingToggle } = this.state;

    const startAt = moment(startDate).format('YYYY-MM-DD');
    const endAt = moment(endDate).format('YYYY-MM-DD');
    const promotionDate = `${startAt} ~ ${endAt}`;
    const target = products?.length ? '특정메뉴' : '전메뉴';
    const isChecked = promotionCheckedList.includes(id); //체크 여부

    return (
      <div className="row" key={index} style={{ position: 'relative', maxWidth: '1346px' }} disabled>
        <p style={{ width: '50px', minWidth: '50px' }}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => this.handleClickCheckBox(id)}
            className="checkbox"
          />
        </p>
        <p style={{ width: widthSize[0] }}>{id}</p>
        <p style={{ width: widthSize[1] }}>{promotionDate}</p>
        <p style={{ width: widthSize[2] }}>{target}</p>
        <p style={{ width: widthSize[3] }}>{name}</p>
        <p style={{ width: widthSize[4] }}>{numberWithCommas(orderProductPromotionCount)}</p>
        <p style={{ width: widthSize[5] }}>
          <span className={'issue'} style={{ backgroundColor: getPromotionCouponStatus(status).color }}>
            {getPromotionCouponStatus(status).title}
          </span>
        </p>
        <p style={{ width: widthSize[6] }}>
          <ToggleSwitch
            disabled={isChangingToggle}
            checked={isPublish}
            onClick={() => this.handleToggle(id)}
            name="checkedB"
          />
        </p>
        <div
          className="center"
          style={{ width: widthSize[7], overflow: 'hidden' }}
          ref={this.state.toggle == id && this.settingModalEl}
        >
          <DetailImage
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (this.state.toggle === id) this.setState({ toggle: -1 });
              else this.setState({ toggle: id });
            }}
          />
          {this.state.toggle == id ? (
            <div className="editMenu" style={{ height: '84px', top: '35px' }}>
              <p
                onClick={() => {
                  this.setState({ isOpenCommentModal: true, toggle: -1, promotionComment: { id, comment } });
                }}
              >
                <MemoIcon /> <span>메모</span>
              </p>
              <p
                onClick={() => {
                  this.props.dispatch(
                    openConfirmPopup({
                      title: 'NOTICE',
                      comment: '삭제 진행 재확인',
                      type: 'delete',
                      onOk: () => this.deletePromotion([id])
                    })
                  );
                }}
              >
                <DeleteIcon /> <span>삭제</span>
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  merchantContents = () => {
    const { dispatch } = this.props;
    const {
      page,
      limit,
      count,
      isLoading,
      promotionList,
      errorMsg,
      startDate,
      endDate,
      pCount,
      sCount,
      fCount,
      rCount,
      promotionCheckedList
    } = this.state;
    const widthSize = ['14%', '16%', '12%', '22%', '16%', '10%', '10%', '7%'];

    // pCount - 발행중 (publish)
    // sCount - 중단 (stop)
    // fCount - 종료 (finish)
    // rCount - 예약 (reservation)

    return (
      <div style={{ width: 'fit-content' }}>
        <div className="top-button-container">
          <p className={'hyper-mint'} onClick={() => this.setState({ promotionModal: true })}>
            <AddButton />
            <span>{'프로모션 발행'}</span>
          </p>
          <p
            className={'red white-font'}
            onClick={() => {
              if (isEmpty(promotionCheckedList)) {
                return alert('삭제 할 프로모션을 선택해주세요.');
              } else {
                dispatch(
                  openConfirmPopup({
                    title: 'NOTICE',
                    comment: '삭제 진행 재확인',
                    type: 'delete',
                    onOk: () => this.deletePromotion(promotionCheckedList)
                  })
                );
              }
            }}
          >
            <RemoveButton />
            <span>{'프로모션 삭제'}</span>
          </p>
          <CalenderRange
            disabled={isLoading}
            startDate={isNotEmpty(startDate) ? moment(startDate).format('YYYY-MM-DD') : ''}
            endDate={isNotEmpty(endDate) ? moment(endDate).format('YYYY-MM-DD') : ''}
            queryParam={(res) => {
              const { startDate, endDate } = res;
              this.setState({
                startDate,
                endDate: isNotEmpty(endDate) ? addDays(endDate, 1) : ''
              });
            }}
          />
        </div>
        <div className="container">
          <div className="table" style={{ width: '1600px' }}>
            {this.renderHeader(widthSize)}
            <div className="rows">
              {isLoading ? (
                <CustomLoading />
              ) : (
                promotionList?.map((data, index) => this.renderRow(data, index, widthSize))
              )}
            </div>
          </div>
          <div className="bottom-container">
            <p>
              <span>{`총 프로모션 개수 : ${numberWithCommas(count)} 개`}</span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <span className="green-font">{`발행중 : ${numberWithCommas(pCount)} 개`}</span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <span className="red-font">{`중단 : ${numberWithCommas(sCount)} 개`}</span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <span className="gray-font">{`종료 : ${numberWithCommas(fCount)} 개`}</span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <span className="yellow-font">{`예약 : ${numberWithCommas(rCount)} 개`}</span>&nbsp;&nbsp;
            </p>
            {/* 하단 */}
            <CustomPagination
              totalCount={count}
              page={page}
              limit={limit}
              onClickBack={this.handleClickBack}
              onClickForward={this.handleClickForward}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    );
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const { promotionComment, storeId } = this.state;
    return (
      <React.Fragment>
        <TemplateDefault sidebar="store">
          <span className="title">프로모션 관리</span>
          {this.merchantContents()}
        </TemplateDefault>
        {this.state.isOpenCommentModal ? (
          <TextAreaModal
            handleClickButton={this.updateComment}
            title="주요사항 메모"
            content={promotionComment.comment}
            onClose={() => this.setState({ isOpenCommentModal: false, promotionComment: {} })}
          />
        ) : null}
        {this.state.promotionModal ? (
          <StorePromotionCreateModal
            title="프로모션 상품 발행"
            content=""
            storeId={storeId}
            onClose={() => {
              this.setState({ promotionModal: false });
            }}
            onClickButton={this.createPromotion}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(Promotion));
