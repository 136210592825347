import { COMMON_DIALOG_STATE_CHANGED, COMMON_CONFIRM_POPUP_OPEN, COMMON_CONFIRM_POPUP_CLOSE } from '../actionTypes';
import produce from 'immer';

// TODO: stack 구조로 바꾸는 걸 고려하자. 지금 구조로는 한 번에 하나의 common dialog만 띄울 수 있다.

const initialState = {
  show: false,
  title: null,
  content: null,

  buttonCount: 1,
  onOk: null,
  onCancel: null,
  okButtonText: '확인',
  cancelButtonText: '취소',

  useClickAway: true,
  modalStyle: null, // success / info / danger / warning

  // confirm popup
  isOpenConfirmPopup: false,
  confirmPopupTitle: 'NOTICE',
  confirmPopupComment: '완료',
  confirmPopupType: 'success',
  confirmPopupOnOk: null
};

export const dialogReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case COMMON_DIALOG_STATE_CHANGED: {
        draft.show = action.show;
        draft.title = action.title;
        draft.content = action.content;
        draft.buttonCount = action.buttonCount;
        draft.onOk = action.onOk;
        draft.onCancel = action.onCancel;
        draft.okButtonText = action.okButtonText;
        draft.cancelButtonText = action.cancelButtonText;
        draft.useClickAway = action.useClickAway;
        draft.modalStyle = action.modalStyle;
        break;
      }
      case COMMON_CONFIRM_POPUP_OPEN: {
        draft.isOpenConfirmPopup = true;
        draft.confirmPopupTitle = action.title;
        draft.confirmPopupComment = action.comment;
        draft.confirmPopupType = action.popupType;
        draft.confirmPopupOnOk = action.onOk;
        break;
      }
      case COMMON_CONFIRM_POPUP_CLOSE: {
        draft.isOpenConfirmPopup = false;
        draft.confirmPopupTitle = 'NOTICE';
        draft.confirmPopupComment = '완료';
        draft.confirmPopupType = 'success';
        draft.confirmPopupOnOk = null;
        break;
      }

      default: {
        break;
      }
    }
  });
};
