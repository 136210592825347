import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { closeDialog } from '../redux/dialog/dialogActions';
import { nl2br } from '../shared/util';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

class CommonDialog extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,

    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    content: PropTypes.any,

    buttonCount: PropTypes.number,
    onOk: PropTypes.func, // 명시적으로 확인 버튼을 눌렀을 때
    onCancel: PropTypes.func, // 명시적으로 취소 버튼을 눌렀을 때

    okButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,

    useClickAway: PropTypes.bool,
    modalStyle: PropTypes.string
  };

  static defaultProps = {
    buttons: 1
  };

  handleOk = (e) => {
    e?.preventDefault();
    this.props.dispatch(closeDialog());

    const { onOk } = this.props;
    if (onOk) {
      onOk();
    }
  };

  handleCancel = (e) => {
    e?.preventDefault();
    this.props.dispatch(closeDialog());
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
  };

  render() {
    const { show, title, content } = this.props;
    const { buttonCount, okButtonText, cancelButtonText, useClickAway, modalStyle } = this.props;

    const contentType = typeof content;
    let dispContent;
    if (contentType === 'string') {
      dispContent = nl2br(content);
    } else {
      dispContent = content;
    }

    return (
      <Dialog open={show} fullWidth={true}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>{dispContent}</DialogContent>
        <DialogActions>
          {buttonCount > 1 && (
            <Button onClick={this.handleCancel} color="primary">
              {cancelButtonText}
            </Button>
          )}
          <Button onClick={this.handleOk} color="primary" autoFocus>
            {okButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function select(state) {
  const commonDialog = state.commonDialog;
  const selected = {
    show: commonDialog.show,
    title: commonDialog.title,
    content: commonDialog.content,

    buttonCount: commonDialog.buttonCount,
    onOk: commonDialog.onOk,
    onCancel: commonDialog.onCancel,
    okButtonText: commonDialog.okButtonText,
    cancelButtonText: commonDialog.cancelButtonText,

    useClickAway: commonDialog.useClickAway,
    modalStyle: commonDialog.modalStyle
  };
  return selected;
}

export default connect(select)(CommonDialog);
