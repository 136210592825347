import { api } from '../../api';
import { BACKDROP_LOADING_START, BACKDROP_LOADING_STOP } from '../actionTypes';

/**
 * backdrop 로딩 시작
 */
export const backdropLoadingStart = () => {
  return (dispatch) => {
    dispatch({ type: BACKDROP_LOADING_START });
  };
};

/**
 * backdrop 로딩 중지
 */
export const backdropLoadingStop = () => {
  return (dispatch) => {
    dispatch({ type: BACKDROP_LOADING_STOP });
  };
};
