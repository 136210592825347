import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import actions
import { openConfirmPopup } from '../../redux/dialog/dialogActions';

// import components
import CustomPagination from '../../components/custom/CustomPagination';
import TemplateDefault from '../template/TemplateDefault';
import CalenderRange from '../../components/general/CalenderRange';

// import utils & icons
import moment from 'moment';
import { api } from '../../api';
import { isNotEmpty } from '../../shared/util';
import { numberWithCommas } from '../../shared/formatter';
import { addDays } from '../../shared/dateTimeUtil';
import { ReactComponent as ToggleIcon } from '../../assets/img/svg/store/order/toggle.svg';
import { ReactComponent as EmptyImage } from '../../assets/img/svg/store/menu/empty.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/svg/main/delete.svg';
import { ReactComponent as Star } from '../../assets/img/svg/store/review/star.svg';
import { ReactComponent as HalfStar } from '../../assets/img/svg/store/review/half_star.svg';
import { ReactComponent as EmptyStar } from '../../assets/img/svg/store/review/empty_star.svg';

class Review extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      storeId: props.match?.params?.storeid || -1,
      limit: 10,
      page: 1,
      count: 0,
      reviewList: [],
      isLoading: false,
      errorMsg: '',
      startDate: '',
      endDate: '',

      toggleTest: true
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { isLoading } = this.state;
    if (!isLoading) this.loadReviews();
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, isLoading, page } = this.state;

    // 날짜 변경
    if (isNotEmpty(startDate) && isNotEmpty(endDate)) {
      if (prevState.startDate !== startDate || prevState.endDate !== endDate) {
        if (!isLoading) this.loadReviews();
      }
    }

    // 페이지 변경
    if (prevState.page !== page) {
      this.loadReviews();
    }
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // 리뷰 리스트 로드
  loadReviews = () => {
    const { storeId, startDate, endDate, limit, page } = this.state;

    const isDate = isNotEmpty(startDate) && isNotEmpty(endDate);

    this.setState({
      isLoading: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .get(
        `/v1/places/${storeId}/orders?limit=${limit}&page=${page}${
          isDate ? `&startDate=${startDate}&endDate=${endDate}` : ''
        }`
      )
      .then((res) => {
        const { orders, count } = res.data;
        this.setState({
          couponList: orders,
          count: count,
          isLoading: false
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
      });
  };

  // 이전 페이지
  handleClickBack = () => {
    this.setState((prev) => ({
      page: prev.page - 1
    }));
  };
  // 다음 페이지
  handleClickForward = () => {
    this.setState((prev) => ({
      page: prev.page + 1
    }));
  };
  // -- render --------------------------------------------------------------------------------

  renderHeader = (widthSize) => {
    const titles = ['등록 일자', '등록 번호', '사용자 (ID)', '평점', '']; //['등록일자', '오더종류', '사용자 (ID)', '주문 번호', '주문 메뉴', '결제 금액', '주문 처리'];

    return (
      <div className="row th">
        <p style={{ width: widthSize[0] }}></p>
        <p style={{ width: widthSize[1] }}>{titles[0]}</p>
        <p style={{ width: widthSize[2] }}>{titles[1]}</p>
        <p style={{ width: widthSize[3] }}>{titles[2]}</p>
        <p style={{ width: widthSize[4] }}>{titles[3]}</p>
        <p style={{ width: widthSize[5] }}>{titles[4]}</p>
        {/* <p style={{ width: '11%' }}>{titles[5]}</p>
        <p style={{ width: '16%' }}>{titles[6]}</p> */}
      </div>
    );
  };

  renderMenu = (data, index) => {
    const { name, count } = data;
    return (
      <React.Fragment key={index}>
        <span>{`${name} ${count}`}</span>
        <br />
      </React.Fragment>
    );
  };

  gradeStarPrint = (grade) => {
    const star = Math.floor(grade);
    let halfStar = grade % 1;
    const emptyStar = Math.floor(5 - grade);

    let stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= star) {
        stars.push(1);
      } else if (halfStar !== 0) {
        stars.push(0.5);
        halfStar = 0;
      } else {
        stars.push(0);
      }
    }
    return stars;
  };

  starRender = (star) => {
    if (star == 1) return <Star style={{ cursor: 'default' }} />;
    else if (star == 0.5) return <HalfStar style={{ cursor: 'default' }} />;
    else return <EmptyStar style={{ cursor: 'default' }} />;
  };

  renderRow = (data, index, widthSize) => {
    const { id, date, orderNumber, userId, grade, detail } = data;
    const gradeArray = this.gradeStarPrint(grade);
    return (
      <React.Fragment key={index}>
        <div className="row" key={index}>
          <p style={{ width: widthSize[0] }}>
            <ToggleIcon
              onClick={() => {
                this.setState((prev) => ({ toggleTest: !this.state.toggleTest }));
                // this.setState(prev => ({toggle: [...prev.toggle, ]}))
              }}
            />
          </p>
          <p style={{ width: widthSize[1] }}>{date}</p>
          <p style={{ width: widthSize[2] }}>{orderNumber}</p>
          <p style={{ width: widthSize[3] }}>{userId}</p>
          <p style={{ width: widthSize[4] }}>
            {gradeArray?.map((g, index) => (
              <React.Fragment key={`${id}-${index}`}>{this.starRender(g)}</React.Fragment>
            ))}
          </p>
          <p style={{ width: widthSize[5] }}>
            <DeleteIcon
              onClick={() =>
                this.setState((prev) => {
                  this.props.dispatch(
                    openConfirmPopup({
                      title: 'NOTICE',
                      comment: '삭제 진행 재확인',
                      type: 'delete'
                    })
                  );
                })
              }
            />
          </p>
        </div>
        {id == 1 && this.state.toggleTest == true ? (
          <div className="description">
            <div style={{ width: '4%' }}></div>
            <div className="menu">
              <p className="title">
                <span>&#183;&nbsp;</span>주문 메뉴 :{' '}
              </p>
              <div className="content">{detail.menu?.map((data, index) => this.renderMenu(data, index))}</div>
            </div>
            <div className="info">
              <div>
                <p className="title">
                  <span>&#183;&nbsp;</span>리뷰 내용 :{' '}
                </p>
                &nbsp;{detail.reviewContents}
              </div>
              <div>
                <div>
                  <p className="title">
                    <span>&#183;&nbsp;</span>리뷰 사진 :{' '}
                  </p>
                  &nbsp;
                  {detail.reviewPhoto.map((photo, index) => {
                    return (
                      <React.Fragment key={index}>
                        {photo == '' ? (
                          <EmptyImage style={{ marginRight: '12px' }} />
                        ) : (
                          <img src={img} width="102px" height="81px" style={{ marginRight: '12px' }} />
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  };

  merchantContents = () => {
    const { page, limit, count, isLoading, reviewList, errorMsg, startDate, endDate } = this.state;
    const widthSize = ['4%', '18%', '20%', '22%', '25%', '4%', '4%'];

    return (
      <div style={{ width: 'fit-content' }}>
        <div className="top-button-container" style={{ marginRight: '30px' }}>
          <CalenderRange
            disabled={isLoading}
            startDate={isNotEmpty(startDate) ? moment(startDate).format('YYYY-MM-DD') : ''}
            endDate={isNotEmpty(endDate) ? moment(endDate).format('YYYY-MM-DD') : ''}
            queryParam={(res) => {
              const { startDate, endDate } = res;
              this.setState({
                startDate,
                endDate: isNotEmpty(endDate) ? addDays(endDate, 1) : ''
              });
            }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div className="container" style={{ marginRight: '30px' }}>
            <div className="table">
              {this.renderHeader(widthSize)}
              <div className="rows">{sampleData?.map((data, index) => this.renderRow(data, index, widthSize))}</div>
            </div>
            <div className="bottom-container">
              {/* 하단 */}
              <CustomPagination
                totalCount={count}
                page={page}
                limit={limit}
                onClickBack={this.handleClickBack}
                onClickForward={this.handleClickForward}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <TemplateDefault sidebar="store">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="title">리뷰 관리</span>
          </div>
          {this.merchantContents()}
        </TemplateDefault>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(Review));

const sampleData = [
  {
    id: 1,
    date: '2021.09.10',
    orderNumber: 'A-01',
    userId: '수리수리단골짱',
    grade: 3.5,
    detail: {
      menu: [
        { name: '김치찌개', count: 1 },
        { name: '치즈계란말이', count: 1 },
        { name: '공기밥', count: 1 },
        { name: '된장찌개', count: 1 },
        { name: '김치찌개', count: 1 },
        { name: '치즈계란말이', count: 1 },
        { name: '공기밥', count: 1 },
        { name: '된장찌개', count: 1 }
      ],
      reviewContents:
        '벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌',
      reviewPhoto: ['', '']
    }
  },
  {
    id: 2,
    date: '2021.09.10',
    orderNumber: 'A-01',
    userId: '수리수리박수리 단골',
    grade: 4,
    detail: {
      menu: [
        { name: '김치찌개', count: 1 },
        { name: '치즈계란말이', count: 1 },
        { name: '공기밥', count: 1 },
        { name: '된장찌개', count: 1 },
        { name: '김치찌개', count: 1 },
        { name: '치즈계란말이', count: 1 },
        { name: '공기밥', count: 1 },
        { name: '된장찌개', count: 1 }
      ],
      reviewContents:
        '벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌',
      reviewPhoto: ['']
    }
  },
  {
    id: 3,
    date: '2021.09.10',
    orderNumber: 'A-01',
    userId: '수리수리단골짱',
    grade: 3.5,
    detail: {
      menu: [
        { name: '김치찌개', count: 1 },
        { name: '치즈계란말이', count: 1 },
        { name: '공기밥', count: 1 },
        { name: '된장찌개', count: 1 },
        { name: '김치찌개', count: 1 },
        { name: '치즈계란말이', count: 1 },
        { name: '공기밥', count: 1 },
        { name: '된장찌개', count: 1 }
      ],
      reviewContents:
        '벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌개 너무너무 맛잇어요 짱ㅉ앚아ㅉㅇ아짱맺~~벌써 5번째 시켜먹었어요~ 김치찌',
      reviewPhoto: ['', '']
    }
  }
];
