import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

// import utils & icons
import { api } from '../../api';
import { returnFileSize, isEmpty, isNotEmpty } from '../../shared/util';
import { encodeFileToBase64 } from '../../shared/imageUtil';
import { ReactComponent as CloseButton } from '../../assets/img/svg/main/modal_close.svg';
import { ReactComponent as PendingIcon } from '../../assets/img/svg/main/pending.svg';
import { ReactComponent as UploadingIcon } from '../../assets/img/svg/main/uploading.svg';
import { ReactComponent as FailIcon } from '../../assets/img/svg/main/fail.svg';
import { ReactComponent as SuccessIcon } from '../../assets/img/svg/main/success.svg';
import { ReactComponent as FileIcon } from '../../assets/img/svg/main/file.svg';
import { ReactComponent as SmallErrorIcon } from '../../assets/img/svg/main/small_error.svg';
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';

class StoreMenuModal extends React.Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object,
    title: PropTypes.string,
    menuData: PropTypes.object,
    onClose: PropTypes.func,
    action: PropTypes.func
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      loadingStatus: 'pending', // pending, uploading, fail, success
      categoryName: props.menuData?.productCategory,
      name: props.menuData?.name,
      content: props.menuData?.content,
      price: props.menuData?.price,
      productImage: props.menuData?.productImage,
      oriProductImage: props.menuData?.productImage,
      file: {},
      isLoading: false
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------

  componentDidMount() {}

  // -- handler -------------------------------------------------------------------------------
  /* 파일 업로드 */
  fileChange = (event) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      this.setState({ loadingStatus: 'loading' });
      this.setState({ file: file });
      this.encodeFileToBase64(file);
      // this.setState({ loadingStatus: 'success' });
      this.setState({ loadingStatus: 'pending' });
    }
  };

  fileRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      productImage: null,
      file: null,
      oriProductImage: ''
    });
  };

  /* 입력 */
  handleChangeInput = (e) => {
    if (e.target.name === 'price') {
      const numRegex = /[^0-9]/g;
      if (numRegex.test(e.target.value)) return;
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  /* 등록 버튼 */
  handleClickAddButton = () => {
    const { categoryName, name, content, price, file, oriProductImage } = this.state;
    this.props.action(categoryName, name, content, price, file, this.props.menuData?.id, oriProductImage);
  };

  /* 이미지 미리보기 
File 혹은 Blob 을 읽은 뒤 base64로 인코딩한 문자열을 FileReader 인스턴스의 result 속성에 담아준다.
*/
  encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
      reader.onload = () => {
        this.setState({ productImage: reader.result });
        resolve();
      };
    });
  };

  // -- render --------------------------------------------------------------------------------

  loadingStatusRender = (state, option = 'file') => {
    switch (state) {
      case 'pending':
        return (
          <>
            <PendingIcon />
            <span>jpeg, png (10MB)</span>
          </>
        );
      case 'uploading':
        return (
          <>
            <UploadingIcon />
            <span>Uploading file</span>
          </>
        );
      case 'fail':
        return (
          <>
            <FailIcon />
            <span>Unable to upload file</span>
          </>
        );
      case 'success':
        return (
          <>
            <SuccessIcon />
            <span>File upload complete!</span>
          </>
        );
    }
  };
  textBoxRender = (state) => {
    switch (state) {
      case 'fail':
        return (
          <>
            <p className="text-box center" style={{ marginBottom: '6px', justifyContent: 'flex-end' }}>
              <span className="center">
                <FileIcon />
                {this.state.file?.name}
              </span>
              <span>({returnFileSize(this.state.file?.size)})</span>
            </p>
          </>
        );
      case 'success':
        return (
          <>
            <p className="text-box" style={{ marginBottom: '6px', justifyContent: 'flex-end' }}>
              <span className="center">
                <FileIcon />
                {this.state.file?.name}
              </span>
              <span>({returnFileSize(this.state.file?.size)})</span>
            </p>
          </>
        );
      default:
        return (
          <>
            <p className="text-box" style={{ marginBottom: '6px', justifyContent: 'flex-end' }}>
              -
            </p>
          </>
        );
    }
  };

  render() {
    const { categoryName, name, content, price, productImage, file } = this.state;

    return (
      <React.Fragment>
        <div className="modal-container" style={{ width: '100%' }}>
          <div className="modal" style={{ width: '600px', height: '850px' }}>
            <div className="title-container" style={{ width: 'auto', background: 'white' }}>
              <span style={{ margin: '0' }}>{this.props.title}</span>
              <CloseButton onClick={() => this.props.onClose()} />
            </div>
            <div className="content-container" style={{ paddingTop: '38px' }}>
              <div className="row" style={{ marginBottom: '11px' }}>
                <p style={{ marginBottom: '15px' }}>˙ 메뉴 이미지 등록</p>
                <input type="file" id="input-file" style={{ display: 'none' }} onChange={this.fileChange} />
                <div className="center">
                  <label htmlFor="input-file">
                    {isNotEmpty(productImage) ? (
                      <div style={{ position: 'relative' }}>
                        <img
                          className={`loading-status menu`}
                          src={productImage}
                          width="143px"
                          height="114px"
                          style={{ objectFit: 'cover' }}
                        />
                        <div style={{ position: 'absolute', top: 0, right: 0 }} onClick={this.fileRemove}>
                          <ClearTwoToneIcon style={{ width: '40px', height: '40px', color: '#74f5f4' }} />
                        </div>
                      </div>
                    ) : (
                      <div className={`loading-status menu ${this.state.loadingStatus}`}>
                        {this.loadingStatusRender(this.state.loadingStatus, this.props.option)}
                      </div>
                    )}
                  </label>
                </div>
                {this.textBoxRender(this.state.loadingStatus)}
              </div>
              <div className="row">
                <p>˙ 카테고리</p>
                <input
                  name="categoryName"
                  className="input-box"
                  type="text"
                  placeholder=""
                  value={categoryName || ''}
                  onChange={this.handleChangeInput}
                />
              </div>
              <div className="row">
                <p>˙ 메뉴명</p>
                <input
                  name="name"
                  className="input-box"
                  type="text"
                  placeholder=""
                  value={name || ''}
                  onChange={this.handleChangeInput}
                />
              </div>
              <div className="row">
                <p>˙ 메뉴 설명</p>
                <textarea
                  className="menu-content"
                  name={'content'}
                  value={content || ''}
                  maxLength={200}
                  onChange={this.handleChangeInput}
                ></textarea>
              </div>
              <div className="row">
                <p>˙ 메뉴 가격</p>
                <div>
                  <input
                    name="price"
                    className="input-box"
                    type="text"
                    placeholder="5000"
                    value={price || ''}
                    style={{ width: '166px' }}
                    onChange={this.handleChangeInput}
                  />
                  <input
                    name=""
                    className="input-box"
                    type="text"
                    placeholder=""
                    value="원"
                    style={{ width: '94px', padding: '0', textAlign: 'center' }}
                    disabled
                  />
                </div>
              </div>
              <span
                className={'button button-active'}
                style={{ width: '553px', color: 'black', marginLeft: '0', marginTop: '10px' }}
                onClick={this.handleClickAddButton}
              >
                {this.props.title === '메뉴 수정' ? '수정' : '등록'}
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}
export default connect(select)(StoreMenuModal);
