import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../assets/img/svg/popup/close_icon.svg';

class CustomPopup extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    isOpen: PropTypes.bool,
    closePopup: PropTypes.func,
    width: PropTypes.string,
    height: PropTypes.string
  };

  static defaultProps = {
    isOpen: false,
    closePopup: () => {},
    width: '50%',
    height: '50%'
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.state = {};
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {}

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // -- render --------------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <div className={`popup-wrap ${this.props.isOpen ? 'open' : 'close'} center`} onClick={this.props.closePopup}>
          <div
            className={`popup-container ${this.props.isOpen ? 'open' : 'close'} flex-column`}
            style={{ width: this.props.width, height: this.props.height }}
            onClick={(e) => {
              e.stopPropagation(); // 부모 이벤트 막기
            }}
          >
            <div className="close-icon" onClick={this.props.closePopup}>
              <CloseIcon />
            </div>
            {this.props.children}
            <div className="footer">
              <button className="close button-active" onClick={this.props.closePopup}>
                확인
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(CustomPopup));
