import axios from 'axios';
import { getTpError } from './shared/ErrorCodes';
import { showDialog } from './redux/dialog/dialogActions';
import { store } from './store';

export const baseURL = process.env.REACT_APP_ADMIN_API_BASE_URL;

export const api = axios.create({
  baseURL
});

export const getAuthorizedApi = () => {
  api.defaults.headers = {
    Authorization: 'Bearer ' + localStorage.getItem('accessToken')
  };
  return api;
};

export const CUSTOM_ERROR_HANDLE = { useCustomErrorHandle: true };

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 호출측에서 직접 에러를 처리하겠다고 하면, 공통 처리 로직을 건너 뛴다.
    if (error.config?.useCustomErrorHandle === true) {
      return Promise.reject(error);
    }

    // TODO: 공통 에러 처리
    const tpError = getTpError(error);

    if (tpError.resultCode === 401) {
      //토큰 관련
      if (tpError.name === 'EXPIRED_TOKEN') {
        store.dispatch(
          showDialog({
            title: '오류',
            content: '로그인 정보가 만료되었습니다.',
            modalStyle: 'warning'
          })
        );
      } else if (tpError.name === 'NO_TOKEN') {
        store.dispatch(
          showDialog({
            title: '오류',
            content: '로그인이 필요합니다.',
            modalStyle: 'warning'
          })
        );
      }

      localStorage.clear();
      window.location.href = '/';
    } else
      store.dispatch(
        showDialog({
          title: tpError.title,
          content: tpError.displayMessage,
          modalStyle: 'warning'
        })
      );

    return Promise.reject(error);
  }
);
