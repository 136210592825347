import { BACKDROP_LOADING_START, BACKDROP_LOADING_STOP } from '../actionTypes';
import produce from 'immer';

// TODO: stack 구조로 바꾸는 걸 고려하자. 지금 구조로는 한 번에 하나의 common dialog만 띄울 수 있다.

const initialState = {
  isBackDropLoading: false
};

export const globalReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case BACKDROP_LOADING_START: {
        draft.isBackDropLoading = true;
        break;
      }
      case BACKDROP_LOADING_STOP: {
        draft.isBackDropLoading = false;
        break;
      }

      default: {
        break;
      }
    }
  });
};
