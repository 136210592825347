import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TemplateDefault from '../template/TemplateDefault';
import TextAreaModal from '../../components/general/TextareaModal';
import StoreMenuModal from '../../components/general/StoreMenuModal';

// import actions
import { openConfirmPopup, closeConfirmPopup } from '../../redux/dialog/dialogActions';

// import utils & icons
import update from 'react-addons-update';
import { api } from '../../api';
import { numberWithCommas } from '../../shared/formatter';
import { ReactComponent as AddButton } from '../../assets/img/svg/main/add_btn.svg';
// import { ReactComponent as EmptyImage } from '../../assets/img/svg/store/menu/empty_image.svg';
import { ReactComponent as EmptyImage } from '../../assets/img/svg/store/menu/empty_image_no_add.svg';
import { ReactComponent as DetailImage } from '../../assets/img/svg/store/menu/detail.svg';
import { ReactComponent as StarIcon } from '../../assets/img/svg/main/star.svg';
import { ReactComponent as EditIcon } from '../../assets/img/svg/main/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/svg/main/delete.svg';
import { ReactComponent as SignatureIcon } from '../../assets/img/svg/store/menu/signature_icon.svg';
import { isEmpty, isNotEmpty } from '../../shared/util';

class Menu extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.settingModalEl = React.createRef();

    this.state = {
      storeId: props.match?.params?.storeid || -1,
      productList: [],
      productOrigin: '',
      showTextModal: false,
      showMenuModal: false,
      showEditMenuData: {},
      toggle: -1,
      isOpenWarningPopup: false
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { isLoading } = this.state;
    if (!isLoading) {
      this.loadProducts();
      this.loadProductsOrigin();
    }
    // 옵션팝업 이외 영역 조작
    window.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  // -- handler -------------------------------------------------------------------------------

  // 이외 영역 클릭시 닫기
  handleClickOutside = ({ target }) => {
    // 설정 메뉴
    if (this.state.toggle !== -1 && !this.settingModalEl.current?.contains(target)) {
      this.setState({
        toggle: -1
      });
    }
  };

  // 메뉴 리스트 로드
  loadProducts = () => {
    const { storeId } = this.state;

    this.setState({
      isLoading: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .get(`/v1/places/${storeId}/products`)
      .then((res) => {
        const { products } = res.data;
        this.setState({
          productList: products,
          isLoading: false
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
      });
  };
  // 원산지 표기 조회
  loadProductsOrigin = () => {
    const { storeId } = this.state;

    this.setState({
      isLoading: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .get(`/v1/places/${storeId}/product-origin`)
      .then((res) => {
        this.setState({
          productOrigin: res.data.productOrigin,
          isLoading: false
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
      });
  };

  /* 원산지 표기 수정 및 등록 */
  handleChangeProductOrigin = (content) => {
    const { storeId } = this.state;
    if (isEmpty(content)) return alert('내용을 입력해주세요.');
    else {
      this.setState({
        isLoading: true
      });
      api.defaults.headers = {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
      };
      api
        .patch(`/v1/places/${storeId}/product-origin`, { productOrigin: content })
        .then((res) => {
          this.setState({
            productOrigin: content,
            isLoading: false,
            showTextModal: false
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false
          });
        });
    }
  };

  /* 메뉴 등록 */
  addMenu = (categoryName, name, content, price, file) => {
    const { dispatch } = this.props;
    const { isLoading, productList, storeId } = this.state;

    // 더블클릭 등 중복 요청 방지
    if (isLoading) return;

    if (isEmpty(categoryName) || isEmpty(name) || isEmpty(price)) {
      dispatch(
        openConfirmPopup({
          title: 'NOTICE',
          comment: '필수정보 입력 재확인',
          type: 'warning'
        })
      );
    } else {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('categoryName', categoryName);
      formData.append('name', name);
      formData.append('content', content);
      formData.append('price', price);

      this.setState({
        isLoading: true
      });
      api.defaults.headers = {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
      };
      api
        .post(`/v1/places/${storeId}/products`, formData)
        .then((res) => {
          const newProduct = res.data;

          this.setState({
            isLoading: false,
            showMenuModal: false,
            productList: update(productList, { $push: [newProduct] })
          });

          dispatch(
            openConfirmPopup({
              title: 'NOTICE',
              comment: '등록 완료',
              type: 'success'
            })
          );
        })
        .catch((err) => {
          this.setState({
            isLoading: false
          });
        });
    }
  };
  /* 메뉴 수정 */
  editMenu = (categoryName, name, content, price, file, productId, productImage) => {
    const { dispatch } = this.props;
    const { isLoading, productList, storeId } = this.state;

    // 더블클릭 등 중복 요청 방지
    if (isLoading) return;

    if (isEmpty(categoryName) || isEmpty(name) || isEmpty(price)) {
      this.setState({
        isOpenWarningPopup: true
      });
    } else {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('categoryName', categoryName);
      formData.append('name', name);
      formData.append('content', content);
      formData.append('price', price);
      formData.append('oriProductImage', productImage);

      this.setState({
        isLoading: true
      });
      api.defaults.headers = {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
      };
      api
        .put(`/v1/places/${storeId}/products/${productId}`, formData)
        .then((res) => {
          const findIndex = productList.findIndex((v) => v.id === productId);
          this.setState({
            isLoading: false,
            showEditMenuData: {},
            showMenuModal: false,
            productList: update(productList, { [findIndex]: { $set: res.data } })
          });

          dispatch(
            openConfirmPopup({
              title: 'NOTICE',
              comment: '수정 완료',
              type: 'success'
            })
          );
        })
        .catch((err) => {
          this.setState({
            isLoading: false
          });
        });
    }
  };

  /* 대표메뉴 */
  handleClickSignature = (productId, isSignature) => {
    const { dispatch } = this.props;
    const { productList, isLoading, storeId } = this.state;

    // 더블클릭 등 중복 요청 방지
    if (isLoading) return;

    this.setState({
      isLoading: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    const body = {
      isSignature: !isSignature
    };
    api
      .patch(`/v1/places/${storeId}/products/${productId}/toggle-signature`, body)
      .then((res) => {
        const findIndex = productList.findIndex((v) => v.id === productId);
        this.setState({
          toggle: -1,
          isLoading: false,
          productList: update(productList, { [findIndex]: { isSignature: { $set: !isSignature } } })
        });
        dispatch(
          openConfirmPopup({
            title: 'NOTICE',
            comment: '수정 완료',
            type: 'success'
          })
        );
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
      });
  };

  /* 메뉴 삭제 버튼 클릭 재확인 팝업창*/
  handleClickDelete = (id) => {
    const { dispatch } = this.props;
    dispatch(
      openConfirmPopup({
        title: 'NOTICE',
        comment: '삭제 진행 재확인',
        type: 'delete',
        onOk: () => this.deleteProduct(id)
      })
    );
  };

  /* 메뉴 삭제 */
  deleteProduct = (productId) => {
    const { dispatch } = this.props;
    const { productList, isLoading, storeId } = this.state;

    // 더블클릭 등 중복 요청 방지
    if (isLoading) return;

    this.setState({
      isLoading: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .delete(`/v1/places/${storeId}/products/${productId}`)
      .then((res) => {
        const findIndex = productList.findIndex((v) => v.id === productId);

        this.setState({
          isLoading: false,
          productList: update(productList, { $splice: [[findIndex, 1]] })
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
      })
      .finally(() => {
        dispatch(closeConfirmPopup());
      });
  };

  /* 수정 버튼 클릭 */
  handleClickEdit = (data) => {
    this.setState({
      showEditMenuData: data,
      toggle: -1
    });
  };

  // -- render --------------------------------------------------------------------------------

  renderHeader = (widthSize) => {
    const titles = ['메뉴 이미지', '카테고리명', '메뉴명', '메뉴 설명', '메뉴 가격'];
    return (
      <div className="menu-row th">
        <p style={{ width: widthSize[0] }}></p>
        <p style={{ width: widthSize[1] }}>{titles[0]}</p>
        <p style={{ width: widthSize[2] }}>{titles[1]}</p>
        <p style={{ width: widthSize[3] }}>{titles[2]}</p>
        <p style={{ width: widthSize[4] }}>{titles[3]}</p>
        <p style={{ width: widthSize[5] }}>{titles[4]}</p>
        <p style={{ width: widthSize[6] }}></p>
      </div>
    );
  };

  renderRow = (data, index, widthSize) => {
    const { dispatch } = this.props;
    const { content, id, isSignature, productCategory, name, price, productImage } = data;

    return (
      <React.Fragment key={id}>
        <div className="menu-row" key={index} style={{ position: 'relative', maxWidth: '1402px' }}>
          <div style={{ width: widthSize[0] }} className="index index-div">
            <span>{index + 1}</span>
            {isSignature && (
              <div className="signature">
                <SignatureIcon />
              </div>
            )}
          </div>
          <p style={{ width: widthSize[1] }}>
            {isEmpty(productImage) ? <EmptyImage /> : <img src={productImage} width="143px" height="114px" />}
          </p>
          <p style={{ width: widthSize[2] }}>
            <input disabled type="text" value={productCategory} className="text-box" />
          </p>
          <p style={{ width: widthSize[3] }}>
            <input disabled type="text" value={name} className="text-box" />
          </p>
          <p style={{ width: widthSize[4] }}>
            <input disabled type="text" value={content} className="text-box" />
          </p>
          <p style={{ width: widthSize[5] }}>
            <input disabled type="text" value={numberWithCommas(price)} className="text-box" />
          </p>
          <div
            className="center"
            style={{ width: widthSize[6], overflow: 'hidden' }}
            ref={this.state.toggle == id && this.settingModalEl}
          >
            <DetailImage
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (this.state.toggle === id) this.setState({ toggle: -1 });
                else this.setState({ toggle: id });
              }}
            />
            {this.state.toggle == id ? (
              <div className="editMenu">
                <p onClick={() => this.handleClickSignature(id, isSignature)}>
                  <StarIcon /> <span>대표메뉴</span>
                </p>
                <p onClick={() => this.handleClickEdit(data)}>
                  <EditIcon /> <span>수정</span>
                </p>
                <p onClick={() => this.handleClickDelete(id)}>
                  <DeleteIcon /> <span>삭제</span>
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  merchantContents = () => {
    const { productList } = this.state;
    const widthSize = ['4%', '15%', '17%', '27%', '27%', '15%', '4%'];

    return (
      <div style={{ width: 'fit-content' }}>
        <div className="top-button-container" style={{ marginRight: '16px' }}>
          <p
            className={'hyper-mint'}
            onClick={() => {
              this.setState({ showMenuModal: true });
            }}
          >
            <AddButton />
            <span>메뉴 추가</span>
          </p>
        </div>
        <div style={{ display: 'flex' }}>
          <div className="menu-container" style={{ marginRight: '16px' }}>
            <div className="menu-table">
              {this.renderHeader(widthSize)}
              <div className="menu-rows">
                {productList?.map((data, index) => this.renderRow(data, index, widthSize))}
              </div>
            </div>
          </div>
        </div>
        <span
          className="origin-button button-active"
          onClick={() => {
            this.setState({ showTextModal: true });
          }}
        >
          원산지
        </span>
      </div>
    );
  };

  render() {
    const { productOrigin, isLoading } = this.state;

    return (
      <React.Fragment>
        <TemplateDefault sidebar="store">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="title">메뉴 관리</span>
          </div>
          {this.merchantContents()}
        </TemplateDefault>
        {this.state.showTextModal ? (
          <TextAreaModal
            handleClickButton={this.handleChangeProductOrigin}
            title="원산지 표기 등록"
            content={productOrigin}
            onClose={() => {
              this.setState({ showTextModal: false });
            }}
            disabled={isLoading}
          />
        ) : null}
        {this.state.showMenuModal && (
          <StoreMenuModal
            action={this.addMenu}
            title="메뉴 추가"
            onClose={() => {
              this.setState({ showMenuModal: false });
            }}
          />
        )}
        {isNotEmpty(this.state.showEditMenuData) && (
          <StoreMenuModal
            menuData={this.state.showEditMenuData}
            action={this.editMenu}
            title="메뉴 수정"
            onClose={() => {
              this.setState({ showEditMenuData: {} });
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(Menu));
