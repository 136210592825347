import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import update from 'react-addons-update';

import { Button } from '@material-ui/core';

// import components
import CustomPagination from '../../components/custom/CustomPagination';
import TemplateDefault from '../template/TemplateDefault';
import CalenderRange from '../../components/general/CalenderRange';

// import utils
import { api } from '../../api';
import moment from 'moment';
import { isNotEmpty } from '../../shared/util';
import { numberWithCommas } from '../../shared/formatter';
import { addDays } from '../../shared/dateTimeUtil';
import { ReactComponent as ToggleIcon } from '../../assets/img/svg/store/order/toggle.svg';
import { closeConfirmPopup, openConfirmPopup } from '../../redux/dialog/dialogActions';

const headers = [
  { text: '', width: '4%' },
  { text: '등록일자', width: '11%' },
  { text: '오더종류', width: '8%' },
  { text: '사용자 (ID)', width: '15%' },
  { text: '주문 번호', width: '7%' },
  { text: '주문 메뉴', width: '16.5%' },
  { text: '결제 금액', width: '11%' },
  { text: '주문 처리', width: 'auto' },
  { text: '결제 취소', width: '10%' }
];

class Order extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      storeId: props.match?.params?.storeid || -1,
      limit: 10,
      page: 1,
      count: 0,
      orderList: [],
      isLoading: false,
      errorMsg: '',
      startDate: '',
      endDate: '',
      openDetailIdList: []
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { isLoading } = this.state;
    if (!isLoading) this.loadOrder();
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, isLoading, page } = this.state;

    // 날짜 변경
    if (isNotEmpty(startDate) && isNotEmpty(endDate)) {
      if (prevState.startDate !== startDate || prevState.endDate !== endDate) {
        if (!isLoading) this.loadOrder();
      }
    }

    // 페이지 변경
    if (prevState.page !== page) {
      this.loadOrder();
    }
  }

  // -- handler -------------------------------------------------------------------------------

  // 주문 리스트 로드
  loadOrder = () => {
    const { storeId, startDate, endDate, limit, page } = this.state;
    const isDate = isNotEmpty(startDate) && isNotEmpty(endDate);

    this.setState({
      isLoading: true
    });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    api
      .get(
        `/v1/places/${storeId}/orders?limit=${limit}&page=${page}${
          isDate ? `&startDate=${startDate}&endDate=${endDate}` : ''
        }`
      )
      .then((res) => {
        const { orders, count } = res.data;
        this.setState({
          orderList: orders,
          count: count,
          isLoading: false
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
      });
  };

  // 이전 페이지
  handleClickBack = () => {
    this.setState((prev) => ({
      page: prev.page - 1
    }));
  };
  // 다음 페이지
  handleClickForward = () => {
    this.setState((prev) => ({
      page: prev.page + 1
    }));
  };

  doHardCancel = (orderNo, payer, menuTitle) => {
    const { dispatch } = this.props;

    dispatch(
      openConfirmPopup({
        title: '취소 진행 재확인',
        comment: `"${payer}"가 주문한 "${menuTitle}"의 결제 취소를 원하시나요?`,
        type: 'delete',
        onOk: () => {
          api.defaults.headers = {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken')
          };
          api
            .put(`/v1/places/${this.state.storeId}/orders/hardCancel/${orderNo}`)
            .then((res) => {
              if (res.data.code !== 0) {
                console.error(res.data);
                throw new Error(res.data.code);
              } else {
                dispatch(
                  openConfirmPopup({
                    title: 'SUCCESS',
                    comment: '해당 주문의 결제가 취소되었습니다.',
                    type: 'success',
                    onOk: () => {
                      dispatch(closeConfirmPopup());
                    }
                  })
                );

                this.setState({
                  orderList: this.state.orderList.map((ele) =>
                    ele.orderNo === orderNo ? { ...ele, status: 'C' } : ele
                  )
                });
              }
            })
            .catch((err) => {
              let errMessage = '에러가 발생했습니다. Console 창을 확인해주세요!';
              if (err.message === 9003) {
                errMessage = 'Eximbay 이슈로 결제 취소가 이루어지지 않았습니다. PG사에 문의해주세요!';
              } else if (err.message === 9004) {
                errMessage = 'iamport 상의 이슈로 결제 취소가 이루어지지 않았습니다. PG사에 문의해주세요!';
              } else if (err.message >= 400) {
                errMessage =
                  '하이퍼 클라우드 DB 상의 이슈로 결제 취소가 이루어지지 않았습니다. Console 창을 확인해주세요!';
              }

              dispatch(
                openConfirmPopup({
                  title: 'WARNING',
                  comment: errMessage,
                  type: 'warning',
                  onOk: () => {
                    dispatch(closeConfirmPopup());
                  }
                })
              );
            });
        }
      })
    );
  };

  // -- render --------------------------------------------------------------------------------

  renderHeader = () => {
    return (
      <div className="row th">
        {headers.map(({ text, width }) => (
          <p key={text} style={{ width }}>
            {text}
          </p>
        ))}
      </div>
    );
  };

  renderMenu = (data, index) => {
    const { product, amount } = data;
    return (
      <React.Fragment key={index}>
        <span>{`${product?.name} ${amount}`}</span>
        <br />
      </React.Fragment>
    );
  };

  renderRow = (data, index) => {
    const {
      id,
      createdAt,
      orderType,
      payUserName,
      dayOrderNo,
      orderProducts,
      price,
      status,
      requestMessage,
      paymentType,
      orderNo
    } = data;

    const { openDetailIdList } = this.state;

    // 메뉴 정보 - 1개 메뉴 + 외 개수
    const menuLength = orderProducts?.length || 0;
    const menuTitle = orderProducts?.[0]?.product?.name + (menuLength > 1 ? ' 외' + (menuLength - 1) : '');

    // 쿠폰 정보 추출
    const coupon = orderProducts?.[0]?.coupon?.name;
    // 프로모션 정보 추출
    const promotion = orderProducts?.[0]?.promotion?.name;

    // 메뉴 펼침
    const isOpen = openDetailIdList.includes(id);

    return (
      <React.Fragment key={index}>
        <div className="row" key={index}>
          <p style={{ width: headers[0].width }}>
            <ToggleIcon
              onClick={() => {
                if (isOpen) {
                  const findIndex = openDetailIdList.findIndex((v) => v === id);
                  this.setState({ openDetailIdList: update(openDetailIdList, { $splice: [[findIndex, 1]] }) });
                } else this.setState({ openDetailIdList: update(openDetailIdList, { $push: [id] }) });
              }}
            />
          </p>
          <p style={{ width: headers[1].width }}>{moment(createdAt).format('YYYY.MM.DD')}</p>
          <p style={{ width: headers[2].width }}>{orderType?.name}</p>
          <p style={{ width: headers[3].width }}>{payUserName}</p>
          <p style={{ width: headers[4].width }}>{dayOrderNo}</p>
          <p style={{ width: headers[5].width }}>{menuTitle}</p>
          <p style={{ width: headers[6].width }}>{numberWithCommas(price)}</p>
          <p style={{ width: headers[7].width }}>
            <span className={status === 'B' || status === 'P' ? 'issue gray' : 'issue'}>승인 전</span>
            <span className={status === 'A' || status === 'E' ? 'issue on' : 'issue'}>승인</span>
            <span className={status === 'C' ? 'issue off' : 'issue'}>취소</span>
            <span className={status === 'F' ? 'issue gray' : 'issue'}>완료</span>
          </p>
          <p style={{ width: headers[8].width }}>
            <Button
              onClick={() => this.doHardCancel(orderNo, payUserName, menuTitle)}
              color="secondary"
              variant="contained"
              disabled={status === 'C' || status === 'B'}
            >
              결제 취소
            </Button>
          </p>
        </div>
        {isOpen && (
          <div className="description">
            <div style={{ width: '4%' }}></div>
            <div className="menu">
              <p className="title">
                <span>&#183;&nbsp;</span>주문 메뉴 :{' '}
              </p>
              <div className="content">{orderProducts?.map((data, index) => this.renderMenu(data, index))}</div>
            </div>
            <div className="info">
              <div>
                <p className="title">
                  <span>&#183;&nbsp;</span>고객요청 메세지 :{' '}
                </p>
                &nbsp;{requestMessage}
              </div>
              <div>
                <div style={{ width: '40%' }}>
                  <p className="title">
                    <span>&#183;&nbsp;</span>결제 방식 :{' '}
                  </p>
                  &nbsp;{paymentType}
                </div>
                <div style={{ width: '60%' }}>
                  <p className="title">
                    <span>&#183;&nbsp;</span>쿠폰 사용 :{' '}
                  </p>
                  &nbsp;{coupon}
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  merchantContents = () => {
    const { page, limit, count, isLoading, orderList, errorMsg, startDate, endDate } = this.state;
    return (
      <div style={{ width: 'fit-content' }}>
        <div className="top-button-container" style={{ marginRight: '30px' }}>
          <CalenderRange
            disabled={isLoading}
            startDate={isNotEmpty(startDate) ? moment(startDate).format('YYYY-MM-DD') : ''}
            endDate={isNotEmpty(endDate) ? moment(endDate).format('YYYY-MM-DD') : ''}
            queryParam={(res) => {
              const { startDate, endDate } = res;
              this.setState({
                startDate,
                endDate: isNotEmpty(endDate) ? addDays(endDate, 1) : ''
              });
            }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div className="container" style={{ marginRight: '30px' }}>
            <div className="table">
              {this.renderHeader()}
              <div className="rows">{orderList?.map((data, index) => this.renderRow(data, index))}</div>
            </div>
            {/* 하단 */}
            <CustomPagination
              totalCount={count}
              page={page}
              limit={limit}
              onClickBack={this.handleClickBack}
              onClickForward={this.handleClickForward}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <TemplateDefault sidebar="store">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="title">주문 관리</span>
          </div>
          {this.merchantContents()}
        </TemplateDefault>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(Order));
