import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadProjectVersion } from '../../redux/version/versionActions';
import { changeStoreValue } from '../../redux/root/actions';
import { showDialog } from '../../redux/dialog/dialogActions';
import SideMenuBar from '../../components/SideBar';
import Header from '../../components/Header';

import '../../assets/scss/template/temp-default.scss';

class TemplateDefault extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {};
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(changeStoreValue('version', 'currentVersion', 'changeStoreValue (3.0.0)'));
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  handleServerVersionInfo = () => {
    const { dispatch } = this.props;
    const onOk = () => {
      dispatch(loadProjectVersion());
    };

    dispatch(
      showDialog({
        title: '창제목',
        content: '서버에서 버전 정보를 가져 올까요?',
        onOk: onOk,
        buttonCount: 2
      })
    );
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const { version } = this.props;

    return (
      <React.Fragment>
        <div className="root-bg">
          <SideMenuBar sidebar={this.props.sidebar} />
          <div style={{ paddingLeft: '240px' }}>
            <Header />
            <div className="contents-container">{this.props.children}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(TemplateDefault));
