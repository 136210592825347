import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import update from 'react-addons-update';
import { Link } from 'react-router-dom';

// import compoents
import { openConfirmPopup, closeConfirmPopup } from '../../redux/dialog/dialogActions';
import TemplateDefault from '../template/TemplateDefault';
import CustomPagination from '../../components/custom/CustomPagination';
import CustomLoading from '../../components/custom/CustomLoading';
import CustomErrorMessage from '../../components/custom/CustomErrorMessage';
import QrCodePopup from '../../components/general/QrCodePopup';
import { ToggleSwitch } from '../../components/mui/LBToggle';
import PlaceUploadModal from '../../components/general/PlaceUploadModal';

// import utils
import { api } from '../../api';
import { isEmpty, isNotEmpty } from '../../shared/util';
import Swal from 'sweetalert2';

// import icon
import { ReactComponent as WarningBigIcon } from '../../assets/img/svg/popup/warning_big_icon.svg';
import { ReactComponent as AddButton } from '../../assets/img/svg/main/add_btn.svg';
import { ReactComponent as RemoveButton } from '../../assets/img/svg/main/remove_btn.svg';

moment.locale('ko');

class Main extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      limit: 50,
      page: 1,
      placeList: [],
      placeCheckedList: [],
      count: 0,
      isLoading: false,
      errorMsg: '',
      isOpenQrcodePopup: false,
      qrCodeUrl: '',
      isOpenDeleteDialog: false
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { isLoading } = this.state;
    // 가맹점 리스트 조회
    if (!isLoading) this.loadPlaceList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { page } = this.state;

    // 페이지 변경
    if (prevState.page !== page) {
      this.loadPlaceList();
    }
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------

  // 가맹점 조회
  loadPlaceList = () => {
    const { page, limit } = this.state;
    // this.setState({ isLoading: true, errorMsg: '' });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };

    api
      .get(`/v1/places?limit=${limit}&page=${page}`)
      .then((res) => {
        this.setState({ isLoading: false, placeList: res.data?.places, count: res.data?.count });
      })
      .catch((err) => {
        this.setState({ isLoading: false, errorMsg: '예기치 못한 에러가 발생되었습니다.' });
      })
      .finally(() => {});
  };

  // 테이블 오더 On/Off
  handleClickTableToggle = (placeId, isOpen) => {
    const { placeList } = this.state;
    this.setState({ isToggling: true });
    api
      .patch(`/v1/places/${placeId}/toggle-table-order`, { isOpen })
      .then((res) => {
        const findIndex = placeList.findIndex((v) => v.id === placeId);
        if (findIndex !== -1) {
          this.setState({
            placeList: update(placeList, { [findIndex]: { isOpenTable: { $set: res.data?.isOpen } } }),
            isToggling: false
          });
        }
      })
      .catch((err) => {
        this.setState({ isToggling: false, errorMsg: '예기치 못한 오류가 발생되었습니다.' });
      })
      .finally(() => {});
  };

  // 픽업 오더 On/Off
  handleClickPickUpToggle = (placeId, isOpen) => {
    const { placeList } = this.state;
    this.setState({ isToggling: true });
    api
      .patch(`/v1/places/${placeId}/toggle-pickup-order`, { isOpen })
      .then((res) => {
        const findIndex = placeList.findIndex((v) => v.id === placeId);
        if (findIndex !== -1) {
          this.setState({
            placeList: update(placeList, { [findIndex]: { isOpenPickup: { $set: res.data?.isOpen } } }),
            isToggling: false
          });
        }
      })
      .catch((err) => {
        this.setState({ isToggling: false });
      })
      .finally(() => {});
  };

  // 이전 페이지
  handleClickBack = () => {
    this.setState((prev) => ({
      page: prev.page - 1
    }));
  };
  // 다음 페이지
  handleClickForward = () => {
    this.setState((prev) => ({
      page: prev.page + 1
    }));
  };

  // QrCode 팝업창 Open
  openQrcodePopup = (qrUrl) => {
    this.setState({
      isOpenQrcodePopup: true,
      qrCodeUrl: qrUrl
    });
  };
  // QrCode 팝업창 close
  closeQrcodePopup = () => {
    this.setState({
      isOpenQrcodePopup: false,
      qrCodeUrl: ''
    });
  };

  // 선택된 가맹점 삭제 > 팝업창 띄우기
  handleClickDeletePlace = () => {
    const { placeCheckedList } = this.state;

    if (isEmpty(placeCheckedList)) {
      alert('가맹점을 하나 이상 선택해주세요.');
    } else {
      /* 삭제 버튼 클릭 재확인 팝업창*/
      const { dispatch } = this.props;
      dispatch(
        openConfirmPopup({
          title: 'NOTICE',
          comment: '삭제 진행 재확인',
          type: 'delete',
          onOk: () => this.handleClickAgree()
        })
      );
    }
  };

  handleDownloadJsonAlert = () => {
    const { placeCheckedList } = this.state;

    if (isEmpty(placeCheckedList)) {
      alert('가맹점을 하나 이상 선택해주세요.');
    } else {
      Swal.fire({
        title: '가맹점 JSON 다운로드',
        input: 'select',
        inputOptions: {
          insert: '신규 가맹점 등록',
          update: '기존 가맹점 업데이트'
        },
        inputPlaceholder: 'Json 타입 선택',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            alert('Json 타입을 선택해주세요.');
          }
          this.handleDownloadJson(value);
        }
      });
    }
  };

  handleDownloadJson = (type) => {
    const { placeCheckedList } = this.state;

    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', `${api.defaults.baseURL}v1/place-json/download-json`);
    form.setAttribute('target', '_blank');
    const inputType = document.createElement('input');
    inputType.setAttribute('type', 'hidden');
    inputType.setAttribute('name', 'type');
    inputType.setAttribute('value', type);
    const inputPlaceIdList = document.createElement('input');
    inputPlaceIdList.setAttribute('type', 'hidden');
    inputPlaceIdList.setAttribute('name', 'placeId');
    inputPlaceIdList.setAttribute('value', placeCheckedList);
    form.appendChild(inputType);
    form.appendChild(inputPlaceIdList);
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };
  // 선택된 가맹점 삭제
  handleClickAgree = () => {
    const { dispatch } = this.props;
    const { placeCheckedList, placeList, isLoadingDelete } = this.state;

    if (isLoadingDelete) return;

    this.setState({
      isLoadingDelete: true
    });

    api
      .post(`/v1/places/remove`, { placeIdList: placeCheckedList })
      .then((res) => {
        const newList = placeList.filter((v) => !placeCheckedList.includes(v.id));

        this.setState({
          placeList: newList,
          placeCheckedList: [],
          isOpenDeleteDialog: false,
          isLoadingDelete: false
        });
      })
      .catch((err) => {
        this.setState({ isOpenDeleteDialog: false, isLoadingDelete: false });
      })
      .finally(() => {
        dispatch(closeConfirmPopup());
      });
  };

  // 모든 체크 선택
  // 전체 선택 여부에 따라 on/off
  handleClickAllCheckBox = () => {
    const { placeCheckedList, placeList } = this.state;
    const isAllChecked = isNotEmpty(placeList) && placeList?.length === placeCheckedList?.length ? true : false;

    // 전체 선택 상태
    if (isAllChecked) {
      this.setState({ placeCheckedList: [] });
    }
    // 전체 선택이 아닌 경우
    else {
      const allId = placeList.map((v) => v.id);
      this.setState({ placeCheckedList: allId });
    }
  };

  handleClickCheckBox = (id) => {
    const { placeCheckedList } = this.state;
    const isChecked = placeCheckedList.includes(id);

    // 체크
    if (!isChecked) {
      const newList = [...placeCheckedList, id];
      this.setState({
        placeCheckedList: newList
      });
    }
    // 체크 해제
    else {
      const newList = placeCheckedList?.filter((v) => v !== id);
      this.setState({
        placeCheckedList: newList
      });
    }
  };

  // Row Data
  renderRow = (data, index, rowWidth) => {
    const { isToggling, placeCheckedList } = this.state;
    const { id, createdAt, name, partner, placeCategory, qrCodeUrl, status, isOpenTable, isOpenPickup } = data;
    //체크 여부
    const isChecked = placeCheckedList.includes(id);
    return (
      <div className="row" key={`${index}-${id}`}>
        <p className="ellipsis" style={{ width: '50px', minWidth: '50px' }}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => this.handleClickCheckBox(id)}
            className="checkbox"
          />
        </p>
        <p className="ellipsis" style={{ width: rowWidth[0] }}>
          {/* {moment(createdAt).format('YYYY-MM-DD HH:mm:ss')} 서버와의 시간 확인용*/}
          {moment(createdAt).format('YYYY-MM-DD')}
        </p>
        <p className="ellipsis" style={{ width: rowWidth[1] }}>
          <Link to={`/store/${id}`} className="text-link">
            {' '}
            {name}
          </Link>
        </p>
        <p className="ellipsis" style={{ width: rowWidth[2] }}>
          {partner?.businessNumber}
        </p>
        <p className="ellipsis" style={{ width: rowWidth[3] }}>
          {partner?.ownerName}
        </p>
        <p className="ellipsis" style={{ width: rowWidth[4] }}>
          {partner?.email}
        </p>
        <p
          className={`ellipsis ${isNotEmpty(qrCodeUrl) && ' qr-btn'}`}
          style={{ width: rowWidth[5] }}
          onClick={() => this.openQrcodePopup(qrCodeUrl)}
        >
          {isNotEmpty(qrCodeUrl) ? 'Y' : 'N'}
        </p>
        <p className="ellipsis" style={{ width: rowWidth[6] }}>
          {placeCategory?.name}
        </p>
        <p style={{ width: rowWidth[7] }}>
          <span className={isOpenPickup || isOpenTable ? 'status on' : 'status off'}></span>
        </p>
        <p style={{ width: rowWidth[8] }}>
          <ToggleSwitch
            checked={isOpenTable}
            onClick={() => this.handleClickTableToggle(id, !isOpenTable)}
            name="checkedB"
            disabled={isToggling}
          />
        </p>
        <p style={{ width: rowWidth[9] }}>
          <ToggleSwitch
            checked={isOpenPickup}
            onClick={() => this.handleClickPickUpToggle(id, !isOpenPickup)}
            name="checkedB"
            disabled={isToggling}
          />
        </p>
      </div>
    );
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const {
      page,
      limit,
      count,
      isLoading,
      errorMsg,
      placeList,
      isOpenQrcodePopup,
      qrCodeUrl,
      placeCheckedList,
      isOpenDeleteDialog
    } = this.state;
    const tableHeader = [
      '등록일자',
      '가맹점명',
      '사업자번호',
      '대표자',
      '메일 계정',
      'QR Code',
      '업종',
      '상태',
      '테이블 ON',
      '픽업 ON'
    ];
    const rowWidth = ['8%', '20%', '14%', '8%', '16%', '7%', '7%', '5%', '8%', '8%'];
    // 전체 체크 여부
    const isAllChecked = isNotEmpty(placeList) && placeList?.length === placeCheckedList?.length ? true : false;
    return (
      <React.Fragment>
        {/* QrCode 팝업창 */}
        <QrCodePopup isOpen={isOpenQrcodePopup} closePopup={this.closeQrcodePopup} qrCodeUrl={qrCodeUrl} />
        <TemplateDefault sidebar="admin">
          <span className="title">가맹점 관리</span>
          <div style={{ width: 'fit-content' }}>
            <div className="top-button-container">
              <p className={'hyper-mint'} onClick={() => this.setState({ showModal: true })}>
                <AddButton />
                <span>{'가맹점 등록'}</span>
              </p>
              <p className={'red white-font'} onClick={this.handleClickDeletePlace}>
                <RemoveButton />
                <span>{'가맹점 삭제'}</span>
              </p>
              <p className={'green white-font'} onClick={this.handleDownloadJsonAlert}>
                <AddButton />
                <span>{'JSON 다운받기'}</span>
              </p>
            </div>
            <div className="container">
              <div className="table">
                <div className="th">
                  <p style={{ width: '50px', minWidth: '50px' }}>
                    <input
                      type="checkbox"
                      checked={isAllChecked}
                      onChange={this.handleClickAllCheckBox}
                      className="checkbox"
                    />
                  </p>
                  <p style={{ width: rowWidth[0] }}>{tableHeader[0]}</p>
                  <p style={{ width: rowWidth[1] }}>{tableHeader[1]}</p>
                  <p style={{ width: rowWidth[2] }}>{tableHeader[2]}</p>
                  <p style={{ width: rowWidth[3] }}>{tableHeader[3]}</p>
                  <p style={{ width: rowWidth[4] }}>{tableHeader[4]}</p>
                  <p style={{ width: rowWidth[5] }}>{tableHeader[5]}</p>
                  <p style={{ width: rowWidth[6] }}>{tableHeader[6]}</p>
                  <p style={{ width: rowWidth[7] }}>{tableHeader[7]}</p>
                  <p style={{ width: rowWidth[8] }}>{tableHeader[8]}</p>
                  <p style={{ width: rowWidth[9] }}>{tableHeader[9]}</p>
                </div>
                {isLoading ? (
                  <CustomLoading size={'100px'} />
                ) : isNotEmpty(errorMsg) ? (
                  <CustomErrorMessage message={errorMsg} />
                ) : (
                  <div className="rows">{placeList?.map((data, index) => this.renderRow(data, index, rowWidth))}</div>
                )}
              </div>
              {/* 하단 */}
              <CustomPagination
                totalCount={count}
                page={page}
                limit={limit}
                onClickBack={this.handleClickBack}
                onClickForward={this.handleClickForward}
                isLoading={isLoading}
              />
            </div>
          </div>
        </TemplateDefault>
        {this.state.showModal ? (
          <PlaceUploadModal
            // TODO: isValid는 나중에 동의/비동의 같은 항목에서 return 값 받아오는 용도로 쓸 예정
            title="가맹점 등록"
            isValid={(valid) => {
              this.setState({ showModal: false });
            }}
            handleOnCreate={this.loadPlaceList}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(Main));
