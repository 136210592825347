import { SET_PROJECT_VERSION } from '../actionTypes';
import { api } from '../../api';

/**
 * Template 프로젝트 버전 정보를 설정한다.
 */
export const setProjectVersion = (versionInfo) => {
  return {
    type: SET_PROJECT_VERSION,
    versionInfo
  };
};

/**
 * 서버에서 Template 프로젝트 버전 정보를 가져와 정보를 업데이트 한다.
 */
export const loadProjectVersion = () => {
  return (dispatch) => {
    /* const url = `/api/v1/shop/${shopId}`;
        api.get(url)
            .then(res => {
                const {data} = res.data;
                console.log('versionData', data);
                dispatch(setProjectVersion(data));
            })
            .finally(() => {
            });*/
    dispatch(setProjectVersion('Server (2.0.0)'));
  };
};
