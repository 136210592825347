import { CHANGE_STORE_VALUE } from '../actionTypes';

export function changeStoreValue(containerName, targetPath, value) {
  return {
    type: CHANGE_STORE_VALUE,
    containerName,
    targetPath,
    value
  };
}
