import { getAuthorizedApi } from '../../api';

const ResetPassword = ({ storeId }) => {
  const handleClickResetPassword = () => {
    getAuthorizedApi()
      .patch(`v1/auth/${storeId}`)
      .then(() => {
        alert('초기화 완료');
      })
      .catch(() => {
        alert('초기화 실패');
      });
  };
  if (!storeId) return null;
  return (
    <div style={{ padding: '8px 4px', 'margin-top': '20px' }}>
      <button className="close button" onClick={handleClickResetPassword}>
        비밀번호 초기화
      </button>
    </div>
  );
};

export default ResetPassword;
