import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseButton } from '../../assets/img/svg/main/modal_close.svg';

class NoticeModal extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object,
    handleCloseModal: PropTypes.func,
    handleClickConfirm: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      content: this.props.content,
      title: this.props.title
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  /* 확인버튼 */
  handleClickButton = () => {
    const { handleClickConfirm } = this.props;
    const { title, content, id } = this.state;
    handleClickConfirm(title, content, id);
  };

  render() {
    return (
      <React.Fragment>
        <div className="modal-container">
          <div className="textarea-modal">
            <div className="title-container">
              <span>공지 등록</span>
              <CloseButton onClick={() => this.props.handleCloseModal()} />
            </div>
            <div className="content-container">
              <textarea
                maxLength={200}
                placeholder="제목"
                cols={1}
                className="modal-title"
                value={this.state.title}
                onChange={this.handleChange}
                name="title"
              ></textarea>
              <p className="texts">내용 작성</p>
              <textarea
                placeholder="내용 작성"
                className="textarea"
                value={this.state.content}
                onChange={this.handleChange}
                name="content"
              ></textarea>
              <span
                className="button button-active"
                style={{ width: '330px', color: 'black', margin: '0 auto' }}
                onClick={this.handleClickButton}
              >
                등록
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}
export default connect(select)(NoticeModal);
