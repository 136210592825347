import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseButton } from '../../assets/img/svg/main/modal_close.svg';

class TextareaModal extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    handleClickButton: PropTypes.func,
    onClose: PropTypes.func,
    disabled: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = { content: this.props.content };
  }

  handleChange = (event) => {
    this.setState({ content: event.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <div className="modal-container">
          <div className="textarea-modal">
            <div className="title-container">
              <span>{this.props.title}</span>
              <CloseButton onClick={() => this.props.onClose()} />
            </div>
            <div className="content-container">
              <p className="texts">내용 작성</p>
              <textarea className="textarea" value={this.state.content} onChange={this.handleChange}></textarea>
              <span
                className="button button-active"
                style={{ width: '330px', color: 'black', margin: '0 auto' }}
                onClick={() => {
                  if (!this.props.disabled) this.props.handleClickButton(this.state.content);
                }}
              >
                등록
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}
export default connect(select)(TextareaModal);
