import { isDebuggableEnv } from './shared/util';
import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { reducers } from './redux';

/**
 * 개발 환경에서는 콘솔에 redux 로깅을 남긴다.
 */
export const getReduxLogger = () => {
  let reduxLogger = null;

  if (isDebuggableEnv()) {
    console.info('detecting development environment. using state logger');
    reduxLogger = (store) => (next) => (action) => {
      console.group(action.type);
      console.info('before state', JSON.parse(JSON.stringify(store.getState())));
      console.info('dispatching', JSON.parse(JSON.stringify(action)));
      let result = next(action);
      console.info('next state', JSON.parse(JSON.stringify(store.getState())));
      console.groupEnd();
      return result;
    };
  }
  return reduxLogger;
};

const reduxLogger = getReduxLogger();
let middleware;
if (reduxLogger) {
  middleware = applyMiddleware(thunk, reduxLogger);
} else {
  middleware = applyMiddleware(thunk);
}

export const store = createStore(reducers, middleware);
