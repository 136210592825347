import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ReactComponent as CloseButton } from '../../assets/img/svg/main/modal_close.svg';

class PolicyModal extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object,
    handleCloseModal: PropTypes.func,
    handleClickConfirm: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      content: this.props.content,
      type: 'TERMS_OF_SERVICE'
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClickButton = () => {
    const { handleClickConfirm } = this.props;
    const { id, content, type } = this.state;
    handleClickConfirm(type, content, id);
  };

  render() {
    return (
      <React.Fragment>
        <div className="modal-container">
          <div className="textarea-modal" style={{ height: 'auto', maxHeight: '800px' }}>
            <div className="title-container">
              <span>약관 {this.props.id ? '수정' : '등록'}</span>
              <CloseButton onClick={() => this.props.handleCloseModal()} />
            </div>
            <div className="content-container">
              <p className="texts">타입 선택</p>
              <div
                style={{
                  display: 'flex'
                }}
              >
                <div
                  style={{
                    padding: '0.5rem 0.75rem',
                    margin: '0.5rem 0.125rem',
                    borderRadius: '1rem',
                    background: '#74f5f4',
                    color: 'black',
                    cursor: 'pointer',
                    display: 'flex'
                  }}
                >
                  이용약관
                </div>
              </div>
              {/* <input
                placeholder="타입"
                className="input-box"
                type="text"
                style={{ marginTop: '1rem' }}
                value={this.state.type}
                onChange={this.handleChange}
                name="type"
              /> */}
              <p className="texts" style={{ marginTop: '1rem' }}>
                내용 작성
              </p>
              <textarea
                placeholder="내용 작성"
                className="textarea"
                value={this.state.content}
                onChange={this.handleChange}
                name="content"
              ></textarea>
              <span
                className="button button-active"
                style={{ width: '330px', color: 'black', margin: '0 auto' }}
                onClick={this.handleClickButton}
              >
                {this.props.id ? '수정' : '등록'}
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}
export default connect(select)(PolicyModal);
