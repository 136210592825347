import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../assets/scss/_colors.scss';

const CustomErrorMessage = ({ message = '예기치 못한 에러가 발생되었습니다.' }) => {
  return (
    <div className={'custom-error center'}>
      <p>{message}</p>
    </div>
  );
};

export default CustomErrorMessage;
