import _ from 'lodash';
import { getNumbersOnly } from './util';

/**
 * 0을 00 으로 출력하기 위한 Formatter
 * @type {Intl.NumberFormat}
 */
export const doubleDigitFormatter = new Intl.NumberFormat(['ko-KR'], { minimumIntegerDigits: 2 });

/**
 * 일반적인 숫자 Formatter
 * @type {Intl.NumberFormat}
 */
export const numberFormatter = new Intl.NumberFormat(['ko-KR']);

/**
 * 한국 전화번호 포맷팅
 * @param srcPhoneNumber
 */
export const formatPhoneNumber = (srcPhoneNumber) => {
  if (_.isEmpty(srcPhoneNumber)) {
    return '';
  }

  const phoneNumber = getNumbersOnly(srcPhoneNumber);
  let formatNum;
  try {
    if (phoneNumber.length === 11) {
      formatNum = phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (phoneNumber.length === 8) {
      formatNum = phoneNumber.replace(/(\d{4})(\d{4})/, '$1-$2');
    } else {
      if (phoneNumber.indexOf('02') === 0) {
        formatNum = phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      } else {
        formatNum = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    }
  } catch (e) {
    formatNum = phoneNumber;
  }
  return formatNum;
};

/**
 * 지정된 자리수 숫자를 얻는다.
 * @param number
 * @returns {string}
 */
export const formatNDigit = (number, minimumIntegerDigits = 2) => {
  const theNumber = parseInt(`${number}`, 10);
  let formatted = theNumber.toLocaleString('en-US', {
    minimumIntegerDigits: minimumIntegerDigits,
    useGrouping: false
  });
  return formatted;
};

/*
 * 3자릿수 마다 콤마 1,123,222,000
 */
export const numberWithCommas = (x) => {
  if (typeof x === 'number') return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  else return 0;
};
