import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TempLayout1 from './pages/template/TempLayout1';
import TempLayout2 from './pages/template/TempLayout2';
import Test from './pages/template/Test';
import SheetJSApp from './pages/SheetJSApp'; // 가맹점 대량 등록을 위한 엑셀 수정/저장 가능한 페이지
import Policy from './pages/admin/Policy';
import PolicyViewer from './pages/admin/PolicyViewer';
import Login from './pages/Login';
import BackDropLoading from './components/mui/BackDropLoading';
import { Main, Coupon, Notice, Promotion, CalculatePage } from './pages/admin/index';
import {
  StoreMain,
  StoreOrder,
  StoreMenu,
  StoreCoupon,
  StoreReview,
  StorePromotion,
  StoreCalculate,
  StoreBranch
} from './pages/store/index';
import CommonDialog from './components/CommonDialog';
import ConfirmPopup from './components/ConfirmPopup';

// import styles & utils
import './assets/scss/style.scss';
import { isNotEmpty } from './shared/util';
import { api } from './api';

class App extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object
  };

  componentDidMount() {
    const accessToken = localStorage.getItem('accessToken');

    if (isNotEmpty(accessToken))
      api.defaults.headers = {
        Authorization: 'Bearer ' + accessToken
      };
  }

  render() {
    const routes = [
      { path: '/main', component: Main },
      { path: '/notice', component: Notice },
      { path: '/coupon', component: Coupon },
      { path: '/promotion', component: Promotion },
      { path: '/policy', component: Policy },
      { path: '/calculate', component: CalculatePage },
      { path: '/store/:storeid', component: StoreMain },
      { path: '/store/order/:storeid', component: StoreOrder },
      { path: '/store/menu/:storeid', component: StoreMenu },
      { path: '/store/promotion/:storeid', component: StorePromotion },
      { path: '/store/coupon/:storeid', component: StoreCoupon },
      { path: '/store/review/:storeid', component: StoreReview },
      { path: '/store/calculate/:storeid', component: StoreCalculate },
      { path: '/store/branch/:storeid', component: StoreBranch },
      { path: '/temp1', component: TempLayout1 },
      { path: '/temp2', component: TempLayout2 },
      { path: '/test', component: Test },
      { path: '/xlsx', component: SheetJSApp },
      { path: '/policy_viewer', component: PolicyViewer }
    ];

    return (
      <React.Fragment>
        <Switch>
          {/* 로그인 페이지 */}
          <Route exact path="/" component={Login} />
          {/* 로그인 X > 로그인 페이지로 이동 */}
          {routes.map(({ path, component: Component }) => (
            <Route
              key={path}
              exact
              path={path}
              render={(props) => {
                const accessToken = localStorage.getItem('accessToken');
                return isNotEmpty(accessToken) ? (
                  <Component {...props} />
                ) : (
                  <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                );
              }}
            />
          ))}
          {/* 404는 로그인 체크 후 메인 페이지로 */}
          <Route path="*" render={(props) => <Redirect to={{ pathname: '/', state: { from: props.location } }} />} />
        </Switch>

        {/* 공통 컴포넌트 */}
        <CommonDialog />
        <ConfirmPopup />
        <BackDropLoading />
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth
  };
}

export default withRouter(connect(select)(App));
