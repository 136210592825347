import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadProjectVersion } from '../redux/version/versionActions';
import { changeStoreValue } from '../redux/root/actions';
import { doLogin } from '../redux/auth/authActions';
import { showDialog } from '../redux/dialog/dialogActions';
import '../assets/scss/_common.scss';
import '../assets/scss/template/template-main.scss';
import { ReactComponent as Logo } from '../assets/img/svg/logo_main.svg';
import { isNotEmpty } from '../shared/util';
import { api } from '../api';

class Login extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      id: 'email',
      password: 'password'
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(changeStoreValue('version', 'currentVersion', 'changeStoreValue (3.0.0)'));
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  handleServerVersionInfo = () => {
    const { dispatch } = this.props;
    const onOk = () => {
      dispatch(loadProjectVersion());
    };

    dispatch(
      showDialog({
        title: '창제목',
        content: '서버에서 버전 정보를 가져 올까요?',
        onOk: onOk,
        buttonCount: 2
      })
    );
  };

  onChangeInput = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  // 로그인 요청 event
  onSubmit = () => {
    const { dispatch, history } = this.props;
    const { id, password } = this.state;

    const onOk = () => {
      return history.push('/main');
    };
    dispatch(doLogin(id, password, onOk));
  };

  // 엔터 확인
  onKeyPress = (e) => {
    if (e.key == 'Enter') {
      this.onSubmit();
    }
  };

  // -- render --------------------------------------------------------------------------------
  render() {
    const { version } = this.props;
    const { id, password } = this.state;

    return (
      <React.Fragment>
        <div className="root-bg">
          <div style={{ height: '49px', backgroundColor: 'white' }}>
            <div className="top" style={{ height: '26px', backgroundColor: '#74f5f4' }}></div>
            <div className="bottom"></div>
          </div>
          <div className="contents">
            <p className="title">ADMINPAGE</p>
            <div className="login-container">
              <div className="logo-container">
                <Logo style={{ width: '316px', height: '56px' }} />
              </div>
              <div className="form-container">
                <p className="input-label">ID</p>
                <input
                  name="id"
                  className="input-box"
                  type="text"
                  placeholder="Enter email"
                  style={{ marginBottom: '24px' }}
                  onChange={this.onChangeInput}
                  value={id || ''}
                />
                <p className="input-label">Password</p>
                <input
                  name="password"
                  className="input-box"
                  type="password"
                  placeholder="Enter password"
                  style={{ marginBottom: '47px' }}
                  onChange={this.onChangeInput}
                  value={password || ''}
                  onKeyPress={this.onKeyPress}
                />
                <div className="text-link" onClick={this.onSubmit}>
                  <span className="login-button high-gray-font" style={{ margin: '0 auto' }}>
                    로그인
                  </span>
                </div>
              </div>
            </div>
            <p className="copyright">{`Copyright ${new Date().getFullYear()}. HyperCloud All right reserved.`}</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(Login));
