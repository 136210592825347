// EXAMPLE ACTION
export const SET_PROJECT_VERSION = 'SET_PROJECT_VERSION';
export const CHANGE_STORE_VALUE = 'CHANGE_STORE_VALUE';
export const COMMON_DIALOG_STATE_CHANGED = 'COMMON_DIALOG_STATE_CHANGED';

// common
export const COMMON_CONFIRM_POPUP_OPEN = 'COMMON_CONFIRM_POPUP_OPEN';
export const COMMON_CONFIRM_POPUP_CLOSE = 'COMMON_CONFIRM_POPUP_CLOSE';

export const BACKDROP_LOADING_START = 'BACKDROP_LOADING_START';
export const BACKDROP_LOADING_STOP = 'BACKDROP_LOADING_STOP';

//
// auth
//
export const SET_MEMBER_LOGIN = 'SET_MEMBER_LOGIN';
export const SET_MEMBER_LOGOUT = 'SET_MEMBER_LOGOUT';

export const SET_AUTH_FIRST_CHANGE = 'SET_AUTH_FIRST_CHANGE'; // 최초 접속 로그 (비밀번호 변경 필수 체크)

//
// store 매장 정보
//
export const LOAD_STORE_CATEGORY_REQUEST = 'LOAD_STORE_CATEGORY_REQUEST';
export const LOAD_STORE_CATEGORY_SUCCESS = 'LOAD_STORE_CATEGORY_SUCCESS';
export const LOAD_STORE_CATEGORY_FAILURE = 'LOAD_STORE_CATEGORY_FAILURE';

export const LOAD_STORE_SERVICE_REQUEST = 'LOAD_STORE_SERVICE_REQUEST';
export const LOAD_STORE_SERVICE_SUCCESS = 'LOAD_STORE_SERVICE_SUCCESS';
export const LOAD_STORE_SERVICE_FAILURE = 'LOAD_STORE_SERVICE_FAILURE';

// test
export const GET_POST_CONTENT = 'GET_POST_CONTENT';
export const GET_ALL_POSTS = 'GET_ALL_POSTS';
export const UPDATE_POSTS = 'UPDATE_POSTS';
// export const GET_ALL_POSTS = "GET_ALL_POSTS";
// export const GET_ALL_POSTS = "GET_ALL_POSTS";
