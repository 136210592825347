import { encryptRSA } from '../../shared/encryptUtil';
import { api } from '../../api';
import { GET_ALL_POSTS } from '../actionTypes';

export const url = '//jsonplaceholder.typicode.com';

export const getAllPosts = () => {
  return (dispatch) => {
    api
      .get(`${url}/posts`)
      .then((res) => {
        const { data } = res;
        dispatch({
          type: GET_ALL_POSTS,
          posts: data
        });
      })
      .finally(() => {});
  };
};
