import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isNotEmpty } from '../../shared/util';

const CustomLoading = ({ color = '#74f5f4', size = '40px', comment = '', commentSize = '20px' }) => {
  return (
    <div className={'data-loading center'}>
      <CircularProgress style={{ width: size, height: size, color }} />
      {isNotEmpty && <span style={{ marginLeft: '10px', fontSize: commentSize, color: '#74f5f4' }}>{comment}</span>}
    </div>
  );
};

export default CustomLoading;
