import produce from 'immer';
import { deepCopy } from '../../shared/util';
import { GET_POST_CONTENT } from '../actionTypes';

const initialState = {
  id: 1,
  title: 'test',
  body: 'test',
  userId: 1
};
export const postReducer = (state = initialState, action) => {
  if (action.type === GET_POST_CONTENT) {
    return produce(state, (draft) => {
      draft.id = action.id;
      draft.title = action.title;
      draft.body = action.body;
      draft.userId = action.userId;
    });
  }

  // switch (action.type) {
  //   case GET_ALL_POSTS:
  //     return action.posts;
  //   // default:
  //   //   return initialState;
  // }

  return state;
};
