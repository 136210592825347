import { connect } from 'react-redux';
import React from 'react';
import { returnFileSize } from '../../shared/util';
import { api } from '../../api';
import { ReactComponent as CloseButton } from '../../assets/img/svg/main/modal_close.svg';
import { ReactComponent as PendingIcon } from '../../assets/img/svg/main/pending.svg';
import { ReactComponent as UploadingIcon } from '../../assets/img/svg/main/uploading.svg';
import { ReactComponent as FailIcon } from '../../assets/img/svg/main/fail.svg';
import { ReactComponent as SuccessIcon } from '../../assets/img/svg/main/success.svg';
import { ReactComponent as FileIcon } from '../../assets/img/svg/main/file.svg';
import { ReactComponent as SmallErrorIcon } from '../../assets/img/svg/main/small_error.svg';

class PlaceUploadModal extends React.Component {
  // static PropTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      // pending, uploading, fail, success
      loadingStatus: 'pending',
      file: {},
      countNewPartners: 0,
      countNewPlaces: 0,
      countNewProductCategories: 0,
      countNewProducts: 0,
      excludedPartners: [],
      excludedPlaces: [],
      excludedProductCategories: [],
      excludedProducts: []
    };
  }

  returnValue(flag) {
    const isValid = this.props.isValid;
    isValid(flag);
  }

  loadingStatusRender = (state, option = 'file') => {
    switch (state) {
      case 'pending':
        return (
          <>
            <PendingIcon />
            <span>{option == 'image' ? `jpeg, png (10MB)` : `Low DB Upload (xls, csv)`}</span>
          </>
        );
      case 'uploading':
        return (
          <>
            <UploadingIcon />
            <span>Uploading file</span>
          </>
        );
      case 'fail':
        return (
          <>
            <FailIcon />
            <span>Unable to upload file</span>
          </>
        );
      case 'success':
        return (
          <>
            <SuccessIcon />
            <span>File upload complete!</span>
          </>
        );
    }
  };
  textBoxRender = (state) => {
    switch (state) {
      case 'fail':
        return (
          <>
            <p className="text-box" style={{ marginBottom: '6px' }}>
              <span>
                <FileIcon />
                {/* 강남구가맹점등록파일.xls */}
                {this.state.file.name}
              </span>
              <span>{returnFileSize(this.state.file.size)}</span>
            </p>
            <p className="text-box" style={{ marginBottom: '13px' }}></p>
          </>
        );
      case 'success':
        return (
          <>
            <p className="text-box" style={{ marginBottom: '6px' }}>
              <span>
                <FileIcon />
                {this.state.file.name}
              </span>
              <span>{returnFileSize(this.state.file.size)}</span>
            </p>
            <p
              style={{
                marginBottom: '19px',
                justifyContent: 'flex-start',
                display: 'block',
                overflowY: 'scroll',
                wordBreak: 'break-word',
                maxHeight: '200px'
              }}
            >
              <div>
                {this.state.countNewPlaces > 0 ? (
                  <span className="blue-font">등록된 매장 : {this.state.countNewPlaces},&nbsp;</span>
                ) : (
                  <></>
                )}
              </div>
              <div>
                {this.state.countNewPartners > 0 ? (
                  <span className="blue-font">등록된 파트너 : {this.state.countNewPartners},&nbsp;</span>
                ) : (
                  <></>
                )}
              </div>

              <div>
                {this.state.countNewProductCategories > 0 ? (
                  <span className="blue-font">
                    등록된 제품 카테고리 : {this.state.countNewProductCategories},&nbsp;
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <div>
                {this.state.countNewProducts > 0 ? (
                  <span className="blue-font">등록된 제품 : {this.state.countNewProducts},&nbsp;</span>
                ) : (
                  <></>
                )}
              </div>

              <div>
                {this.state.excludedPartners.length > 0 ? (
                  <span className="gray-font">
                    미반영 파트너 :{' '}
                    {this.state.excludedPartners.map((v, index) => (
                      <span
                        key={`partner-${index}`}
                        style={{
                          backgroundColor: '#e9e9e9',
                          margin: '0px 4px',
                          padding: '2px 6px',
                          borderRadius: '2px'
                        }}
                      >
                        {v}
                      </span>
                    ))}
                    ,&nbsp;
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <div>
                {this.state.excludedPlaces.length > 0 ? (
                  <span className="gray-font">
                    미반영 매장 :{' '}
                    {this.state.excludedPlaces.map((v, index) => (
                      <span
                        key={`place-${index}`}
                        style={{
                          backgroundColor: '#e9e9e9',
                          margin: '0px 4px',
                          padding: '2px 6px',
                          borderRadius: '2px'
                        }}
                      >
                        {v}
                      </span>
                    ))}
                    ,&nbsp;
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <div>
                {this.state.excludedProductCategories.length > 0 ? (
                  <span className="gray-font">
                    미반영 제품 카테고리 :{' '}
                    {this.state.excludedProductCategories.map((v, index) => (
                      <span
                        key={`categories-${index}`}
                        style={{
                          backgroundColor: '#e9e9e9',
                          margin: '0px 4px',
                          padding: '2px 6px',
                          borderRadius: '2px'
                        }}
                      >
                        {v}
                      </span>
                    ))}
                    ,&nbsp;
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <div>
                {this.state.excludedProducts.length > 0 ? (
                  <span className="gray-font">
                    미반영 제품 :{' '}
                    {this.state.excludedProducts.map((v, index) => (
                      <span
                        key={`product-${index}`}
                        style={{
                          backgroundColor: '#e9e9e9',
                          margin: '0px 4px',
                          padding: '2px 6px',
                          borderRadius: '2px'
                        }}
                      >
                        {v}
                      </span>
                    ))}
                    ,&nbsp;
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </p>
          </>
        );
      default:
        return (
          <>
            <p className="text-box" style={{ marginBottom: '6px' }}></p>
            <p className="text-box" style={{ marginBottom: '13px' }}></p>
          </>
        );
    }
  };

  fileChange = async (event) => {
    // console.log(event);
    this.setState({ loadingStatus: 'pending' });
    const file = event.target.files[0];
    const form = new FormData();
    form.append('file', file);
    try {
      this.setState({ loadingStatus: 'uploading' });
      const {
        data: {
          countNewPartners,
          countNewPlaces,
          countNewProductCategories,
          countNewProducts,
          excludedPartners,
          excludedPlaces,
          excludedProductCategories,
          excludedProducts
        }
      } = await api.post('v1/places/bulk', form);

      this.setState({ countNewPartners });
      this.setState({ countNewPlaces });
      this.setState({ countNewProductCategories });
      this.setState({ countNewProducts });
      this.setState({ excludedPartners });
      this.setState({ excludedPlaces });
      this.setState({ excludedProductCategories });
      this.setState({ excludedProducts });
      this.setState({ loadingStatus: 'success' });
    } catch (e) {
      this.setState({ loadingStatus: 'fail' });
      console.error(e);
    }
    this.setState({ file: file });
    event.target.value = null;

    this.props.handleOnCreate();
  };

  render() {
    const isImage = this.props.option === 'image' ? true : false;
    return (
      <React.Fragment>
        <div className="modal-container" style={{ width: '100%' }}>
          <div
            className={isImage ? 'modal image' : 'modal'}
            style={{
              height: 'auto'
            }}
          >
            <div className="title-container" style={{ width: 'auto', background: 'white' }}>
              <span style={{ margin: '0' }}>{this.props.title}</span>
              <CloseButton onClick={() => this.returnValue(false)} />
            </div>
            <div className="content-container">
              <p className="texts" style={{ margin: '14px 15px 12px' }}>
                {isImage ? '이미지 등록' : '대량 업로드'}
                {/* {isImage ? '메뉴 이미지 등록' : '대량 업로드'} */}
              </p>
              <input
                type="file"
                id="input-file"
                style={{ display: 'none' }}
                onInput={this.fileInput}
                onChange={this.fileChange}
              />
              <label className="input-file-button" htmlFor="input-file">
                <div className={`loading-status ${this.state.loadingStatus}`}>
                  {this.loadingStatusRender(this.state.loadingStatus, this.props.option)}
                </div>
              </label>
              {isImage ? (
                <></>
              ) : (
                <>
                  <p className="texts" style={{ margin: '14px 15px 7px' }}>
                    등록 처리
                  </p>
                  {this.textBoxRender(this.state.loadingStatus)}
                </>
              )}

              <span
                className={isImage ? 'button image' : 'button'}
                style={{ width: '100%', color: 'black', marginLeft: '0' }}
                onClick={() => this.returnValue(false)}
              >
                확인
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function select(state) {
  const commonDialog = state.commonDialog;
  const selected = {
    // show: commonDialog.show,
    // title: commonDialog.title,
    // content: commonDialog.content,
    // buttonCount: commonDialog.buttonCount,
    // onOk: commonDialog.onOk,
    // onCancel: commonDialog.onCancel,
    // okButtonText: commonDialog.okButtonText,
    // cancelButtonText: commonDialog.cancelButtonText,
    // useClickAway: commonDialog.useClickAway,
    // modalStyle: commonDialog.modalStyle
  };
  return selected;
}
export default connect(select)(PlaceUploadModal);
