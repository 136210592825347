import { connect } from 'react-redux';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import { format } from 'date-fns';
import '../../assets/scss/calender.scss';
import { ReactComponent as PrevIcon } from '../../assets/img/svg/calender/prev.svg';
import { ReactComponent as NextIcon } from '../../assets/img/svg/calender/next.svg';
import { range } from '../../shared/util';

class CalenderRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: this.props.startDate !== '' && this.props.startDate !== null ? new Date(this.props.startDate) : '', //new Date(Date.now()),
      endDate: this.props.startDate !== '' && this.props.startDate !== null ? new Date(this.props.endDate) : ''
    };
  }

  updateDate(type) {
    const queryParam = this.props.queryParam;
    if (this.state.startDate !== '' && this.state.endDate !== '') {
      queryParam({
        startDate: format(this.state.startDate, 'yyyy-MM-dd'),
        endDate: format(this.state.endDate, 'yyyy-MM-dd')
      });
    } else if (this.state.startDate !== '') {
      queryParam({ startDate: format(this.state.startDate, 'yyyy-MM-dd'), endDate: '' });
    } else if (this.state.endDate !== '') {
      queryParam({ startDate: '', endDate: format(this.state.endDate, 'yyyy-MM-dd') });
    }
  }

  render() {
    const months = ['01월', '02월', '03월', '04월', '05월', '06월', '07월', '08월', '09월', '10월', '11월', '12월'];
    return (
      <div style={{ display: 'flex', marginLeft: '11px' }}>
        <DatePicker
          autoComplete="off"
          disabled={this.props.disabled}
          name="startDate"
          dateFormat="yyyy.MM.dd"
          selected={this.state.startDate}
          selectsStart
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          placeholderText="시작일 선택"
          locale={ko}
          onChange={async (d) => {
            await this.setState({ startDate: d ? d : '', endDate: '' });
            this.updateDate('start');
          }}
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <div className="date-customheader">
              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <PrevIcon />
              </button>
              <div className="custom-month">
                {date.getFullYear()}년 {months[date.getMonth()]}
              </div>
              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <NextIcon />
              </button>
            </div>
          )}
        />
        <span className="hyphen">-</span>
        <DatePicker
          autoComplete="off"
          disabled={this.props.disabled}
          name="endDate"
          dateFormat="yyyy.MM.dd"
          selected={this.state.endDate}
          selectsEnd
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          minDate={this.state.startDate}
          // maxDate={new Date()}
          placeholderText="종료일 선택"
          locale={ko}
          onChange={async (d) => {
            await this.setState({ endDate: d ? d : '' });
            this.updateDate();
          }}
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <div className="date-customheader">
              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <PrevIcon />
              </button>
              <div className="custom-month">
                {date.getFullYear()}년 {months[date.getMonth()]}
              </div>
              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <NextIcon />
              </button>
            </div>
          )}
        />
      </div>
    );
  }
}
function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}
export default connect(select)(CalenderRange);
