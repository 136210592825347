import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export const ToggleSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 25,
    padding: 0
  },
  switchBase: {
    padding: 3,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#3CC13B',
        opacity: 1
      }
    }
  },
  thumb: {
    width: 18,
    height: 18
  },
  track: {
    padding: 0,
    borderRadius: 25 / 2,
    backgroundColor: '#545454',
    opacity: 1
  },
  checked: {},
  focusVisible: {}
}))(({ classes, disabled, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disabled={disabled}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});
