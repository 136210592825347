import { encryptRSA } from '../../shared/encryptUtil';
import { api } from '../../api';
import { SET_MEMBER_LOGIN, SET_MEMBER_LOGOUT, SET_AUTH_FIRST_CHANGE } from '../actionTypes';

/**
 * 로그인을 수행한다.
 * @param id
 * @param password
 */
export const doLogin = (id, password, onOk) => {
  return (dispatch) => {
    const postParams = {
      email: id,
      password
    };
    return api
      .post('/v1/auth/signin', postParams)
      .then((res) => {
        const { accessToken, manager } = res.data;
        localStorage.setItem('accessToken', accessToken);
        dispatch({
          type: SET_MEMBER_LOGIN,
          manager
        });

        if (onOk) {
          onOk();
        }
      })
      .finally(() => {});
  };
};

/**
 * Admin 정보 불러오기
 */
export const getAdminInfo = () => {
  return (dispatch) => {
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };
    return api
      .get('/v1/manager')
      .then((res) => {
        dispatch({
          type: SET_MEMBER_LOGIN,
          manager: res.data.manager
        });
      })
      .finally(() => {});
  };
};

/**
 * 비밀번호 변경 후 최초 여부만 변경
 */
export const changeIsFirst = () => {
  return (dispatch) => {
    dispatch({
      type: SET_AUTH_FIRST_CHANGE
    });
  };
};

/**
 * 로그아웃
 */
export const logout = () => {
  localStorage.clear();
  return (dispatch) => {
    dispatch({
      type: SET_MEMBER_LOGOUT
    });
  };
};
