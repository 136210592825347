import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import update from 'react-addons-update';

// import component
import { openConfirmPopup, closeConfirmPopup } from '../../redux/dialog/dialogActions';
import TemplateDefault from '../template/TemplateDefault';
import CustomPagination from '../../components/custom/CustomPagination';
import NoticeModal from '../../components/general/NoticeModal';
import CustomErrorMessage from '../../components/custom/CustomErrorMessage';
import CustomLoading from '../../components/custom/CustomLoading';

// import icon & utils
import { api } from '../../api';
import moment from 'moment';
import { ReactComponent as AddButton } from '../../assets/img/svg/main/add_btn.svg';
import { ReactComponent as RemoveButton } from '../../assets/img/svg/main/remove_btn.svg';
import { ReactComponent as EditIcon } from '../../assets/img/svg/main/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/svg/main/delete.svg';
import { isEmpty, isNotEmpty } from '../../shared/util';

class Notice extends Component {
  // -- static --------------------------------------------------------------------------------
  static propTypes = {
    dispatch: PropTypes.func,
    app: PropTypes.object,
    auth: PropTypes.object,
    version: PropTypes.object
  };

  // -- constructor ---------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      limit: 10,
      page: 1,
      noticeList: [],
      errorMsg: '',
      isLoading: false,
      count: 0,
      showModal: false,
      editModal: [false, false, false, false, false],
      isOpenDeleteDialog: false
    };
  }

  // -- react lifecycle -----------------------------------------------------------------------
  componentDidMount() {
    const { isLoading } = this.state;
    // 공지 리스트 조회
    if (!isLoading) this.loadNoticeList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { page } = this.state;

    // 페이지 변경
    if (prevState.page !== page) {
      this.loadNoticeList();
    }
  }

  // -- feature -------------------------------------------------------------------------------

  // -- handler -------------------------------------------------------------------------------
  // 공지 조회
  loadNoticeList = () => {
    const { page, limit } = this.state;
    this.setState({ isLoading: true, errorMsg: '' });
    api.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    };

    api
      .get(`/v1/notices?limit=${limit}&page=${page}`)
      .then((res) => {
        this.setState({ isLoading: false, noticeList: res.data.notices, count: res.data?.count });
      })
      .catch((err) => {
        this.setState({ isLoading: false, errorMsg: '예기치 못한 에러가 발생되었습니다.' });
      })
      .finally(() => {});
  };

  /* 삭제 경고 팝업창  최종 확인*/
  handleClickAgree = (id) => {
    const { dispatch } = this.props;
    const { noticeList } = this.state;

    api
      .delete(`/v1/notices/${id}`)
      .then((res) => {
        this.setState({
          isOpenDeleteDialog: false,
          noticeList: noticeList.filter((v) => v.id !== id)
        });
      })
      .catch((err) => {
        this.setState({ isOpenDeleteDialog: false });
      })
      .finally(() => {
        dispatch(closeConfirmPopup());
      });
  };

  /* 삭제 버튼 클릭 */
  handleClickDeleteButton = (id) => {
    const { dispatch } = this.props;
    dispatch(
      /* 삭제 버튼 클릭 재확인 팝업창*/
      openConfirmPopup({
        title: 'NOTICE',
        comment: '삭제 진행 재확인',
        type: 'delete',
        onOk: () => this.handleClickAgree(id)
      })
    );
  };

  /* 공지 팝업 */
  modalData = (
    <NoticeModal
      // TODO: isValid는 나중에 동의/비동의 같은 항목에서 return 값 받아오는 용도로 쓸 예정
      title=""
      content=""
      handleCloseModal={() => {
        this.setState({ showModal: false });
      }}
      handleClickConfirm={() => {
        alert('기본');
        this.setState({ showModal: false });
      }}
    />
  );

  /* 공지 등록 */
  addNotice = (title, content) => {
    const { noticeList } = this.state;
    if (isEmpty(title)) return alert('제목을 입력해주세요.');
    else if (isEmpty(content)) return alert('내용을 입력해주세요.');
    else {
      const body = {
        title,
        content
      };

      api
        .post(`/v1/notices`, body)
        .then((res) => {
          const newList = [...noticeList];
          newList.unshift(res.data);
          if (newList.length > 10) newList.pop();

          this.setState((prev) => ({
            count: prev.count + 1,
            noticeList: newList,
            showModal: false
          }));
        })
        .catch((err) => {
          this.setState({ showModal: false });
        })
        .finally(() => {});
    }
  };

  /* 공지 등록 팝업창 */
  createModalData = () => {
    return () => {
      this.modalData = (
        <NoticeModal
          title=""
          content=""
          handleCloseModal={() => {
            this.setState({ showModal: false });
          }}
          handleClickConfirm={this.addNotice}
        />
      );
      this.setState({ showModal: true });
    };
  };

  /* 공지 수정 */
  editNotice = (title, content, noticeId) => {
    if (isEmpty(title)) return alert('제목을 입력해주세요.');
    else if (isEmpty(content)) return alert('내용을 입력해주세요.');
    else {
      const body = {
        title,
        content
      };

      api
        .put(`/v1/notices/${noticeId}`, body)
        .then((res) => {
          const { id, title, content } = res.data;
          const findIndex = this.state.noticeList.findIndex((v) => v.id === id);
          this.setState({
            noticeList: update(this.state.noticeList, {
              [findIndex]: {
                title: { $set: title },
                content: { $set: content }
              }
            }),
            showModal: false
          });
        })
        .catch((err) => {
          this.setState({ showModal: false });
        })
        .finally(() => {});
    }
  };

  /* 공지 수정 팝업창 */
  editModalOpen = (id, title, content) => {
    return () => {
      this.modalData = (
        <NoticeModal
          id={id}
          title={title}
          content={content}
          handleCloseModal={() => {
            this.setState({ showModal: false });
          }}
          handleClickConfirm={this.editNotice}
        />
      );
      this.setState({ showModal: true });
    };
  };

  // 이전 페이지
  handleClickBack = () => {
    this.setState((prev) => ({
      page: prev.page - 1
    }));
  };
  // 다음 페이지
  handleClickForward = () => {
    this.setState((prev) => ({
      page: prev.page + 1
    }));
  };

  // -- render --------------------------------------------------------------------------------

  topButton = (className, text, onClick) => {
    const button = className === 'hyper-mint' ? <AddButton /> : <RemoveButton />;
    return (
      <p className={className} onClick={onClick}>
        {button}
        <span>{text}</span>
      </p>
    );
  };

  renderHeader = () => {
    return (
      <div className="row th">
        <p style={{ width: '8%' }}>No</p>
        <p style={{ width: '10%' }}>등록일자</p>
        <p style={{ width: '70%' }}>공지명</p>
        <p style={{ width: '12%' }}></p>
      </div>
    );
  };

  renderRow = (data, index) => {
    const { id, createdAt, title, content } = data;
    return (
      <div className="row" key={index}>
        <p style={{ width: '8%' }}>{id}</p>
        <p style={{ width: '10%' }}>{moment(createdAt).format('YYYY-MM-DD')}</p>
        <p style={{ width: '70%' }} className="ellipsis">
          {title}
        </p>
        <p style={{ width: '12%' }}>
          <EditIcon style={{ marginRight: '28px' }} onClick={this.editModalOpen(id, title, content)} />
          <DeleteIcon onClick={() => this.handleClickDeleteButton(id)} />
        </p>
      </div>
    );
  };

  render() {
    const { page, limit, count, isLoading, noticeList, errorMsg } = this.state;
    return (
      <React.Fragment>
        <TemplateDefault sidebar="admin">
          <span className="title">공지 관리</span>
          <div style={{ width: 'fit-content' }}>
            <div className="top-button-container">
              {this.topButton('hyper-mint', '공지 등록', this.createModalData())}
            </div>
            <div className="container">
              <div className="table">
                {this.renderHeader()}
                {isLoading ? (
                  <CustomLoading size="100px" />
                ) : isNotEmpty(errorMsg) ? (
                  <CustomErrorMessage message={errorMsg} />
                ) : (
                  <div className="rows">{noticeList?.map((data, index) => this.renderRow(data, index))}</div>
                )}
              </div>
              {/* 하단 */}
              <CustomPagination
                totalCount={count}
                page={page}
                limit={limit}
                onClickBack={this.handleClickBack}
                onClickForward={this.handleClickForward}
                isLoading={isLoading}
              />
            </div>
          </div>
        </TemplateDefault>
        {this.state.showModal ? this.modalData : null}
      </React.Fragment>
    );
  }
}

function select(state) {
  return {
    app: state.app,
    auth: state.auth,
    version: state.version
  };
}

export default withRouter(connect(select)(Notice));
