import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { withRouter } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const BackDropLoading = ({ global }) => {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={global.isBackDropLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

function select(state) {
  return {
    global: state.global
  };
}

export default withRouter(connect(select)(BackDropLoading));
