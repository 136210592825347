import produce from 'immer';
import { SET_PROJECT_VERSION } from '../actionTypes';

const initialState = {
  currentVersion: '1.0.0'
};

export const versionReducer = (state = initialState, action) => {
  if (action.type === SET_PROJECT_VERSION) {
    return produce(state, (draft) => {
      const version = action.versionInfo;
      draft.currentVersion = version;
    });
  }

  return state;
};
